import {
    DeviceConfigCommand, DiagnosticCommand, FactoryResetCommand,
    FirmwareUpdateCommand, LogRequestCommand, RebootCommand,
    RecordDataCommand, SensorStreamDataCommand, SensorUpdateCommand,
    StreamPortCommand, StreamVideoCommand
} from "@app/shared";
import { InputRow } from "../inputs";
import { EditFirmwareUpdateCommand } from "./EditFirmwareUpdateCommand";
import { EditRecordDataCommand } from "./EditRecordDataCommand";
import { EditSensorStreamDataCommand } from "./EditSensorStreamDataCommand";
import { EditSensorUpdateCommand } from "./EditSensorUpdateCommand";
import { EditStreamPortCommand } from "./EditStreamPortCommand";

export type DeviceCommandTypes = DeviceConfigCommand | DiagnosticCommand | FactoryResetCommand |
    FirmwareUpdateCommand | LogRequestCommand | RebootCommand |
    RecordDataCommand | SensorStreamDataCommand | SensorUpdateCommand |
    StreamPortCommand | StreamVideoCommand;

export type DeviceCommandEditorProps = {
    command: DeviceCommandTypes | undefined;
    onChange: (command: DeviceCommandTypes | undefined) => any;
};

export const DeviceCommandEditor = (props: DeviceCommandEditorProps) => {
    if (!props || !props.command || !props.command.$type)
        return <></>;

    switch (props.command!.$type) {
        case FirmwareUpdateCommand.$type:
            return <EditFirmwareUpdateCommand {...props} />
        case RecordDataCommand.$type:
            return <EditRecordDataCommand {...props} />
        case SensorStreamDataCommand.$type:
            return <EditSensorStreamDataCommand {...props} />
        case SensorUpdateCommand.$type:
            return <EditSensorUpdateCommand {...props} />
        case StreamPortCommand.$type:
            return <EditStreamPortCommand {...props} />
        default:
            return <InputRow>No Parameters to Set</InputRow>
    }
}