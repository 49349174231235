import { DependencyList, useEffect, useState } from "react";

export const useDelayedAction = (clearAction: undefined | (() => void), action: () => void, deps?: DependencyList, delay?: number) => {
    const [, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if (clearAction)
            clearAction();
        const newId = setInterval(async () => await performDelayedAction(), delay ?? 500);
        setIntervalId((prev) => {
            if (prev !== undefined) {
                clearInterval(prev);
            }
            return newId;
        });
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps

    const performDelayedAction = async () => {
        setIntervalId((prev) => {
            if (prev !== undefined) {
                clearInterval(prev);
            }
            return undefined;
        });
        await action();
    }

    return performDelayedAction;
}