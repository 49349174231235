import cn from "classnames";
import { FC, PropsWithChildren, ReactElement, ReactNode, useRef, useState } from "react";
import { usePopper } from "react-popper";

type HoverTooltipProps = {
    hoverable: ReactNode | ReactElement | string;
    className?: string;
    placement?: Placement;
    offset?: [number, number];
    show?: boolean;
    delayMs?: number;
    popperButtonRef?: React.MutableRefObject<null>;
};

export declare type Placement = "top" | "bottom" | "left" | "right" |
    "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end" |
    "auto" | "auto-start" | "auto-end";

export const HoverTooltip: FC<PropsWithChildren<HoverTooltipProps>> = ({ hoverable, children, className, show = false, popperButtonRef: popperButtonRefIn, placement = "top", offset = [0, 10], delayMs }) => {
    const popperButtonRef = useRef(null);
    const popperDivRef = useRef(null);
    const showTimeout = useRef(null as null | NodeJS.Timeout);
    const popper = usePopper(popperButtonRefIn?.current ?? popperButtonRef.current, popperDivRef.current, {
        placement: placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: offset,
                }
            }
        ]
    });
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseOver = () => {
        if (delayMs) {
            showTimeout.current = setTimeout(() => setShowTooltip(true), delayMs);
        } else {
            setShowTooltip(true);
        }
    }
    const handleMouseOut = () => {
        setShowTooltip(false);
        if (showTimeout.current) {
            clearTimeout(showTimeout.current);
            showTimeout.current = null;
        }
    }

    return (
        <>
            {!popperButtonRefIn ?
                <span ref={popperButtonRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    {hoverable}
                </span>
                :
                hoverable
            }

            <div
                ref={popperDivRef}
                style={{ ...popper.styles.popper, zIndex: 999, margin: 0 }}
                className={cn("tooltip", (className ?? "block border border-separators-page shadow bg-dialog-background max-w-sm p-3"), (showTooltip || show ? "visible" : "invisible"))}
                {...popper.attributes.popper}
            >
                {children}
            </div>

        </>
    );
}
