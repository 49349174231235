import { degreesToRadians, Vector2 } from "@app/shared";

export const metersToPixels = (metersX: number, metersY: number, origin: Vector2, scale: number) => {
    if (origin == null)
        origin = new Vector2();
    //Origin and scale are in pixels referenced to the background image.
    var newX = (metersX / scale + origin.x);
    var newY = (metersY / scale + origin.y);

    var retVal = new Vector2({ x: newX, y: newY });
    return retVal;
}
export const metersPointToPixels = (point: Vector2, origin: Vector2, scale: number) => {
    return metersToPixels(point.x, point.y, origin, scale);
}
export const getFovPoint = (height: number, azimuthAngle: number, elevationAngle: number, scaleRatio: number) => {
    var eps = 0.1;
    var elevationAngleRads = degreesToRadians(Math.min(90 + elevationAngle, 90 - eps)); //we do not want this angle to be higher than - or equal to - 90 degrees
    var y = Math.tan(elevationAngleRads) * height;

    var distanceToGround = height / Math.cos(elevationAngleRads);
    var azimuthAngleRads = degreesToRadians(azimuthAngle);
    var x = Math.tan(azimuthAngleRads) * distanceToGround;

    return [x / scaleRatio, y / scaleRatio];
}