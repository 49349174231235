import { PowerInfo, IPowerInfo } from "./Hardware.PowerInfo.gen";
import { ProcessorInfo, IProcessorInfo } from "./Hardware.ProcessorInfo.gen";
import { RamInfo, IRamInfo } from "./Hardware.RamInfo.gen";
import { TemperatureInfo, ITemperatureInfo } from "./Hardware.TemperatureInfo.gen";
import { OrientationInfo, IOrientationInfo } from "./Hardware.OrientationInfo.gen";
import { RadarTemperatureInfo, IRadarTemperatureInfo } from "./Hardware.RadarTemperatureInfo.gen";
import { Guid, IGuid } from "./System.Guid.gen";
import { DateTime, IDateTime } from "./System.DateTime.gen";

export interface IHardwareStatus {
	power: IPowerInfo | undefined;

	processing: IProcessorInfo | undefined;

	ram: IRamInfo | undefined;

	temperature: ITemperatureInfo | undefined;

	orientation: IOrientationInfo | undefined;

	radarTemperature: IRadarTemperatureInfo | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	statusTimestamp: string | undefined;

	uptime: number;

	$type?: string | undefined;
}
export class HardwareStatus {
	constructor(data?: IHardwareStatus) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.power) { this.power = new PowerInfo(data.power); }
		if (data.processing) { this.processing = new ProcessorInfo(data.processing); }
		if (data.ram) { this.ram = new RamInfo(data.ram); }
		if (data.temperature) { this.temperature = new TemperatureInfo(data.temperature); }
		if (data.orientation) { this.orientation = new OrientationInfo(data.orientation); }
		if (data.radarTemperature) { this.radarTemperature = new RadarTemperatureInfo(data.radarTemperature); }
		if (data.statusTimestamp) { this.statusTimestamp = new Date(data.statusTimestamp); }
	}

	power: PowerInfo | undefined;

	processing: ProcessorInfo | undefined;

	ram: RamInfo | undefined;

	temperature: TemperatureInfo | undefined;

	orientation: OrientationInfo | undefined;

	radarTemperature: RadarTemperatureInfo | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	statusTimestamp: Date | undefined;

	uptime: number = 0;

	static $type: string = "CoreApi.Models.Hardware.HardwareStatus, ";
	$type?: string | undefined = HardwareStatus.$type;
}

