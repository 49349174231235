import cn from "classnames";
import toast, { Toast } from "react-hot-toast";
import { IconType } from "react-icons";
import { Icons } from "../components";
import { ReactNode } from "react";

const toastMessage = (t: Toast, Icon: IconType, bgColor: string, iconColor: string, title: string, message?: string | ReactNode) => (
  <div className={cn("max-w-lg w-full bg-dialog-background shadow-lg rounded-lg pointer-events-auto flex border border-separators-page p-3 place-items-center items-stretch",
    t.visible ? 'animate-fade-in-down' : 'animate-fade-out-up')}>
    <div className={cn("w-1 my-0 flex-shrink-0 flex-grow-0 flex rounded", bgColor)}></div>
    <div className="w-12 ml-3 mr-2 flex place-items-center justify-center flex-grow-0 flex-shrink-0"><Icon size={30} className={iconColor} /></div>
    <div className="flex-grow flex flex-col mx-3">
      <div className="font-bold">{title}</div>
      {typeof message === 'string' && <div>{message}</div>}
      {typeof message !== 'string' && (message)}
    </div>
    <div className="w-10 flex-grow-0 flex-shrink-0 flex justify-end place-items-center">
      <Icons.Times size={30} className="text-muted-body-text cursor-pointer hover:text-link-page-hover" onClick={() => toast.dismiss(t.id)} />
    </div>
  </div>
);
export const toastInfo = (title: string, message?: string | ReactNode, duration?: number): string => {
  return toast.custom(t => toastMessage(t, Icons.Info, "bg-info-icon", "text-info-icon", title, message), { duration: duration });

};
export const toastError = (title: string, message?: string | ReactNode): string =>
  toast.custom(t => toastMessage(t, Icons.Error, "bg-error-icon", "text-error-icon", title, message));
;
export const toastSuccess = (title: string, message?: string | ReactNode, duration?: number): string => {
  return toast.custom(t => toastMessage(t, Icons.Success, "bg-success-icon", "text-success-icon", title, message), { duration: duration });
};
export const toastWarning = (title: string, message?: string | ReactNode, duration?: number): string => {
  return toast.custom(t => toastMessage(t, Icons.Warning, "bg-warning-icon", "text-warning-icon", title, message), { duration: duration });
};
export const dismissToast = (toastId: string) => {
  toast.dismiss(toastId);
}

