import { DetectionZone, DetectionZoneType, Device, Facility, ScreenLine, Spot } from "@app/shared";
import { IconLink } from "../IconLink";
import { Icons } from "../Icons";
import { DetectionZoneConfig } from "./shapeConfigs/DetectionZoneConfig";
import { FovDeviceConfig } from "./shapeConfigs/FovDeviceConfig";
import { ScreenLineConfig } from "./shapeConfigs/ScreenLineConfig";
import { SpotConfig } from "./shapeConfigs/SpotConfig";
import { Shape } from "./SvgShape";
import { useMemo, useState } from "react";
import { HoverTooltip } from "../HoverTooltip";

type ShapeConfigurationProps = {
    id?: string,
    shape: Shape;
    facility: Facility;
    availableDevices: Device[];
    isSelected?: boolean;
    isEditable?: boolean;
    onDelete?: () => void;
    onSplit?: () => void;
    onCopy?: (shapes?: Array<DetectionZone | ScreenLine>) => void;
    onClick?: (shape: DetectionZone | ScreenLine | Device) => void;
    toggleEdit?: () => void;
    onChange: (shape: Shape) => void;
};

export const ShapeConfiguration: React.FC<ShapeConfigurationProps> = ({ facility, shape, availableDevices, onDelete, onChange, onCopy, onClick, onSplit, isSelected = false, isEditable = false, id, toggleEdit }) => {
    //const canEditName = shape.$type === Device.$type;
    const [isCollapsed, setIsCollapsed] = useState(true);

    const renderConfig = (shape: Shape) => {
        switch (shape.$type) {
            case Device.$type:
                return (<FovDeviceConfig facility={facility} device={shape as any as Device} availableDevices={availableDevices} onChange={onChange} isEditable={isEditable} isCollapsed={isCollapsed} />);
            case Spot.$type:
                return <SpotConfig spot={shape as any as Spot} onChange={onChange} isEditable={false} isCollapsed={isCollapsed} />;
            case DetectionZone.$type:
                return <DetectionZoneConfig currentZone={shape as any as DetectionZone} onChange={onChange} isEditable={isEditable} isCollapsed={isCollapsed} />;
            case ScreenLine.$type:
                return <ScreenLineConfig facility={facility} screenLine={shape as any as ScreenLine} onChange={onChange} isEditable={isEditable} isCollapsed={isCollapsed} />;
            default:
                return <div>Unknown shape type "{shape.$type}" - please contact administrators!</div>
        }
    }
    const containerClass = useMemo(() => {
        const std = "border border-separators-page rounded p-3  shadow mx-2";
        return isSelected ? 'ring ring-offset-2 ring-blue-500 m-[5px] ' + std : std;
    }, [isSelected]);
    const shapeTypeName = useMemo(() => shapeToName(shape as any), [shape]);

    return (
        <div id={id} className={containerClass} onClick={() => onClick ? onClick(shape as any) : undefined}>
            <div className="flex items-center">
                <div className="flex-grow">
                    <div className="flex justify-between">
                        <h1>{shape.name}</h1>
                    </div>
                    {!((shape.name?.toLowerCase().indexOf(shapeTypeName.toLowerCase()) ?? -1) >= 0) && <h3 className="ms-3 mt-[-.5rem] font-light">{shapeTypeName}</h3>}
                </div>
                <div id="buttons" className="flex flex-row justify-bottom mr-5">
                    {onSplit && isEditable && (shape.$type === DetectionZone.$type) && (shape as DetectionZone).points?.length === 5 &&//5 as we repeat the first point
                        <HoverTooltip placement="auto" hoverable={
                            <IconLink iconClassName="font-bold" Icon={Icons.SplitCells} onClick={x => { x.stopPropagation(); onSplit() }} />
                        }>
                            <p className="flex gap-3 place-items-center">
                                Split {shapeTypeName} into multiple equal parkings spots along the longest axis
                            </p>
                        </HoverTooltip>
                    }
                    {onCopy && isEditable && (shape.$type !== Spot.$type) &&
                        <HoverTooltip placement="auto" hoverable={
                            <IconLink Icon={Icons.Clipboard} onClick={x => { x.stopPropagation(); onCopy([shape as any]) }} />
                        }>
                            <p className="flex gap-3 place-items-center">
                                Copy {shapeTypeName}
                            </p>
                        </HoverTooltip>
                    }
                    {onDelete && isEditable && (shape.$type !== Spot.$type) &&

                        <HoverTooltip placement="auto" hoverable={
                            <IconLink className="!text-error-icon" Icon={Icons.Trash} onClick={onDelete} />
                        }>
                            <p className="flex gap-3 place-items-center text-error-icon">
                                Remove {shapeTypeName}
                            </p>
                        </HoverTooltip>
                    }
                    {toggleEdit && !isEditable && !isCollapsed &&

                        <HoverTooltip placement="top-start" hoverable={
                            <IconLink Icon={Icons.Edit} onClick={toggleEdit} />
                        }>
                            <p className="flex gap-3 place-items-center">
                                Edit {shapeTypeName}
                            </p>
                        </HoverTooltip>
                    }
                    {<HoverTooltip placement="top-start" hoverable={
                        <IconLink Icon={Icons.Expand} iconSize={24} onClick={() => setIsCollapsed(cv => !cv)} rotation={isCollapsed ? 0 : 180} />
                    }>
                        <p className="flex gap-3 place-items-center">
                            {isCollapsed ? 'Expand' : 'Collapse'} {shapeTypeName}
                        </p>
                    </HoverTooltip>}
                </div>
            </div>
            {renderConfig(shape)}
        </div>
    );
}

export function shapeToName(shape: Device | ScreenLine | Spot | DetectionZone) {
    switch (shape.$type) {
        case Device.$type:
            return 'Device';
        case DetectionZone.$type: {
            if ((shape as DetectionZone).zoneType === DetectionZoneType.ParkingSpot) {
                return 'Parking Spot';
            }
            return 'Detection Zone';
        }
        case ScreenLine.$type:
            return 'Screen Line';
        case Spot.$type:
            return 'Spot';
        default:
            return 'unknown';
    }
}
