import { TrafficReportProps } from "./IConfiguredTrafficReport";
import { AggregationLevel, getFHWAClassDisplayName, getTargetTypeDisplayName, Reports, TotalVolumeByPeriod } from "@app/shared";
import { useContext, useMemo } from "react";
import { getDefaultApexOptions, ReportContext, UNGROUPED_SERIES_NAME } from "./reportHelpers";
import { extLightThemeColors } from "../../styles/chartThemeColors";
import { ApexOptions } from "apexcharts";
import { useFetch } from "../../hooks";
import Chart from "react-apexcharts";
import { formatInTimeZone } from "date-fns-tz";
import _ from 'lodash';
import { add, isEqual } from "date-fns";
import useRenderingTrace from "../../hooks/useRenderingTrace";

export interface TotalVolumeDataLoaderProps extends TrafficReportProps {
    aggregationLevel: AggregationLevel,
    onClick?: (date: Date) => void
}
export interface TotalVolumeChartProps extends TrafficReportProps {
    aggregationLevel: AggregationLevel,
    data?: TotalVolumeByPeriod[],
    groupKey?: string,
    groupByTargetType: boolean,
    startDate: Date,
    endDate: Date,
    chartTimezone: string,
    chartType: 'bar' | 'line'
    onClick?: (date: Date) => void
}
export const TotalVolumeDataLoader: React.FC<TotalVolumeDataLoaderProps> = (props) => {
    const { searchParams, reportConfig } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => Reports.getTotalVolume(props.aggregationLevel, searchParams), [searchParams]);

    if (error && !isLoading) {
        return (<h2 className="mx-auto">{error}</h2>)
    }
    if (!data?.length || isLoading) {
        return null;
    }
    if (reportConfig.chartType === 'other') {
        throw 'unsupported chart type for total volume';
    }
    return <TotalVolumeChart {...props} data={data} startDate={searchParams.inclusiveStart} endDate={searchParams.exclusiveEnd} groupByTargetType={reportConfig.groupByTargetType} chartTimezone={reportConfig.chartTimezone} chartType={reportConfig.chartType} />;
}

export const TotalVolumeChart: React.FC<TotalVolumeChartProps> = (reportProps) => {
    const { aggregationLevel, size, subTitle, title, data, groupByTargetType, groupKey, chartTimezone, chartType, startDate, endDate } = reportProps;

    const getDateFormat = (numPeriods: number, start: Date, end: Date) => {
        const startMonth = formatInTimeZone(start, chartTimezone, 'MMMM');
        const endMonth = formatInTimeZone(end, chartTimezone, 'MMMM');
        const startYear = formatInTimeZone(end, chartTimezone, 'yyyy');
        const endYear = formatInTimeZone(end, chartTimezone, 'yyyy');
        const isSameMonth = startMonth === endMonth;
        const isSameYear = startYear === endYear;
        if (aggregationLevel === AggregationLevel.Hour) {
            return numPeriods > 24 ? 'M/dd HH:00' : 'H:mm';
        } else if (aggregationLevel === AggregationLevel.Year) {
            return 'yyyy';
        } else if (aggregationLevel === AggregationLevel.Month) {
            return numPeriods > 12 ? 'MMMM yyyy' : 'MMMM';
        } else {
            if (isSameYear) {
                if (isSameMonth) {
                    return 'd';
                }
                return 'MMM d';
            }
            return 'MMM dd yyyy';
        }
    }

    const stacksInData = useMemo(() => {
        if (!data?.length) { return []; }
        if (groupKey) {
            return Array.from(new Set(data.map(x => (x as any)[groupKey])));
        }
        return Array.from(new Set(data.map(x => x.targetType)));
    }, [data, groupKey]);

    const expandedStampedDates = useMemo(() => {
        if (!data?.length) return [];

        const intervalInMs = (interval: AggregationLevel): number | undefined => {
            switch (interval) {
                case AggregationLevel.Day: return 24 * 60 * 60 * 1000; // No approximation needed here
                case AggregationLevel.Hour: return 60 * 60 * 1000;
                case AggregationLevel.FiveMinutes: return 5 * 60 * 1000;
                default: return undefined; // Months and years will be handled differently
            }
        };

        const fillMissingDates = (data: TotalVolumeByPeriod[], beginning: Date, ending: Date, interval: AggregationLevel, timeZone: string): Array<Omit<TotalVolumeByPeriod, 'periodStart'> & { periodStart: number, isExpanded: boolean }> => {
            const expandedData: Array<TotalVolumeByPeriod & { isExpanded: boolean }> = [];
            const intervalMs = intervalInMs(interval);

            let currentDate = new Date(beginning);
            let endDate = new Date(ending);

            while (currentDate < endDate) {
                const existingData: Array<TotalVolumeByPeriod & { isExpanded: boolean }> = data.filter(d => isEqual(d.periodStart!, currentDate)).map(x => ({ ...x, isExpanded: false }));

                stacksInData.forEach(stack => {
                    var eDatum = existingData.find(x => x.targetType === stack || (groupKey?.length ? (x as any)[groupKey] === stack : false));
                    if (eDatum) {
                        expandedData.push(eDatum);
                        return;
                    }

                    var newDatum = { periodStart: new Date(currentDate), targetType: stack, avgVelocity: 0, count: 0, inCount: 0, outCount: 0, isExpanded: true }
                    if (groupKey?.length) { (newDatum as any)[groupKey] = stack; }
                    expandedData.push(newDatum);
                });



                if (intervalMs) {
                    currentDate = add(currentDate, { seconds: intervalMs / 1000 });
                } else {
                    // Handle month and year intervals without approximation
                    if (interval === AggregationLevel.Month) {
                        currentDate = add(currentDate, { months: 1 });
                    } else if (interval === AggregationLevel.Year) {
                        currentDate = add(currentDate, { years: 1 });
                    }
                }
            }

            return expandedData.map(x => ({ ...x, periodStart: x.periodStart!.getTime() })).sort((x, y) => x?.periodStart - y?.periodStart);
        };

        return fillMissingDates(data, startDate, endDate, aggregationLevel, chartTimezone);
    }, [stacksInData, startDate, endDate, aggregationLevel]);

    const xValues = useMemo(() => {
        return Array
            .from(new Set(expandedStampedDates.map(item => item.periodStart!) ?? []))
            .sort();
    }, [expandedStampedDates]);

    const dateFormat = useMemo(() => {
        if (!xValues.length) {
            return 'yyyy';
        }
        return getDateFormat(xValues.length, new Date(xValues[0]), new Date(xValues[xValues.length - 1]));
    }, [xValues]);
    const series = useMemo(() => {
        if (!stacksInData?.length) { return []; }


        const colorMap = createWrappedMap(stacksInData, extLightThemeColors);

        if (!groupByTargetType) {
            const gData = Object.values(expandedStampedDates?.reduce((acc, item) => {
                //const j = item;
                const period = item.periodStart;
                if (acc[period] === undefined) {
                    acc[period] = { periodStart: item.periodStart, count: item.count, isExpanded:item.isExpanded };
                } else {
                    acc[period].count += item.count;
                }
                return acc;
            }, {} as any as { [key: number]: { periodStart: number, count: number, isExpanded:boolean } }) ?? {});

            return [{
                name: UNGROUPED_SERIES_NAME,
                color: extLightThemeColors[0],
                labels: gData.map(x => formatInTimeZone(x.periodStart, chartTimezone, dateFormat)),
                data: gData.map(x => ({ x: x.periodStart, y: x.count, isExpanded: x.isExpanded }))
            }];
        }

        const r = [];

        for (const stackVal of stacksInData) {//stack val is target type
            if (groupKey) { //use FHWA instead of TT
                r.push({
                    name: getFHWAClassDisplayName(stackVal),
                    color: colorMap.get(stackVal),
                    expansions: expandedStampedDates?.filter(x => (x as any)[groupKey] === stackVal).map(x => x.isExpanded),
                    data: expandedStampedDates?.filter(x => (x as any)[groupKey] === stackVal).map(x => x.count) as Array<number> //data is positional so must be sorted by xKey
                });
            } else {
                r.push({
                    name: getTargetTypeDisplayName(stackVal),
                    color: colorMap.get(stackVal),
                    expansions: expandedStampedDates?.filter(x => x.targetType === stackVal).map(x => x.isExpanded),
                    data: expandedStampedDates?.filter(x => x.targetType === stackVal).map(x => x.count) as Array<number> //data is positional so must be sorted by xKey
                });
            }
        }

        return r;

    }, [expandedStampedDates, stacksInData, groupByTargetType, groupKey]);

    var options = useMemo(() => {
        if (!xValues?.length) {
            return undefined;
        }
        return _.merge(getDefaultApexOptions(reportProps), {

            xaxis: {
                categories: xValues,
                type: 'datetime',
                tickPlacement: 'on',
                tickAmount: Math.min(90, xValues.length - 2),
                labels: {
                    crosshairs: {
                        show: false
                    },
                    formatter: function (value: string, timestamp: number, opts: any) {
                        if (xValues[opts?.i]) {
                            return formatInTimeZone(xValues[opts.i], chartTimezone, dateFormat);
                        }
                    }
                }
            },
            legend: {
                customLegendItems: groupKey ? stacksInData.map(getFHWAClassDisplayName) : stacksInData.map(getTargetTypeDisplayName),
            }
        } as ApexOptions)
    }, [stacksInData, chartTimezone, title, subTitle, groupByTargetType, xValues, chartType]);
    useRenderingTrace('chart type change', chartType);
    return series.length === 0 ? null : <Chart
        options={options}
        series={series}
        type={chartType}
        width="100%"
        height={size.height ? size.height - 80 : undefined}
    />
}
function createWrappedMap<T, U>(array1: T[], array2: U[]): Map<T, U> {
    const map = new Map<T, U>();
    for (let i = 0; i < array1.length; i++) {
        const wrappedIndex = i % array2.length;
        map.set(array1[i], array2[wrappedIndex]);
    }
    return map;
}
