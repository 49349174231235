export enum DetectionZoneType {
	ParkingSpot = 0,
	SmartSpot = 1,
	ParkingArea = 4,
}

export const DetectionZoneTypeDisplayNames: { [key in keyof typeof DetectionZoneType]?: string} = {
	"ParkingSpot": "ParkingSpot",
	0 : "ParkingSpot",
	"SmartSpot": "SmartSpot",
	1 : "SmartSpot",
	"ParkingArea": "ParkingArea",
	4 : "ParkingArea",
}

export const getDetectionZoneTypeDisplayName = (key: DetectionZoneType | (keyof typeof DetectionZoneType & string)) => {
	return DetectionZoneTypeDisplayNames[key];
}

