import { FirmwareType } from "./FirmwareType.gen";
import { DeviceType } from "./Shared.DeviceType.gen";
import { SensorType } from "./Shared.SensorType.gen";
import { FirmwareVersionType } from "./FirmwareVersionType.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsOptional } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IFirmware {
	id: string | undefined;

	dateCreated: string | undefined;

	firmwareType: FirmwareType;

	deviceType: DeviceType;

	sensorType: SensorType;

	firmwareVersionMajor: number;

	firmwareVersionMinor: number;

	firmwareVersionType: FirmwareVersionType;

	firmwareVersionPatch: number;

	firmwareFileName: string | undefined;

	firmwareHash: string | undefined;

	firmwareVersion: string | undefined;

	$type?: string | undefined;
}
export class Firmware {
	constructor(data?: IFirmware) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.dateCreated) { this.dateCreated = new Date(data.dateCreated); }
	}

	id: string | undefined;

	dateCreated: Date | undefined = new Date();

	firmwareType: FirmwareType = 0;

	deviceType: DeviceType = 0;

	sensorType: SensorType = 0;

	firmwareVersionMajor: number = 0;

	firmwareVersionMinor: number = 0;

	firmwareVersionType: FirmwareVersionType = 0;

	firmwareVersionPatch: number = 0;

	firmwareFileName: string | undefined;

	firmwareHash: string | undefined;

	firmwareVersion: string | undefined;

	static $type: string = "CoreApi.Models.Firmware, ";
	$type?: string | undefined = Firmware.$type;
}

