import { createContext, ProviderProps, useState } from "react";

export const ContainerContext = createContext<ContextValueType | undefined>(undefined);
type ContextValueType = {
    pushContainer: (containerId?: string, visible?: boolean) => string,
    popContainer: (containerId: string) => void,
    setVisible: (containerId: string, visible: boolean) => void
}
export type ContainerInfoType = {
    id: string,
    visible: boolean
}
var currentContainerId = 0;

export const ContainerContextProvider = (props: Omit<ProviderProps<ContextValueType>, 'value'>) => {
    const [containers, setContainers] = useState<ContainerInfoType[]>([]);

    const pushContainer = (containerId?: string, visible?: boolean) => {
        const newContainerId = containerId ?? "div_p_" + currentContainerId++;
        setContainers(prev => [...prev, { id: newContainerId, visible: visible ?? true }]);
        return newContainerId;
    }
    const popContainer = (containerId: string) => {
        setContainers(prev => prev.filter(c => c.id !== containerId));
    }
    const setVisible = (containerId: string, visible: boolean) => {
        setContainers(prev => prev.map(c => c.id === containerId ? { ...c, visible } : c));
    }

    var visibleContainers = containers.filter(c => c.visible);
    var lastVisibleId: string | undefined = undefined;
    if (visibleContainers.length >= 1)
        lastVisibleId = visibleContainers[visibleContainers.length - 1].id;

    return (
        <ContainerContext.Provider value={{ pushContainer, popContainer, setVisible }} {...props}>
            {props.children}
            {containers && containers.map(container => (
                <div key={container.id} id={container.id} style={{ visibility: ((!lastVisibleId || lastVisibleId === container.id) && container.visible ? 'visible' : 'hidden') }} />
            ))}
        </ContainerContext.Provider>
    );
}