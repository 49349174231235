import { useAuth } from "@app/shared";
import { FC } from "react";
import { TabbedDashLayout } from "../layouts/TabbedDashLayout";
import { EventTriggerTable } from "../shared/EventTriggerTable";
import { ClientEndpointTable } from "./facility/ClientEndpointTable";

export const ClientEndpoints: FC = (props) => {
    const { user, currentOrganizationId } = useAuth();

    var key = 1;

    return (
        <TabbedDashLayout centerChildren="Integrations"
            tabs={[
                { title: "Events", child: <EventTriggerTable organizationId={currentOrganizationId ?? ""} /> },
                { title: "Endpoints", child: <ClientEndpointTable organizationId={currentOrganizationId ?? ""} /> },
                // { title: "History", child: <>History</> },
            ]}
        />
    );
}