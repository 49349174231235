import {
    EmailClientEndpoint,
    JsonClientEndpoint,
    LuxcomClientEndpoint,
    NtcipDmsClientEndpoint,
    WebRelayClientEndpoint
} from "@app/shared";
import { InputRow, Select } from "../inputs";
import { EditEmailClientEndpoint } from "./EditEmailClientEndpoint";
import { EditJsonClientEndpoint } from "./EditJsonClientEndpoint";
import { EditLuxcomClientEndpoint } from "./EditLuxcomClientEndpoint";
import { EditNtcipDmsClientEndpoint } from "./EditNtcipDmsClientEndpoint";
import { EditWebRelayClientEndpoint } from "./EditWebRelayClientEndpoint";


export type ClientEndpointType = EmailClientEndpoint | JsonClientEndpoint | LuxcomClientEndpoint | NtcipDmsClientEndpoint | WebRelayClientEndpoint;

export type ClientEndpointEditorProps = {
    endpoint: ClientEndpointType | undefined;
    onChange: (endpoint: ClientEndpointType | undefined) => any;
};

const getEditor = (props: ClientEndpointEditorProps) => {

    switch (props.endpoint!.$type) {
        case EmailClientEndpoint.$type:
            return <EditEmailClientEndpoint {...props} />
        case JsonClientEndpoint.$type:
            return <EditJsonClientEndpoint {...props} />
        case LuxcomClientEndpoint.$type:
            return <EditLuxcomClientEndpoint {...props} />
        case NtcipDmsClientEndpoint.$type:
            return <EditNtcipDmsClientEndpoint {...props} />
        case WebRelayClientEndpoint.$type:
            return <EditWebRelayClientEndpoint {...props} />
        default:
            return <></>
    }
}

export const ClientEndpointEditor = (props: ClientEndpointEditorProps) => {
    if (!props || !props.endpoint)
        return <>No endpoint to edit.  Please try again</>;
    return (
        <>
            <InputRow>
                <Select label="Endpoint Type" emptyOptionText="Select Endpoint Type" emptyOptionDisabled={false} options={[
                    { label: "Closure (WebRelay)", value: WebRelayClientEndpoint.$type },
                    { label: "Email", value: EmailClientEndpoint.$type },
                    { label: "Json", value: JsonClientEndpoint.$type },
                    { label: "NTCIP DMS", value: NtcipDmsClientEndpoint.$type },
                    { label: "SDLC (Luxcom)", value: LuxcomClientEndpoint.$type },
                ]}
                    disabled={!!props?.endpoint?.id}
                    value={props?.endpoint?.$type}
                    onChange={(v) => props?.onChange((props && props?.endpoint) ? { ...props.endpoint, $type: v.currentTarget?.value } : undefined)}
                />
            </InputRow>
            {getEditor(props)}
        </>
    );
}  
