import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { TotalVolumeDataLoader } from "./TotalVolumeDataLoader";
import { TotalVolumeByDay } from "./TotalVolumeByDay";
import { useContext } from "react";
import { ReportContext } from "./reportHelpers";
import { zonedTimeToUtc } from "date-fns-tz";
import { AggregationLevel } from "@app/shared";

const MonthlyDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, updateReportConfig } = useContext(ReportContext);

    function handleClick(date: Date): void {
        const startLocal = new Date(date.getFullYear(), date.getMonth(), 1);
        const end = new Date(date.getFullYear(), (date.getMonth() + 1) % 12, 1);

        end.setMilliseconds(-1);

        const cfg = {
            ...reportConfig,
            selectedReportId: TotalVolumeByDay.key,
            startTime: zonedTimeToUtc(startLocal, reportConfig.chartTimezone),
            endTime: zonedTimeToUtc(end, reportConfig.chartTimezone)
        };
        updateReportConfig(cfg);
    }

    return (<TotalVolumeDataLoader
        {...props}
        aggregationLevel={AggregationLevel.Month}
    />);
}

export const TotalVolumeByMonth: IConfiguredTrafficReport = {
    name: "by Month",
    key: "tvm",
    description: "This report shows total traffic for the specified period grouped by months",
    defaultChartType: 'bar',
    component: MonthlyDataLoader,
    defaultRange: DateOptions.ThisYear,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};