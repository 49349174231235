import { Int32, IInt32 } from "./System.Int32.gen";

export interface IRamUsageInfo {
	statusTimestamp: string | undefined;

	maxRam: number | undefined;

	usedRam: number | undefined;

	maxSwap: number | undefined;

	usedSwap: number | undefined;

	maxIram: number | undefined;

	usedIram: number | undefined;

	emcUsage: number | undefined;

	emcFrequency: number | undefined;

	$type?: string | undefined;
}
export class RamUsageInfo {
	constructor(data?: IRamUsageInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.statusTimestamp) { this.statusTimestamp = new Date(data.statusTimestamp); }
	}

	statusTimestamp: Date | undefined = new Date();

	maxRam: number | undefined;

	usedRam: number | undefined;

	maxSwap: number | undefined;

	usedSwap: number | undefined;

	maxIram: number | undefined;

	usedIram: number | undefined;

	emcUsage: number | undefined;

	emcFrequency: number | undefined;

	static $type: string = "CoreApi.Models.View.Hardware.RamUsageInfo, ";
	$type?: string | undefined = RamUsageInfo.$type;
}

