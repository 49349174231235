import { SensorConfiguration, ISensorConfiguration } from "./SensorConfiguration.gen";
import { Target, ITarget } from "./Target.gen";
import { DeviceNote, IDeviceNote } from "./DeviceNote.gen";
import { Boolean, IBoolean } from "./System.Boolean.gen";
import { Vector2, IVector2 } from "./System.Numerics.Vector2.gen";
import { Guid, IGuid } from "./System.Guid.gen";
import { DateTime, IDateTime } from "./System.DateTime.gen";
import { Int32, IInt32 } from "./System.Int32.gen";
import { Single, ISingle } from "./System.Single.gen";
import { RadarConfiguration, IRadarConfiguration } from "./RadarConfiguration.gen";
import { DeviceType } from "./Shared.DeviceType.gen";
import { SensorType } from "./Shared.SensorType.gen";
import { OperationMode } from "./Shared.OperationMode.gen";
import { DeviceStatus } from "./DeviceStatus.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty, IsOptional } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IDevice {
	facilityName: string | undefined;

	sensorConfig: ISensorConfiguration | undefined | IRadarConfiguration;

	activeTargets: ITarget[] | undefined;

	notes: IDeviceNote[] | undefined;

	typeAsString: string | undefined;

	statusDateAsString: string | undefined;

	hasPendingDeviceCommands: boolean | undefined;

	lastDeviceCommandDidSucceed: boolean | undefined;

	lastDeviceCommandResponse: string | undefined;

	cameraAoiPoints: IVector2[] | undefined;

	homographySourcePoints: IVector2[] | undefined;

	homographyDestinationPoints: IVector2[] | undefined;

	boundaryPoints: IVector2[] | undefined;

	id: string | undefined;

	organizationId: string | undefined;

	facilityId: string | undefined;

	type: DeviceType;

	sensorType: SensorType;

	mode: OperationMode;

	dateAllocated: string | undefined;

	serialNumber: string | undefined;

	hardwareVersion: number | undefined;

	isDeleted: boolean;

	deviceKey: string;

	name: string;

	description: string | undefined;

	color: string | undefined;

	enableDhcp: boolean;

	macAddress: string | undefined;

	ipv4Address: string | undefined;

	gatewayAddresses: string | undefined;

	dnsServers: string | undefined;

	ntpServers: string | undefined;

	proxyIpAddress: string | undefined;

	port: number;

	firmwareVersion: string | undefined;

	sensorFirmwareVersion: string | undefined;

	positionX: number;

	positionY: number;

	height: number;

	headingDegrees: number;

	desiredTiltDegrees: number;

	takeTargetPhotos: boolean;

	minSecondsBetweenPhotos: number | undefined;

	alwaysStreamVideo: boolean;

	videoFps: number;

	videoResolutionX: number;

	videoResolutionY: number;

	inferrenceFps: number;

	snapshotResolutionX: number;

	snapshotResolutionY: number;

	videoBitrateBps: number;

	secondsBetweenHeartbeat: number;

	lastHeartbeat: string | undefined;

	lastHeartbeatLatencyMs: number;

	statusMessage: string | undefined;

	lastStatusChange: string | undefined;

	status: DeviceStatus;

	$type?: string | undefined;
}
export class Device {
	constructor(data?: IDevice) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.sensorConfig && data.sensorConfig.$type === RadarConfiguration.$type) { this.sensorConfig = new RadarConfiguration(data.sensorConfig as IRadarConfiguration); } else
		if (data.sensorConfig) { this.sensorConfig = new SensorConfiguration(data.sensorConfig); }
		this.activeTargets = data.activeTargets ? data.activeTargets.map((a: any) => new Target(a)) : [];
		this.notes = data.notes ? data.notes.map((n: any) => new DeviceNote(n)) : [];
		this.cameraAoiPoints = data.cameraAoiPoints ? data.cameraAoiPoints.map((c: any) => new Vector2(c)) : [];
		this.homographySourcePoints = data.homographySourcePoints ? data.homographySourcePoints.map((h: any) => new Vector2(h)) : [];
		this.homographyDestinationPoints = data.homographyDestinationPoints ? data.homographyDestinationPoints.map((h: any) => new Vector2(h)) : [];
		this.boundaryPoints = data.boundaryPoints ? data.boundaryPoints.map((b: any) => new Vector2(b)) : [];
		if (data.dateAllocated) { this.dateAllocated = new Date(data.dateAllocated); }
		if (data.lastHeartbeat) { this.lastHeartbeat = new Date(data.lastHeartbeat); }
		if (data.lastStatusChange) { this.lastStatusChange = new Date(data.lastStatusChange); }
	}

	facilityName: string | undefined;

	sensorConfig: SensorConfiguration | undefined | RadarConfiguration;

	activeTargets: Target[] | undefined = [];

	notes: DeviceNote[] | undefined = [];

	typeAsString: string | undefined;

	statusDateAsString: string | undefined;

	hasPendingDeviceCommands: boolean | undefined;

	lastDeviceCommandDidSucceed: boolean | undefined;

	lastDeviceCommandResponse: string | undefined;

	cameraAoiPoints: Vector2[] | undefined = [];

	homographySourcePoints: Vector2[] | undefined = [];

	homographyDestinationPoints: Vector2[] | undefined = [];

	boundaryPoints: Vector2[] | undefined = [];

	id: string | undefined;

	organizationId: string | undefined;

	facilityId: string | undefined;

	type: DeviceType = 0;

	sensorType: SensorType = 0;

	mode: OperationMode = 0;

	dateAllocated: Date | undefined;

	serialNumber: string | undefined;

	hardwareVersion: number | undefined;

	isDeleted: boolean = false;

	@IsNotEmpty()
	deviceKey: string = "";

	@IsNotEmpty()
	name: string = "";

	description: string | undefined;

	color: string | undefined;

	enableDhcp: boolean = false;

	macAddress: string | undefined;

	ipv4Address: string | undefined;

	gatewayAddresses: string | undefined;

	dnsServers: string | undefined;

	ntpServers: string | undefined;

	proxyIpAddress: string | undefined;

	port: number = 0;

	firmwareVersion: string | undefined;

	sensorFirmwareVersion: string | undefined;

	positionX: number = 0;

	positionY: number = 0;

	height: number = 0;

	headingDegrees: number = 0;

	desiredTiltDegrees: number = 0;

	takeTargetPhotos: boolean = false;

	minSecondsBetweenPhotos: number | undefined;

	alwaysStreamVideo: boolean = false;

	videoFps: number = 0;

	videoResolutionX: number = 0;

	videoResolutionY: number = 0;

	inferrenceFps: number = 0;

	snapshotResolutionX: number = 0;

	snapshotResolutionY: number = 0;

	videoBitrateBps: number = 0;

	secondsBetweenHeartbeat: number = 0;

	lastHeartbeat: Date | undefined = new Date();

	lastHeartbeatLatencyMs: number = 0;

	statusMessage: string | undefined;

	lastStatusChange: Date | undefined = new Date();

	status: DeviceStatus = 0;

	static $type: string = "CoreApi.Models.Device, ";
	$type?: string | undefined = Device.$type;
}

