export enum EndpointStatus {
	Unknown = 0,
	Healthy = 1,
	Warning = 2,
	Error = 3,
}

export const EndpointStatusDisplayNames: { [key in keyof typeof EndpointStatus]?: string} = {
	"Unknown": "Unknown",
	0 : "Unknown",
	"Healthy": "Healthy",
	1 : "Healthy",
	"Warning": "Warning",
	2 : "Warning",
	"Error": "Error",
	3 : "Error",
}

export const getEndpointStatusDisplayName = (key: EndpointStatus | (keyof typeof EndpointStatus & string)) => {
	return EndpointStatusDisplayNames[key];
}

