export enum TargetType {
	Unknown = 0,
	SemiTrailerTruck = 1,
	PassengerTruck = 2,
	Car = 4,
	Motorcycle = 8,
	Bike = 16,
	Pedestrian = 32,
	Camper = 64,
	Bus = 128,
	Trailer = 256,
	Boat = 512,
	UtilityVehicle = 1024,
	Bobtail = 2048,
	Wheel = 4096,
}

export const TargetTypeDisplayNames: { [key in keyof typeof TargetType]?: string} = {
	"Unknown": "Unknown",
	0 : "Unknown",
	"SemiTrailerTruck": "Semi Truck",
	1 : "Semi Truck",
	"PassengerTruck": "Passenger Truck",
	2 : "Passenger Truck",
	"Car": "Car",
	4 : "Car",
	"Motorcycle": "Motorcycle",
	8 : "Motorcycle",
	"Bike": "Bike",
	16 : "Bike",
	"Pedestrian": "Pedestrian",
	32 : "Pedestrian",
	"Camper": "Camper",
	64 : "Camper",
	"Bus": "Bus",
	128 : "Bus",
	"Trailer": "Trailer",
	256 : "Trailer",
	"Boat": "Boat",
	512 : "Boat",
	"UtilityVehicle": "Utility Vehicle",
	1024 : "Utility Vehicle",
	"Bobtail": "Bobtail",
	2048 : "Bobtail",
	"Wheel": "Wheel",
	4096 : "Wheel",
}

export const getTargetTypeDisplayName = (key: TargetType | (keyof typeof TargetType & string)) => {
	return TargetTypeDisplayNames[key];
}

