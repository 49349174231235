import { useCallback, useEffect, useRef } from "react";

export type MapMouseWheelEvent = { magnitude: number } & WheelEvent;
export type MapMouseWheelHandler = (e: MapMouseWheelEvent) => void;

export const useMouseWheel = (handler: MapMouseWheelHandler, dependencies?: any[]) => {
    const lastWheel = useRef(Date.now());

    const getWheelMagnitude = useCallback((e: WheelEvent) => {
        const now = Date.now();
        const delta = now - lastWheel.current;
        lastWheel.current = now;
        if (delta > 150) {
            return (e.deltaY + e.deltaZ + e.deltaX) > 0 ? 1 : -1;
        } else if (delta > 75) {
            return (e.deltaY + e.deltaZ + e.deltaX) > 0 ? 5 : -5;
        }
        return (e.deltaY + e.deltaZ + e.deltaX) > 0 ? 10 : -10;
    }, []);

    const localHandleWheel = (e: WheelEvent) => {
        const mag = getWheelMagnitude(e);
        handler({ ...e, magnitude: mag } as MapMouseWheelEvent);
    };

    useEffect(() => {
        document.addEventListener('wheel', localHandleWheel);
        return () => {
            document.removeEventListener('wheel', localHandleWheel);
        };
    }, dependencies);// eslint-disable-line react-hooks/exhaustive-deps
}