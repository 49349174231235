export interface ITemperatureInfo {
	id: string | undefined;

	cpu: number;

	pll: number;

	wifi: number;

	pmic: number;

	gpu: number;

	thermal: number;

	ao: number;

	$type?: string | undefined;
}
export class TemperatureInfo {
	constructor(data?: ITemperatureInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	cpu: number = 0;

	pll: number = 0;

	wifi: number = 0;

	pmic: number = 0;

	gpu: number = 0;

	thermal: number = 0;

	ao: number = 0;

	static $type: string = "CoreApi.Models.Hardware.TemperatureInfo, ";
	$type?: string | undefined = TemperatureInfo.$type;
}

