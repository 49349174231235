import { useEffect, useRef } from "react";
import { scaleAndRotatePaths } from "../helpers";
import { HeldKeyEvent, MapMouseWheelEvent, useHeldKeyboardKeys, useMouseWheel } from ".";

export const usePolygonScaleAndRotation = (map: google.maps.Map | undefined, poly: google.maps.Polygon | google.maps.Polyline | undefined, when: () => boolean, onUpdate: () => void, getVertexCircles?: () => google.maps.Circle[][], dependencies?: any[]) => {
    const zPressed = useRef(false);
    const shiftPressed = useRef(false);

    const handleWheel = (e: MapMouseWheelEvent) => {
        if (!zPressed.current || !when()) { return; }
        const op = shiftPressed.current ? 'scale' : 'rotate';
        scaleAndRotatePaths(op, poly, e.magnitude, getVertexCircles?.());
        onUpdate();
    }

    const handleArrowKeys = (e: HeldKeyEvent) => {
        if (!zPressed.current || !when()) { return; }

        Object.keys(e.keysDown).forEach(code => {
            switch (code) {
                case 'ArrowLeft':
                    scaleAndRotatePaths('rotate', poly, -1 * e.keysDown[code], getVertexCircles?.());
                    break;
                case 'ArrowUp':
                    scaleAndRotatePaths('scale', poly, e.keysDown[code], getVertexCircles?.());
                    break;
                case 'ArrowRight':
                    scaleAndRotatePaths('rotate', poly, e.keysDown[code], getVertexCircles?.());
                    break;
                case 'ArrowDown':
                    scaleAndRotatePaths('scale', poly, -1 * e.keysDown[code], getVertexCircles?.());
                    break;
            }
        });
        onUpdate();
    }

    useMouseWheel(handleWheel, dependencies);
    useHeldKeyboardKeys(handleArrowKeys, true, dependencies);

    const handleKeyDown = (e: KeyboardEvent) => {//browser uses ctl for scaling and alt for history so we can't really use those keys cleanly
        if (e.code === 'KeyZ') { zPressed.current = true; }
        shiftPressed.current = e.shiftKey;
    }

    const handleKeyUp = (e: KeyboardEvent) => {
        if (e.code === 'KeyZ') { zPressed.current = false; }
        shiftPressed.current = e.shiftKey;
    }

    useEffect(() => { // useKeys
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        const original = map?.get('keyboardShortcuts');
        map?.set('keyboardShortcuts', false);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            map?.set('keyboardShortcuts', original);
        }
    }, dependencies);// eslint-disable-line react-hooks/exhaustive-deps
}