import cn from "classnames";
import { MouseEventHandler, PropsWithChildren, ReactEventHandler, useRef } from "react";

type DrawingContainerProps = {
    imageSrc: string;
    imageWidth: number;
    imageHeight: number;
    onImageLoad?: ReactEventHandler<HTMLImageElement>;
    onClick?: (point: [number, number]) => any;
    onCurrentPointChange?: (point: [number, number] | undefined) => any;
    imgClassName?: string;
    className?: string;
} & PropsWithChildren;

export const DrawingContainer: React.FC<DrawingContainerProps> = ({ imageSrc, imageWidth, imageHeight, onClick, onImageLoad, onCurrentPointChange, children, className, imgClassName }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    /* Get the point in image coordinates of the mouseEvent argument */
    const getPoint = (mouseEvent: React.MouseEvent): [number, number] => {
        const boundingRect = containerRef.current?.getBoundingClientRect();
        if (!boundingRect || !imageHeight || !imageWidth) {
            throw new Error("Unable to determine coordinate system for point");
        }

        var x = (mouseEvent.clientX - boundingRect.left) / boundingRect.width * imageWidth;
        var y = (mouseEvent.clientY - boundingRect.top) / boundingRect.height * imageHeight;

        return [x, y];
    };

    const handleClick: MouseEventHandler = (e) => {
        e.preventDefault();
        var position = getPoint(e);
        if (onClick) {
            onClick(position);
        }
    };

    const handleMouseMove: MouseEventHandler = async (e) => {
        e.preventDefault();
        var position = await getPoint(e);
        if (onCurrentPointChange) {
            onCurrentPointChange(position);
        }
    };

    const handleMouseLeave: MouseEventHandler = async (e) => {
        e.preventDefault();

        if (onCurrentPointChange) {
            onCurrentPointChange(undefined);
        }
    };

    return (
        <div ref={containerRef} id="source-layout" className={className ?? "relative"} onClick={handleClick} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} >
            <img
                onLoad={onImageLoad}
                className={imgClassName ?? "w-full rounded"}
                src={imageSrc}
                alt="Background"
            />
            <svg className={cn(imgClassName ?? "w-full h-full", "absolute top-0 left-0")}
                version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox={`0 0 ${imageWidth} ${imageHeight}`}
            >
                {children}
            </svg>
        </div>
    );
}

