import { ValidationError } from "class-validator";
import _ from "lodash";
import { FunctionComponent, ReactElement, useState } from "react";

interface RenderProps {
  label: string;
  handleBlur: () => Promise<void>;
}

interface ValidatedInputGroupProps {
  fieldName?: string;
  validationError: ValidationError | undefined;
  validate?: () => void;
  children: (props: RenderProps) => ReactElement;
};

function messageForError(error: ValidationError, displayName: string = '') {
  const messages = Object.values(error.constraints!);
  const selectedMessage = _.first(messages) || 'An unknown validation error occurred';

  return selectedMessage.replace(error.property, displayName);
}

export const ValidatedInputGroup: FunctionComponent<ValidatedInputGroupProps> = (props) => {
  const [hasBlurred, setHasBlurred] = useState(false);

  const { fieldName, children, validationError, validate } = props;
  const label = _.startCase(fieldName).toLocaleLowerCase();

  const isInvalid = !!validationError;

  const handleBlur = async () => {
    if (validate)
      validate();
    setHasBlurred(true);
  }

  const classes = [
    isInvalid ? 'not-valid' : undefined,
    hasBlurred ? 'has-been-validated' : undefined,
  ].join(' ');

  return (
    <span className={classes}>
      {children({ label, handleBlur })}
      {validationError && (
        <span className="invalid">
          {messageForError(validationError, label)}
        </span>
      )}
    </span>
  );
}