import { Int32, IInt32 } from "./System.Int32.gen";

export interface IClientEndpointHistory {
	id: string | undefined;

	clientEndpointId: string | undefined;

	dateCreated: string | undefined;

	postBody: string | undefined;

	responseCode: number | undefined;

	response: string | undefined;

	exceptionMessage: string | undefined;

	millisecondsToRespond: number | undefined;

	$type?: string | undefined;
}
export class ClientEndpointHistory {
	constructor(data?: IClientEndpointHistory) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.dateCreated) { this.dateCreated = new Date(data.dateCreated); }
	}

	id: string | undefined;

	clientEndpointId: string | undefined;

	dateCreated: Date | undefined = new Date();

	postBody: string | undefined;

	responseCode: number | undefined;

	response: string | undefined;

	exceptionMessage: string | undefined;

	millisecondsToRespond: number | undefined;

	static $type: string = "CoreApi.Models.ClientEndpointHistory, ";
	$type?: string | undefined = ClientEndpointHistory.$type;
}

