import { PropsWithChildren, ReactNode, ReactElement, useRef, useState } from "react";
import { IconType } from "react-icons";
import { HoverTooltip } from "./HoverTooltip";

type DataInfoCardProps = {
    Icon: IconType;
    title: string;
    hoverText?: ReactNode | ReactElement;
} & PropsWithChildren;

export const DataInfoCard: React.FC<DataInfoCardProps> = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const popperButtonRef = useRef(null);

    const InfoCard = (<div ref={popperButtonRef} className="border border-separators-page p-5 flex flex-1 gap-3 min-w-min flex-grow shadow-sm" onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} >
        <div className="text-center bg-background-page-highlight-gray rounded-full h-16 w-16 flex flex-col items-center">
            <props.Icon className="h-10 w-10 mx-auto my-auto" />
        </div>
        <div className="flex flex-col flex-grow text-right gap-1">
            <p className="uppercase text-lg whitespace-nowrap">{props.title}</p>
            <h2 className="text-4xl font-bold uppercase">{props.children}</h2>
        </div>
    </div>);

    return props.hoverText ? <HoverTooltip show={showTooltip} popperButtonRef={popperButtonRef} hoverable={InfoCard}>{props.hoverText}</HoverTooltip> : InfoCard;
}