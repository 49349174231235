import { Users } from "@app/shared";
import React from 'react';
import { useFetch } from "../../../hooks";

type UserRoleInputProps = {
    onChange: (newRoles: string[]) => void;
    value: string[];
};

export const UserRoleInput: React.FC<UserRoleInputProps> = (props) => {
    const [allRoles, , allRolesHelpers] = useFetch(Users.getAllRoles);
    return <div>
        {!allRoles && allRolesHelpers.isLoading && <div>Loading...</div>}
        {allRoles?.map(r => (
            <div key={r.value} className="flex flex-row place-items-center gap-2">
                <label>
                    <input
                        className="border-0 shadow-none w-8"
                        type="checkbox"
                        value={r.value}
                        onChange={(e) => {
                            if (e.target.checked)
                                props.onChange([...props.value, r.value!]);
                            else
                                props.onChange(props.value.filter(o => o !== r.value!));
                        }}
                        checked={props.value.findIndex((o) => o === r.value!) >= 0} />
                    {r.label}
                </label>
            </div>
        ))}
    </div>
}