export interface IOptionValueString {
	label: string | undefined;

	value: string | undefined;

}
export class OptionValueString {
	constructor(data?: IOptionValueString) {
		if (!data) return;
		Object.assign(this, data);
		if ((this as any).$type) { delete (this as any).$type; };
	}

	label: string | undefined;

	value: string | undefined;

}

