import { createContext, ProviderProps, ReactNode, useState } from "react";
import { Modal, ModalProps } from "./";

export const ModalStackContext = createContext<ContextValueType | undefined>(undefined);

type ModalStackItem = {
    element: ReactNode,
    modalProps?: ModalProps
}
type ContextValueType = {
    pushModal: (toPush: ModalStackItem | ReactNode) => void | any,
    popModal: () => void | any
}

function isModalStackItem(item: ModalStackItem | ReactNode): item is ModalStackItem {
    return (item as ModalStackItem).element !== undefined;
}

export const ModalStackProvider = (props: Omit<ProviderProps<ModalStackItem[]>, 'value'>) => {
    const [modals, setModals] = useState<ModalStackItem[]>([]);

    const pushModal = (toPush: ModalStackItem | ReactNode) => {
        setModals((val) => {
            if (isModalStackItem(toPush)) {
                return [...val, toPush];
            }
            return [...val, { element: toPush }];
        })
    }
    const popModal = () => {
        if (modals.length < 1) {
            console.warn("Unable to pop modal because there are no modals left!");
            return;
        }

        setModals((val) => {
            return val.slice(0, -1);
        });
    }

    return (
        <ModalStackContext.Provider value={{ pushModal, popModal }} {...props}>
            {props.children}
            <Modal show={modals.length > 0} onClickOutside={() => { }}>
                {modals.length && modals[modals.length - 1].element}
            </Modal>
        </ModalStackContext.Provider>
    );
}