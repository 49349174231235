/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, put, del } from "./api_client";
import { DeviceCommand, IDeviceCommand } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function sendCommand(command: DeviceCommand): ApiResult<boolean> {
	return put<boolean>(`/api/devicecommand`, command);
}

export async function resendCommand(id?: string): ApiResult<undefined> {
	return put(`/api/devicecommand/${ id }/resend`);
}

export async function getById(id?: string): ApiResult<DeviceCommand> {
	return get<IDeviceCommand>(`/api/devicecommand/${ id }`).then(result => result === undefined ? undefined :  new DeviceCommand(result));
}

export async function deleteById(id?: string): ApiResult<undefined> {
	return del(`/api/devicecommand/${ id }`);
}
