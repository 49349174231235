import { ClientEndpoints, Devices, EventTriggerDevice, EventTriggerFacility, Facilities } from "@app/shared";
import { useEffect } from "react";
import { Model, useFetch, useModel } from "../../../hooks";
import { CheckboxInput, InputRow, Select } from "../inputs";
import { MultiSelect, SimpleOption } from "../inputs/MultiSelect";
import { EventTriggerEditorProps, EventTriggerType } from "./EventTriggerEditor";

type EditEventTriggerProps = {
    model: Model<EventTriggerType>;
    hasScreenline?: boolean;
    hasDetectionZone?: boolean;
} & EventTriggerEditorProps;

export function EditEventTrigger(props: EditEventTriggerProps) {
    const [detectionZones] = useFetch(() => props.hasDetectionZone ? Facilities.getDetectionZoneOptions(props.trigger?.organizationId, props.facilityId) : Promise.resolve([]));
    const [screenLines] = useFetch(() => props.hasScreenline ? Facilities.getScreenLineOptions(props.trigger?.organizationId, props.facilityId) : Promise.resolve([]));
    const [endpointOptions] = useFetch(() => ClientEndpoints.getOptions(props.trigger?.organizationId, props.facilityId));
    const [facilityOptions] = useFetch(() => Facilities.getOptions(props.trigger?.organizationId));
    const [deviceOptions] = useFetch(() => Devices.getOptions(props.trigger?.organizationId, props.facilityId));

    const [, helper] = useModel(props.model, props.trigger! as Partial<EventTriggerType>);
    const onChange = (newTrigger: Partial<EventTriggerType>) => {
        props.onChange(newTrigger as EventTriggerType);
    };
    useEffect(() => {
        helper.replaceModel(props.trigger!);
    }, [props.trigger])



    return (
        <>
            {props.hasDetectionZone &&
                <InputRow>
                    <Select label="Select Detection Zone"
                        emptyOptionText="Select Detection Zone"
                        emptyOptionDisabled={false}
                        optionGroups={detectionZones}
                        {...helper.bindingsFor("detectionZoneId", onChange)}
                    />
                </InputRow>
            }
            {props.hasScreenline &&
                <InputRow>
                    <Select label="Select Screen Line"
                        emptyOptionText="Select Screen Line"
                        emptyOptionDisabled={false}
                        optionGroups={screenLines}
                        {...helper.bindingsFor("screenLineId", onChange)}
                    /></InputRow>
            }
            <InputRow>

                {

                    facilityOptions &&
                    <MultiSelect label="Facility Filters"
                        options={facilityOptions as SimpleOption[]}
                        value={props.trigger?.eventTriggerFaclities?.map(f => f.facilityId ?? "") ?? []}
                        onChange={(e) => {
                            props.trigger!.eventTriggerFaclities = (e as string[]).map(e => ({ facilityId: e, eventTriggerId: props.trigger?.id } as EventTriggerFacility)) as EventTriggerFacility[];
                            props.onChange(props.trigger!);
                        }} />
                }
            </InputRow>
            <InputRow>
                {
                    deviceOptions &&
                    <MultiSelect label="Device Filters"
                        options={deviceOptions as SimpleOption[]}
                        value={props.trigger?.eventTriggerDevices?.map(f => f.deviceId ?? "") ?? []}
                        onChange={(e) => {
                            props.trigger!.eventTriggerDevices = (e as string[]).map(e => ({ deviceId: e, eventTriggerId: props.trigger?.id } as EventTriggerDevice)) as EventTriggerDevice[];
                            props.onChange(props.trigger!);
                        }} />
                }
            </InputRow>
            <InputRow>
                <Select
                    label="Endpoint to Notify"
                    emptyOptionDisabled={false}
                    emptyOptionText="Select Endpoint"
                    options={endpointOptions}
                    {...helper.bindingsFor("clientEndpointId", onChange)}
                />
            </InputRow>
            <InputRow>
                <CheckboxInput label="Process On Edge Device"
                    {...helper.bindingsFor("processOnDevice", onChange)}
                />
            </InputRow>
        </>
    );
}