/* eslint-disable @typescript-eslint/no-unused-vars */
import { Compare } from './validators';
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IPasswordReset {
	userId: string | undefined;

	resetToken: string | undefined;

	password: string;

	passwordConfirmation: string;

	$type?: string | undefined;
}
export class PasswordReset {
	constructor(data?: IPasswordReset) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	userId: string | undefined;

	resetToken: string | undefined;

	@IsNotEmpty()
	password: string = "";

	@IsNotEmpty()
	@Compare("password", {
	  "message": "The passwords do not match."
	})
	passwordConfirmation: string = "";

	static $type: string = "CoreApi.Models.View.PasswordReset, ";
	$type?: string | undefined = PasswordReset.$type;
}

