import { PowerUsageInfo } from "@app/shared";
import { HardwareStatusChart, HardwareStatusChartProps, SeriesDetails } from "./HardwareStatusChart";

type PowerUsageChartProps = Omit<HardwareStatusChartProps<PowerUsageInfo>, "series">;

const dataAccessors = (index: keyof PowerUsageInfo) => {
    return {
        date: (data?: PowerUsageInfo) => data?.statusTimestamp,
        xAccessor: (data?: PowerUsageInfo) => data?.statusTimestamp,
        yAccessor: (data?: PowerUsageInfo) => {
            return data?.[index] as number | undefined;
        }
    };
}

const charts: SeriesDetails<PowerUsageInfo>[] = [
    {
        dataKey: "Total",
        accessors: dataAccessors("total")
    },
    {
        dataKey: "CPU",
        accessors: dataAccessors("cpu"),
    },
    {
        dataKey: "GPU",
        accessors: dataAccessors("gpu"),
    }
];


export const PowerUsageChart: React.FC<PowerUsageChartProps> = (props) => {
    return <HardwareStatusChart series={charts} yUnit="mW" {...props} />
}
