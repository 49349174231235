import { JsonClientEndpoint } from "@app/shared";
import { useModel } from "../../../hooks";
import { CheckboxInput, Input, InputRow, NumericInput } from "../inputs";
import { ClientEndpointEditorProps } from "./ClientEndpointEditor";

type EditJsonClientEndpointProps = {
} & ClientEndpointEditorProps;

export const EditJsonClientEndpoint: React.FC<EditJsonClientEndpointProps> = (props) => {
    const [endpoint, helper] = useModel(JsonClientEndpoint, props.endpoint as JsonClientEndpoint);
    const onChange = (newEndpoint: Partial<JsonClientEndpoint>) => {
        props.onChange(newEndpoint as JsonClientEndpoint);
    };
    return (
        <>
            <InputRow><Input label="Endpoint Name" {...helper.bindingsFor("endpointName", onChange)} /></InputRow>
            <InputRow><Input label="Endpoint URL" {...helper.bindingsFor("endpointUrl", onChange)} /></InputRow>
            <InputRow><Input label="HMAC Secret" placeholder="HMAC shared secret key" {...helper.bindingsFor("sharedSecretKey", onChange)} /></InputRow>
            <InputRow><Input label="Custom Auth Header" placeholder="MY_AUTH_HEADER=authValue" {...helper.bindingsFor("customAuthHeader", onChange)} /></InputRow>
            <InputRow><CheckboxInput label="Enable Heartbeat" {...helper.bindingsFor("enableHeartbeat", onChange)} /></InputRow>
            {endpoint.enableHeartbeat &&
                <InputRow><NumericInput label="Seconds Between Heartbeat" {...helper.bindingsFor("secondsBetweenHearbeat", onChange)} /></InputRow>}
        </>
    );
}