import { FunctionComponent, OptgroupHTMLAttributes, OptionHTMLAttributes, SelectHTMLAttributes, useEffect, useState } from "react";
import { InputLabel } from "..";
import { InputProps } from "./inputTypes";
import { ValidatedInputGroup } from "./ValidatedInputGroup";
import { IconType } from "react-icons";

export type SelectOption = OptionHTMLAttributes<HTMLOptionElement>;
export type SelectOptionGroup = {
    options: SelectOption[]
} & OptgroupHTMLAttributes<HTMLOptGroupElement>;

export type SelectProps = {
    options?: SelectOption[],
    optionGroups?: SelectOptionGroup[],
    showGroupName?: boolean,
    isLoadingOptions?: boolean,
    emptyOptionText?: string,
    emptyOptionDisabled?: boolean
    Icon?: IconType;
} & InputProps<SelectHTMLAttributes<HTMLSelectElement>>;

export const Select: FunctionComponent<SelectProps> = (props) => {
    const { fieldName, emptyOptionText, validationError, validateModel, optionGroups, options, className = "w-full", children, isLoadingOptions, emptyOptionDisabled = true, Icon = props.Icon, ...selectProps } = props;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!options || options.length <= 0 || (options[0].value?.toString() ?? "") === "" || (emptyOptionText && (props.value?.toString() ?? "") === "")) {
            return;
        }

        //If we have options, and the value passed in is not a valid option, call onChange to the first one
        if (options && options.length > 0 && props.onChange && options.findIndex((option) => option.value?.toString() === props.value?.toString()) < 0) {
            props.onChange({ target: { value: options[0].value?.toString() ?? "" } } as any);
            return;
        }

        //If we have option groups and the value passed in is not a valid option, call onChange to the first one
        if (optionGroups && optionGroups.length > 0 && props.onChange &&
            optionGroups.findIndex((group) => group.options.findIndex((option) => option.value?.toString() === props.value?.toString()) >= 0) < 0) {
            for (var i = 0; i < optionGroups.length; i++) {
                const curGroup = optionGroups[i];
                if (curGroup.options.length > 0) {
                    props.onChange({ target: { value: curGroup.options[0].value?.toString() ?? "" } } as any);
                    return;
                }
            }
        }

    }, [props.value, options, optionGroups]); // eslint-disable-line react-hooks/exhaustive-deps

    const getOptionLabel = (value: SelectOption, groupName?: string) => {
        if (!isOpen && props.showGroupName) {
            return groupName + ' ' + value.label;
        }
        return value.label;
    }
    const getRenderOption = (groupName?: string) => (value: SelectOption) => {
        const selectedGroupText = (props.showGroupName ? groupName + ' ' : '') + value.label;
        return (<option key={(value.label ?? "") + "-" + (value.value?.toString() ?? "")} {...value} label={getOptionLabel(value, groupName)} selected-label={selectedGroupText}>
            {value.children}
        </option>
        )
    };
    const renderOptions = () => {
        if (!options && !optionGroups && !isLoadingOptions)
            return <></>;

        if (isLoadingOptions)
            return <option key="loading" disabled={true}>loading...</option>;

        if (options) {
            return options.map(getRenderOption());
        }
        if (optionGroups) {

            return optionGroups.map((value) => (
                <optgroup key={value.label?.toString() ?? ""} label={value.label}>
                    {value.options.map(getRenderOption(value.label))}
                </optgroup>
            ));
        }

    };


    const renderOptionsAndChildren = () => {
        return (
            <>
                {children}
                {
                    emptyOptionText &&
                    <option value="" disabled={emptyOptionDisabled}>{emptyOptionText}</option>
                }
                {renderOptions()}
            </>
        );
    };
    const handleFocus = (e: React.FocusEvent<HTMLSelectElement, Element>) => {
        setIsOpen(true);
    }
    const localHandleBlur = (handleBlur: () => Promise<void>) => {
        setIsOpen(false);
        handleBlur();
    }
    const localHandleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setIsOpen(false);
        if (props.onChange) {
            props.onChange(e)
        }
        e.target.blur();
    }
    const renderedDomProps = { ...selectProps };
    delete renderedDomProps.showGroupName;

    return (
        <ValidatedInputGroup fieldName={fieldName} validate={validateModel} validationError={validationError} >
            {({ label, handleBlur }) => <>
                {props.label && <InputLabel>{props.label}</InputLabel>}
                <div className="relative">
                    <select
                        className={className} 
                        {...renderedDomProps}
                        onBlur={e => localHandleBlur(handleBlur)}
                        onChange={localHandleChange}
                        onFocus={handleFocus}
                    >
                        {renderOptionsAndChildren()}
                    </select>
                    {Icon && <span className="absolute right-5 inline-block bottom-7 cursor-pointer color-nav-logo-tint">
                        <Icon className="text-brand-primary" />
                    </span>}
                </div>
            </>
            }
        </ValidatedInputGroup>
    );
}