import { Vector2 } from "@app/shared";
import { useContext, useEffect, useRef } from "react";
import { MapContext } from "../../../../helpers";

export type CircleMapShapeProps = {
    position?: google.maps.LatLng | Vector2,
    radius?: number,
    color?: string,
    opacity?: number,
    isEditable?: boolean,
    isSelected?: boolean,
    onUpdate?: (position: google.maps.LatLng | null) => void;
    onClick?: (e: google.maps.MapMouseEvent) => void;
};
export const CircleMapShape: React.FC<CircleMapShapeProps> = ({ position, color, radius, opacity, isEditable, isSelected, onClick, onUpdate }) => {
    const [parentMap, setBounds] = useContext(MapContext);

    const circle = useRef<google.maps.Marker>();
    useEffect(() => {//create/update google poly for shape        
        if (!circle.current && parentMap && position) {
            circle.current = initPoly(position, parentMap, color, radius, opacity);
            setBounds(curr => {
                const pos = circle.current?.getPosition();
                if (pos) curr.extend(pos);
                return curr;
            })
        } else if (circle.current) {
            circle.current = updatePoly(circle.current, position, parentMap, color, radius);
        }
        return () => {
            circle.current?.setVisible(false);
            circle.current?.setMap(null);
            circle.current = undefined;
        }
    }, [parentMap, circle, position, color, radius]);//eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => { //editable    
        let drag: google.maps.MapsEventListener, dragEnd: google.maps.MapsEventListener;
        if (isEditable && parentMap && circle.current) {
            circle.current.setDraggable(true);
            drag = parentMap.addListener('drag', (e: google.maps.MapMouseEvent) => onClick?.(e));
            dragEnd = parentMap.addListener('dragend', (e: google.maps.MapMouseEvent) => onUpdate?.(e.latLng));
        }
        return () => {
            circle.current?.setDraggable(false);
            drag?.remove();
            dragEnd?.remove();
        }
    }, [isEditable, parentMap, circle, onClick, onUpdate])

    useEffect(() => { // handle selection from list
        if (!isSelected || !circle.current) { return; }
        if (circle.current) {
            circle.current.setAnimation(google.maps.Animation.DROP)
        }
    }, [isSelected, parentMap]);

    useEffect(() => {//selection from map
        let click: google.maps.MapsEventListener;
        if (onClick && parentMap && circle.current) {
            circle.current.setClickable(true);
            click = circle.current.addListener('click', (e: google.maps.MapMouseEvent) => onClick?.(e));

        }
        return () => {
            circle.current?.setClickable(false);
            click?.remove();
        }
    }, [onClick, parentMap, circle])
    return null;
}

function initPoly(position: google.maps.LatLng | Vector2, parentMap: google.maps.Map, color?: string, radius?: number, opacity?: number): google.maps.Marker | undefined {
    if ('lat' in position) { } else {
        const p = position as Vector2;
        position = new google.maps.LatLng({ lat: p.y, lng: p.x });
    }

    return new google.maps.Marker({
        icon: {
            path: 0,//as google.maps.SymbolPath.Circle,
            fillOpacity: opacity ?? 1.0,
            fillColor: color ?? 'red',
            strokeOpacity: opacity ?? 1.0,
            strokeColor: color ?? 'red',
            strokeWeight: 1.0,
            scale: radius ?? 5,
        },
        position: position,
        map: parentMap,
        clickable: false,
    });

}

function updatePoly(poly: google.maps.Marker, position: google.maps.LatLng | Vector2 | undefined, parentMap: google.maps.Map | undefined, color?: string, radius?: number): google.maps.Marker | undefined {
    poly.setMap(parentMap ?? null);
    if (!parentMap || !position) {
        return undefined;
    }

    if ('lat' in position) { } else {
        const p = position as Vector2;
        position = new google.maps.LatLng({ lat: p.y, lng: p.x });
    }
    poly.setIcon({
        ...(poly.getIcon() as google.maps.Icon),
        fillColor: color ?? 'red',
        strokeColor: color ?? 'red',
        scale: radius ?? 5,
    });
    poly.setPosition(position);
}