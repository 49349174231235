export interface IFacilityUtilization {
	date: string | undefined;

	vehicles: number;

	utilization: number;

	$type?: string | undefined;
}
export class FacilityUtilization {
	constructor(data?: IFacilityUtilization) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.date) { this.date = new Date(data.date); }
	}

	date: Date | undefined = new Date();

	vehicles: number = 0;

	utilization: number = 0;

	static $type: string = "CoreApi.Models.Reports.FacilityUtilization, ";
	$type?: string | undefined = FacilityUtilization.$type;
}

