import { ButtonHTMLAttributes, DetailedHTMLProps, ForwardedRef, forwardRef, MouseEventHandler, useEffect, useState } from "react";
import { useIsMounted } from "../hooks";

export enum ButtonVariants {
    Primary, Secondary, Destructive
}
type ButtonProps = {
    variant?: ButtonVariants,
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button = forwardRef(({ variant = ButtonVariants.Primary, disabled, children, className, onClick, ...otherProps }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const [isDisabled, setIsDisabled] = useState<boolean>();
    useEffect(() => setIsDisabled(disabled), [disabled]);
    const isMounted = useIsMounted();

    const myOnClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
        setIsDisabled(true);
        try {
            if (onClick) {
                await onClick(e);
            }
        } finally {
            if (isMounted.current) {
                setIsDisabled(disabled);
            }
        }
    }

    var buttonClass = (className ?? "") + " px-4 py-1 h-10 rounded-md block";
    if (isDisabled)
        buttonClass += " disabled:opacity-50";

    switch (variant) {
        case ButtonVariants.Primary:
            buttonClass += " bg-brand-primary active:bg-brand-primary-darkened text-bright-text text-text-bright";
            buttonClass += isDisabled ? '' : 'hover:bg-brand-primary-lightened';
            break;
        case ButtonVariants.Secondary:
            buttonClass += " bg-brand-secondary active:bg-brand-secondary-darkened text-bright-text text-text-bright";
            buttonClass += isDisabled ? '' : 'hover:bg-brand-secondary-lightened';
            break;
        case ButtonVariants.Destructive:
            buttonClass += " bg-brand-destructive active:bg-brand-destructive-darkened";
            buttonClass += isDisabled ? '' : 'hover:bg-brand-destructive-lightened';
            break;
    }

    return <button ref={ref} type="button" {...otherProps} disabled={isDisabled} onClick={myOnClick} className={`align-middle text-center  ${buttonClass}`}>{children}</button>;
});
