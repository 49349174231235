import { TrafficReportProps } from "./IConfiguredTrafficReport";
import { AggregationLevel, getTargetTypeDisplayName, Reports } from "@app/shared";
import { useContext, useMemo } from "react";
import { ReportContext } from "./reportHelpers";
import { GroupedStackedApexChart } from "./GroupedStackedApexChart";
import { useFetch } from "../../hooks";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";

export function GetFacilityUsageDataLoader(aggLevel: AggregationLevel, durationFormatter: (timezone: string, periodStart: Date) => string): React.FC<TrafficReportProps> {
    return function MYFC(props: TrafficReportProps) {
        const { searchParams, reportConfig } = useContext(ReportContext);
        const [data, error, { isLoading }] = useFetch(() => Reports.facilityScreenLineTotals(aggLevel, searchParams), [aggLevel, searchParams]);

        const apexData = useMemo(() => {
            if (!data?.length) { return []; }
            const sorted = [...data].sort((a, b) => a.periodStart.getTime() - b.periodStart.getTime());
            return sorted.flatMap(item => [{ groupStart: durationFormatter(reportConfig.chartTimezone, item.periodStart), group: 'Inbound', y: item.inCount, targetType: item.targetType ? getTargetTypeDisplayName(item.targetType) : undefined }, { groupStart: durationFormatter(reportConfig.chartTimezone, item.periodStart), group: 'Outbound', y: item.outCount, targetType: item.targetType ? getTargetTypeDisplayName(item.targetType) : undefined }]);
        }, [data, reportConfig.chartTimezone])

        return <ReportLoadingErrorWrapper error={error} hasNoData={!!!apexData?.length} isLoading={isLoading} size={props.size}>
            <GroupedStackedApexChart height={props.size.height} data={apexData} title={props.title?.toString() ?? ""} subTitle={props.subTitle?.toString()} groupKey="group" stackKey='targetType' hideLegend={!reportConfig.groupByTargetType} xKey='groupStart' yKey='y' />
        </ReportLoadingErrorWrapper>

    };
}