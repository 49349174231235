import cn from "classnames";
import { FC, MouseEventHandler, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { useContainer } from "../../hooks";

export type ModalProps = {
	show: boolean;
	showBackground?: boolean;
	onClickOutside: MouseEventHandler;
	className?: string;
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({ show, showBackground = true, onClickOutside, className = "w-full max-w-3xl", ...props }) => {
	var portalId = useContainer(show);

	if (!portalId) {
		return <></>;
	}

	var el = document.getElementById(portalId);

	if (!el) {
		return <></>;
	}

	return createPortal(
		<>
			{show && (
				<div
					className={cn("w-screen h-screen fixed top-0 left-0 z-50 flex overflow-y-auto",
						{ "background bg-dialog-behind backdrop-filter backdrop-blur-sm": showBackground })}
					onClick={(e) => onClickOutside(e)}>
					<div
						className={cn("rounded bg-dialog-background m-auto self-center ", className)}
						onClick={(e) => e.stopPropagation()}>
						{props.children}
					</div>
				</div>
			)}
		</>,
		el
	);
};
