import { Spot, ISpot } from "./Shared.Geometry.Spot.gen";
import { Ruler, IRuler } from "./Shared.Geometry.Ruler.gen";
import { Guid, IGuid } from "./System.Guid.gen";
import { Device, IDevice } from "./Device.gen";
import { DetectionZone, IDetectionZone } from "./DetectionZone.gen";
import { ScreenLine, IScreenLine } from "./ScreenLine.gen";
import { EventTriggerBase, IEventTriggerBase } from "./EventTriggers.EventTriggerBase.gen";
import { Double, IDouble } from "./System.Double.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IFacility {
	originReferencePoint: ISpot | undefined;

	originReferencePointLatLng: ISpot | undefined;

	scaleReferenceLine: IRuler | undefined;

	organizationId: string | undefined;

	deviceCount: number;

	devices: IDevice[] | undefined;

	detectionZones: IDetectionZone[] | undefined;

	screenLines: IScreenLine[] | undefined;

	endPoints: IEventTriggerBase[] | undefined;

	id: string | undefined;

	isDeleted: boolean;

	name: string;

	address1: string | undefined;

	address2: string | undefined;

	city: string | undefined;

	state: string | undefined;

	zip: string | undefined;

	country: string | undefined;

	backgroundImageSource: string | undefined;

	backgroundImageWidth: number | undefined;

	backgroundImageHeight: number | undefined;

	utilization: number;

	facilityGroupId: string | undefined;

	locationCode: string | undefined;

	localTimezoneId: string | undefined;

	$type?: string | undefined;
}
export class Facility {
	constructor(data?: IFacility) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.originReferencePoint) { this.originReferencePoint = new Spot(data.originReferencePoint); }
		if (data.originReferencePointLatLng) { this.originReferencePointLatLng = new Spot(data.originReferencePointLatLng); }
		if (data.scaleReferenceLine) { this.scaleReferenceLine = new Ruler(data.scaleReferenceLine); }
		this.devices = data.devices ? data.devices.map((d: any) => new Device(d)) : [];
		this.detectionZones = data.detectionZones ? data.detectionZones.map((d: any) => new DetectionZone(d)) : [];
		this.screenLines = data.screenLines ? data.screenLines.map((s: any) => new ScreenLine(s)) : [];
		this.endPoints = data.endPoints ? data.endPoints.map((e: any) => new EventTriggerBase(e)) : [];
	}

	originReferencePoint: Spot | undefined;

	originReferencePointLatLng: Spot | undefined;

	scaleReferenceLine: Ruler | undefined;

	organizationId: string | undefined;

	deviceCount: number = 0;

	devices: Device[] | undefined = [];

	detectionZones: DetectionZone[] | undefined = [];

	screenLines: ScreenLine[] | undefined = [];

	endPoints: EventTriggerBase[] | undefined = [];

	id: string | undefined;

	isDeleted: boolean = false;

	@IsNotEmpty()
	name: string = "";

	address1: string | undefined;

	address2: string | undefined;

	city: string | undefined;

	state: string | undefined;

	zip: string | undefined;

	country: string | undefined;

	backgroundImageSource: string | undefined;

	backgroundImageWidth: number | undefined;

	backgroundImageHeight: number | undefined;

	utilization: number = 0;

	facilityGroupId: string | undefined;

	locationCode: string | undefined;

	localTimezoneId: string | undefined;

	static $type: string = "CoreApi.Models.Facility, ";
	$type?: string | undefined = Facility.$type;
}

