import { DetectionZoneChangeEventTrigger, DetectionZoneDwellEventTrigger, DetectionZoneEntryEventTrigger, DetectionZoneExitEventTrigger, DetectionZonePresenceEventTrigger, NearMissEventTrigger, ScreenLineChangeEventTrigger, ScreenLineInboundEventTrigger, ScreenLineOutboundEventTrigger, SpeedingEventTrigger, TargetDetectedEventTrigger, WrongWayEventTrigger } from "@app/shared";
import { InputRow, Select } from "../inputs";
import { EditEventTrigger } from "./EditEventTrigger";

export type EventTriggerType = DetectionZoneChangeEventTrigger | DetectionZoneDwellEventTrigger | DetectionZoneEntryEventTrigger | DetectionZoneExitEventTrigger |
    DetectionZonePresenceEventTrigger | SpeedingEventTrigger | NearMissEventTrigger | ScreenLineChangeEventTrigger | ScreenLineInboundEventTrigger |
    ScreenLineOutboundEventTrigger | TargetDetectedEventTrigger | WrongWayEventTrigger;

export type EventTriggerEditorProps = {
    trigger: EventTriggerType | undefined;
    facilityId?: string;
    onChange: (trigger: EventTriggerType | undefined) => any;
};

const getEditor = (props: EventTriggerEditorProps) => {
    switch (props.trigger!.$type) {
        case DetectionZoneChangeEventTrigger.$type:
            return <>
                <EditEventTrigger model={DetectionZoneChangeEventTrigger} hasDetectionZone={true} {...props} />
            </>;
        case DetectionZoneDwellEventTrigger.$type:
            return <>
                <EditEventTrigger model={DetectionZoneDwellEventTrigger} hasDetectionZone={true} {...props} />
            </>;
        case DetectionZoneEntryEventTrigger.$type:
            return <>
                <EditEventTrigger model={DetectionZoneEntryEventTrigger} hasDetectionZone={true} {...props} />

            </>;
        case DetectionZoneExitEventTrigger.$type:
            return <>
                <EditEventTrigger model={DetectionZoneExitEventTrigger} hasDetectionZone={true} {...props} />
            </>;
        case DetectionZonePresenceEventTrigger.$type:
            return <>
                <EditEventTrigger model={DetectionZonePresenceEventTrigger} hasDetectionZone={true} {...props} />
            </>;
        case NearMissEventTrigger.$type:
            return <>
                <EditEventTrigger model={NearMissEventTrigger} {...props} />
            </>;
        case ScreenLineChangeEventTrigger.$type:
            return <>
                <EditEventTrigger model={ScreenLineChangeEventTrigger} hasScreenline={true} {...props} />
            </>;
        case ScreenLineInboundEventTrigger.$type:
            return <>
                <EditEventTrigger model={ScreenLineInboundEventTrigger} hasScreenline={true} {...props} />
            </>;
        case ScreenLineOutboundEventTrigger.$type:
            return <>
                <EditEventTrigger model={ScreenLineOutboundEventTrigger} hasScreenline={true} {...props} />
            </>;
        case SpeedingEventTrigger.$type:
            return <>
                <EditEventTrigger model={SpeedingEventTrigger} hasScreenline={true} hasDetectionZone={true} {...props} />
            </>;
        case TargetDetectedEventTrigger.$type:
            return <>
                <EditEventTrigger model={TargetDetectedEventTrigger} hasDetectionZone={true} {...props} />
            </>;
        case WrongWayEventTrigger.$type:
            return <>
                <EditEventTrigger model={WrongWayEventTrigger} hasScreenline={true} hasDetectionZone={true}  {...props} />
            </>;
    }
}

export const EventTriggerEditor = (props: EventTriggerEditorProps) => {

    if (!props || !props.trigger)
        return <>No trigger to edit.  Please try again</>;

    return (
        <>
            <InputRow>
                <Select label="Trigger Type" emptyOptionText="Select Event Trigger Type" emptyOptionDisabled={true} options={[
                    { label: "Detection Zone Change", value: DetectionZoneChangeEventTrigger.$type },
                    { label: "Detection Zone Dwell", value: DetectionZoneDwellEventTrigger.$type },
                    { label: "Detection Zone Entry", value: DetectionZoneEntryEventTrigger.$type },
                    { label: "Detection Zone Exit", value: DetectionZoneExitEventTrigger.$type },
                    { label: "Detection Zone Presence", value: DetectionZonePresenceEventTrigger.$type },
                    { label: "Near Miss", value: NearMissEventTrigger.$type },
                    { label: "Screen Line Change", value: ScreenLineChangeEventTrigger.$type },
                    { label: "Screen Line Inbound", value: ScreenLineInboundEventTrigger.$type },
                    { label: "Screen Line Outbound", value: ScreenLineOutboundEventTrigger.$type },
                    { label: "Speeding", value: SpeedingEventTrigger.$type },
                    { label: "Target Detected", value: TargetDetectedEventTrigger.$type },
                    { label: "Wrong Way", value: WrongWayEventTrigger.$type },
                ]}
                    disabled={!!props?.trigger?.id}
                    value={props?.trigger?.$type ?? ""}
                    onChange={(v) => props?.onChange((props && props?.trigger) ? { ...props.trigger, $type: v.target?.value } : undefined)}
                />
            </InputRow>

            {getEditor(props)}

        </>
    );
}  
