import { ForwardedPortBase } from "@app/shared";
import { IconLink, Icons, SortableTable, Spinner, StatusDot } from ".";
import { useDialog } from "../../hooks";
import { format } from "date-fns";
import copy from "copy-to-clipboard";
import { ReactElement, ReactNode } from "react";
import { HoverTooltip } from "./HoverTooltip";

type DevicePortsTableProps = {
    ports: ForwardedPortBase[] | undefined;
    isLoading?: boolean;
    onPortsChanged?: (command: ForwardedPortBase) => void;
};

export const DevicePortsTable: React.FC<DevicePortsTableProps> = ({ ports, isLoading, onPortsChanged }) => {
    const detailsDialog = useDialog<ForwardedPortBase>();

    const handleRowClick = (command: ForwardedPortBase) => {
        detailsDialog.show("Device Port Details", "OK", command);
    };
    const formatTime = (date?: Date) => {
        if (!date) { return undefined; }
        return format(date, 'MMM dd yyyy HH:MM a XXX');
    }

    const getUri = (fp?: ForwardedPortBase) => textWithCopyBtn(((fp?.clientPort === 22) ? 'ssh://' : '') + `${window.location.hostname}:${fp?.serverPort}`);
    const textWithCopyBtn = (txt?: string) => {
        if (txt?.length) {
            return (<><span>{txt}</span><IconLink className="ml-3" Icon={Icons.Clipboard} onClick={() => copy(txt)} /></>)
        }
    }
    const portStatus = (fp?: ForwardedPortBase) => {
        const isOpen = !!!fp?.terminated;
        if (!!!fp?.deviceConnected) {
            return hoverWrap((<div className="h-2 w-[18px] mx-auto"><Spinner /></div>), 'Waiting for device');
        }
        return hoverWrap((<StatusDot status={isOpen?'ok':'error'} />), isOpen ? 'Port Open' : 'Port Closed');
    };
    const hoverWrap = (ele: ReactNode | ReactElement, txt: string) => {
        return (<HoverTooltip hoverable={ele}>{txt}</HoverTooltip>);
    };

    return (
        <>
            <SortableTable
                isLoading={isLoading}
                onRowClick={handleRowClick}
                initialSortDir="desc"
                initialSortKey="requested"
                emptyValue={<div className="py-10">No forwarded ports were found</div>}
                columns={[
                    {
                        header: "",
                        renderFunc: portStatus,
                        width: 25
                    },
                    {
                        header: "Requested",
                        dataKey: "requested",
                        renderFunc: dc => formatTime(dc?.requested),
                        width: 200
                    },
                    {
                        header: "Device Port",
                        width: 200,
                        dataKey: "clientPort"
                    },
                    {
                        header: "Server Port",
                        width: 200,
                        dataKey: "serverPort",
                        renderFunc: fp => textWithCopyBtn(fp?.serverPort?.toString()),
                    },
                    {
                        header: "Uri",
                        width: 200,
                        dataKey: "serverPort",
                        renderFunc: fp => getUri(fp),
                    },
                    {
                        header: "Connection Started",
                        width: 200,
                        dataKey: "deviceConnected",
                        renderFunc: dc => formatTime(dc?.deviceConnected),
                    },
                    {
                        header: "Connection Terminated",
                        width: 200,
                        dataKey: "terminated",
                        renderFunc: dc => formatTime(dc?.terminated),
                    }
                ]}
                data={ports}
            />
        </>
    );
}