import { Single, ISingle } from "./System.Single.gen";
import { Guid, IGuid } from "./System.Guid.gen";
import { Vector2, IVector2 } from "./System.Numerics.Vector2.gen";

export interface IRuler {
	length: number | undefined;

	scaleRatio: number;

	id: string | undefined;

	name: string | undefined;

	points: IVector2[] | undefined;

	$type?: string | undefined;
}
export class Ruler {
	constructor(data?: IRuler) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.points = data.points ? data.points.map((p: any) => new Vector2(p)) : [];
	}

	length: number | undefined;

	scaleRatio: number = 0;

	id: string | undefined;

	name: string | undefined;

	points: Vector2[] | undefined = [];

	static $type: string = "Shared.Geometry.Ruler, ";
	$type?: string | undefined = Ruler.$type;
}

