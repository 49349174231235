import { IVector2 } from "@app/shared";
import { getFovPoint } from ".";

export const getPathForFieldOfView = (x: number, y: number, height: number, elevationAngle: number, azimuthFov: number, elevationFov: number, scaleRatio: number) => {
    var [nearX, nearY] = getFovPoint(height, azimuthFov / 2, elevationAngle - elevationFov / 2, scaleRatio);
    var [farX, farY] = getFovPoint(height, azimuthFov / 2, elevationAngle + elevationFov / 2, scaleRatio);

    var maxDistance = 100 / scaleRatio;

    var nearY_clipped = Math.min(maxDistance, nearY);
    var farY_clipped = Math.min(maxDistance, farY);

    var pathCommands = [
        `M ${x + nearX} ${y + nearY_clipped}`,
        `L ${x + farX} ${y + farY_clipped}`,
        `L ${x - farX} ${y + farY_clipped}`,
        `L ${x - nearX} ${y + nearY_clipped}`,
        "Z",
    ];

    return pathCommands.join(",");
}

export const getRotation = (x: number, y: number, heading: number) => {
    var angle = heading - 180;
    return `rotate(${angle} ${x} ${y})`;
}



export function calculateDistance(point1: IVector2, point2: IVector2): number {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
}

export function divideQuadrilateral(quadrilateral: IVector2[], n: number): IVector2[][] {
    if (quadrilateral.length !== 4) throw new Error('only quadrilaterals supported');
    // Calculate side lengths
    const sideLengths: number[] = [];
    for (let i = 0; i < 4; i++) {
        const nextIndex = (i + 1) % 4;
        const length = calculateDistance(quadrilateral[i], quadrilateral[nextIndex]);
        sideLengths.push(length);
    }

    // Find the indices of the longest side
    const longestSideIndex = sideLengths.indexOf(Math.max(...sideLengths));
    const oppositeSideIndex = (longestSideIndex + 2) % 4;

    // Divide the longest side into n equal segments
    const longestSide = [quadrilateral[longestSideIndex], quadrilateral[(longestSideIndex + 1) % 4]];

    const longestXIncrement = Math.abs((longestSide[0].x - longestSide[1].x) / n);
    const longestYIncrement = Math.abs((longestSide[0].y - longestSide[1].y) / n);
    const longXMultiplier = (longestSide[0].x - longestSide[1].x > 0 ? -1 : 1);
    const longYMultiplier = (longestSide[0].y - longestSide[1].y > 0 ? -1 : 1);

    // Divide the opposite side proportionally
    const oppositeSide = [quadrilateral[oppositeSideIndex], quadrilateral[(oppositeSideIndex + 1) % 4]];
    const oppXMultiplier = (oppositeSide[0].x - oppositeSide[1].x) > 0 ? 1 : -1;//works in q1
    const oppXIncrement = Math.abs((oppositeSide[0].x - oppositeSide[1].x) / n);
    const oppYIncrement = Math.abs((oppositeSide[0].y - oppositeSide[1].y) / n);
    const oppYMultiplier = (oppositeSide[0].y - oppositeSide[1].y) > 0 ? 1 : -1;

    const result: IVector2[][] = [];

    for (let i = 0; i < n; i++) {
        const newLS0 = { x: longestSide[0].x + longXMultiplier * (i * longestXIncrement), y: longestSide[0].y + longYMultiplier * (i * longestYIncrement) };
        const newLS1 = { x: longestSide[0].x + longXMultiplier * (i + 1) * longestXIncrement, y: longestSide[0].y + longYMultiplier * ((i + 1) * longestYIncrement) };
        const newOS0 = { x: oppositeSide[1].x + oppXMultiplier * ((i + 1) * oppXIncrement), y: oppositeSide[1].y + (oppYMultiplier * (i + 1) * oppYIncrement) };
        const newOS1 = { x: oppositeSide[1].x + oppXMultiplier * (i) * oppXIncrement, y: oppositeSide[1].y + (oppYMultiplier * (i) * oppYIncrement) };

        const r = [
            { ...newLS0 },//1,-10
            { ...newLS1 },//0,0
            { ...newOS0 },//1,1
            { ...newOS1 }//2,-8
        ];

        const rOrdered = [];
        for (let i = 0; i < 4; i++) {
            rOrdered[(longestSideIndex + i) % 4] = r[i];
        }
        rOrdered.push({ ...rOrdered[0] });
        result.push(rOrdered);
    }

    return result;
}