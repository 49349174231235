import { Facility, getHeadingForPoint, reOrientXyPoint, Target, TargetHistory, TargetStatus, Vector2 } from "@app/shared";

type SvgTargetHistoryProps = {
  color?: string;
  facility: Facility;
  target: Target;
  showTargets?: boolean;
  hideHistory?: boolean;
};

const DrawTarget = (props: { color: string, history: TargetHistory, origin: Vector2, scaleRatio: number }) => {
  const size = 8;
  const rotation = getHeadingForPoint(props.history.velocity?.x ?? 0, props.history.velocity?.y ?? 0) ?? 0;
  const position = new Vector2(props.history.position).reOrientPoint(props.origin, props.scaleRatio, "forWeb");
  return (<polygon
    transform={`translate(${position.x} ${position.y}) rotate(${rotation})`}
    points={`0,${-size} ${size / 2.0},${size} ${-size / 2},${size}`}
    color={props.color}
    fill={props.color}
    opacity={.3}
    stroke={"white"}
  />);
}
const DrawPath = (props: { color: string, history: TargetHistory[], origin: Vector2, scaleRatio: number }) => {
  const points = props.history.map(h => {
    const retVal = reOrientXyPoint(h.position, props.origin, props.scaleRatio, "forWeb");
    return `${retVal?.x.toFixed(2)},${retVal?.y.toFixed(2)}`;
  });
  return (<polyline
    color={props.color}
    points={points.join(" ")}
    stroke={props.color}
    strokeWidth={2}
    fillOpacity={0}
  />);
};

export const SvgTargetHistory: React.FC<SvgTargetHistoryProps> = (props) => {
  const { color = "yellow", facility, target, hideHistory, showTargets = true } = props;
  const { originReferencePoint, scaleReferenceLine, backgroundImageWidth, backgroundImageHeight } = facility;

  if (!originReferencePoint || !scaleReferenceLine || !backgroundImageWidth || !backgroundImageHeight) {
    console.warn("Location is not configured - cannot render SvgTargetHistory (facility)");
    return <></>;
  }

  const { origin } = originReferencePoint;
  const { scaleRatio } = scaleReferenceLine;

  //TODO: Maybe we should calculate this based on how far apart our target history is?
  const targetsToSkip = 10;

  if (!origin) {
    console.warn("Location is not configured - cannot render SvgTargetHistory (origin)")
    return <></>;
  }

  return (
    <>
      {(target && target.history && !hideHistory) &&
        <DrawPath color={color} history={target.history} origin={origin} scaleRatio={scaleRatio} />
      }

      {showTargets && target.history?.map((val, ind, arr) => (
        (ind % targetsToSkip === 0) &&
        <DrawTarget key={val.id} color={color} history={val} origin={origin} scaleRatio={scaleRatio} />
      ))}

      {(target.status === TargetStatus.Moving || target.status === TargetStatus.New) && (target.history?.length ?? 0) > 0 &&
        <DrawTarget color={color} history={target.history![0]} origin={origin} scaleRatio={scaleRatio} />
      }
    </>
  );
}