import { TargetType } from "./Shared.TargetType.gen";

export interface ITotalVolumeByPeriod {
	periodStart: string | undefined;

	count: number;

	targetType: TargetType;

	avgVelocity: number;

	inCount: number;

	outCount: number;

	$type?: string | undefined;
}
export class TotalVolumeByPeriod {
	constructor(data?: ITotalVolumeByPeriod) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.periodStart) { this.periodStart = new Date(data.periodStart); }
	}

	periodStart: Date | undefined = new Date();

	count: number = 0;

	targetType: TargetType = 0;

	avgVelocity: number = 0;

	inCount: number = 0;

	outCount: number = 0;

	static $type: string = "CoreApi.Models.Reports.TotalVolumeByPeriod, ";
	$type?: string | undefined = TotalVolumeByPeriod.$type;
}

