export enum OperationMode {
	Traffic_Counting = 0,
	Wrong_Way_Detection = 1,
	Parking_Monitor = 2,
	Intersection_Management = 3,
	Train_Station = 4,
}

export const OperationModeDisplayNames: { [key in keyof typeof OperationMode]?: string} = {
	"Traffic_Counting": "Traffic Counting",
	0 : "Traffic Counting",
	"Wrong_Way_Detection": "Wrong Way Detection",
	1 : "Wrong Way Detection",
	"Parking_Monitor": "Parking Monitor",
	2 : "Parking Monitor",
	"Intersection_Management": "Intersection Management",
	3 : "Intersection Management",
	"Train_Station": "Train Station",
	4 : "Train Station",
}

export const getOperationModeDisplayName = (key: OperationMode | (keyof typeof OperationMode & string)) => {
	return OperationModeDisplayNames[key];
}

