import { Facilities, Facility, FacilityGroups, OptionValue } from "@app/shared";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchInput } from "../..";
import { useFetch } from "../../../hooks";
import { Pages } from "../../pages";
import { SelectorSection } from "./SelectorSection";

type FacilityGroupSelectorProps = {
    startingGroupId?: string;
    startingFacilityId?: string;
    onSelectFacility?: (id: string | undefined) => void;
};
export const FacilityGroupSelector: React.FC<FacilityGroupSelectorProps> = (props) => {

    //const organizations = []; //TODO: Add organizations to this drop down
    const [groups] = useFetch(() => FacilityGroups.getAllFacilityGroupOptions());
    const [facilities] = useFetch(() => Facilities.getAll());
    const [hoveredCol, setHoveredCol] = useState(0);
    const navigate = useNavigate();

    const [selectedGroupId, setSelectedGroupId] = useState(props.startingGroupId);
    const [selectedFacilityId, setSelectedFacilityId] = useState(props.startingFacilityId);
    const [searchText, setSearchText] = useState<string>();
    const [searchFilteredFacilities, setSearchFilteredFacilities] = useState<Facility[]>();

    const handleSelectFacility = async (f: Facility) => {
        if (!f || !f.id) {
            return;
        }

        if (props.onSelectFacility)
            await props.onSelectFacility(f.id);
        navigate(Pages.FacilityDetails.getUrl({ id: f.id }));
    }
    const handleSelectGroup = (group: OptionValue) => {
        setSelectedGroupId(group.value);
        setSelectedFacilityId(undefined);
    }
    const handleSearch = (search: string) => {
        setSearchText(search);
    }

    useEffect(() => {
        setSelectedGroupId(props.startingGroupId);
        setSelectedFacilityId(props.startingFacilityId);
    }, [props.startingGroupId, props.startingFacilityId])

    useEffect(() => {
        if (!searchText)
            setSearchFilteredFacilities(facilities);

        const search = searchText?.toLowerCase() ?? "";

        const filteredGroups = groups?.filter(g => (g.label?.toLowerCase().indexOf(search) ?? -1) >= 0);
        setSearchFilteredFacilities(facilities?.filter(f =>
            (f.name?.toLowerCase().indexOf(search) ?? -1) >= 0 ||
            (f.address1?.toLowerCase().indexOf(search) ?? -1) >= 0 ||
            (f.address2?.toLowerCase().indexOf(search) ?? -1) >= 0 ||
            (filteredGroups?.findIndex(ov => ov.value === f.facilityGroupId) ?? -1) >= 0
        ));
    }, [searchText, facilities]); //eslint-disable-line react-hooks/exhaustive-deps

    const GroupView = () => {
        return (
            <div className="flex flex-grow min-h-0 ">
                <SelectorSection
                    title="Groups"
                    className={(hoveredCol === 0 ? "w-4/6" : "w-2/6")}
                    options={groups ?? []}
                    selectedId={selectedGroupId}
                    onMouseEnter={() => { setHoveredCol(0) }}
                    onSelect={handleSelectGroup}
                />
                <SelectorSection
                    title="Locations"
                    className={(hoveredCol === 1 ? "w-4/6" : "w-2/6")}
                    options={facilities?.filter(f => f.facilityGroupId === selectedGroupId)}
                    selectedId={selectedFacilityId}
                    onMouseEnter={() => { setHoveredCol(1) }}
                    onSelect={handleSelectFacility}
                />
            </div>
        );
    }
    const SearchView = () => {
        return (
            <div className="flex flex-grow min-h-0 ">
                <SelectorSection
                    options={searchFilteredFacilities}
                    onSelect={handleSelectFacility} />
            </div>
        );
    }

    return (
        <div className="flex flex-col bg-background-page border-separators-page " style={{ width: "800px", height: "500px" }}>
            <div className="px-5 py-3 flex place-content-end">
                <div><SearchInput delay={0} onSearch={handleSearch} /></div>
            </div>
            {!searchText &&
                <GroupView />
            }
            {searchText &&
                <SearchView />
            }
        </div>
    );
}