import { startOfDay, subDays } from "date-fns";
import { DateOptions, TrafficReportProps } from "../../reports/IConfiguredTrafficReport";
import { TotalVolumeChart } from "../../reports/TotalVolumeDataLoader";
import { AllFHWAClasses, AllTargetTypes, ReportContext } from "../../reports/reportHelpers";
import { zonedTimeToUtc } from "date-fns-tz";
import { AggregationLevel, Device, Facility, TargetClassificationStrategy, TrafficReportConfiguration } from "@app/shared";
import { SelectedReportConfiguration } from "../../reports/ReportComponents";
import { useScreenSize } from '@visx/responsive';

export type TargetEvent = {
    date: Date | undefined;
    counts: { [key: string]: number };
    speeds: { [key: string]: number };
}
export type FacilityEventsChartProps = {
    device: Device,
    id?: string;
    title?: string;
    facility: Facility,
    data: Array<TargetEvent> | undefined;
    isLoading?: boolean;
} & ChartSizeProps;

export type ChartSizeProps = {
    width?: number;
    height?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
}


export function FacilityEventsChart<T extends object>({ facility, device, id, title, data = [], isLoading, width, height = 300, margin = { top: 10, right: 60, bottom: 50, left: 60 } }: FacilityEventsChartProps) {
    const { width: screenWidth } = useScreenSize({ debounceTime: 150 });

    const start = startOfDay(subDays(new Date(), 13));
    const facilityTimezone = facility.localTimezoneId ?? 'UTC';

    const reportConfig = { selectedReportId: 'tvd', tTGrouping:TargetClassificationStrategy.Omnisight, selectedDateId: DateOptions.Custom, context: { facility: facility, device: device, setReportConfig: () => null }, inclusiveStart: zonedTimeToUtc(start, facilityTimezone), exclusiveEnd: new Date(), facilityIds: undefined, chartTimezone: facilityTimezone, groupByTargetType: true, chartType: 'bar', deviceIds: [device.id], targetTypes: AllTargetTypes, timeZone: facilityTimezone, fHWAClasses:AllFHWAClasses } as TrafficReportConfiguration & SelectedReportConfiguration;
    const props = { margin: margin, size: { height: height, width: (screenWidth - margin.left - margin.right) } } as TrafficReportProps;
    return (
        <ReportContext.Provider value={{ reportConfig: reportConfig, searchParams: reportConfig, updateReportConfig: x => { } }} >
            {/* <TotalVolumeChart
                {...props}
                aggregationLevel={AggregationLevel.Day}
            /> */}
        </ReportContext.Provider>);
}

