import { AuthContextProvider } from "@app/shared";
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import { ContainerContextProvider } from "./components";
import { Pages } from './components/pages';
import { PageNotFound } from "./components/pages/PageNotFound";
import './styles/index.css';
import './styles/tailwind.css';
import { useJsApiLoader } from "@react-google-maps/api";
import { useMemo } from "react";

const PageRoutes = () => useRoutes([
  ...Object.values(Pages).map((page) => ({
    path: page.routeUrl,
    element: <page.page />
  })
  ),
  //Add any other routes here
  { path: "/", element: <Navigate to={Pages.ViewFacilityGroups.getUrl()} /> },
  { path: "*", element: <PageNotFound /> }
]);

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY as string;
const googleScriptOptions = {
  id: 'google-map-script',
  libraries: ["drawing", "geometry", "core", "marker"],
  googleMapsApiKey: googleMapsApiKey
};

export default function App() {
  const { isLoaded, loadError } = useJsApiLoader(googleScriptOptions as any);
  const App = useMemo(() => {
    if (!isLoaded) return null;
    return (
      <AuthContextProvider>
        <ContainerContextProvider>
          <BrowserRouter>
            <PageRoutes />
          </BrowserRouter>
        </ContainerContextProvider>
      </AuthContextProvider>
    );
  }, [isLoaded]);
  return (App);
}
