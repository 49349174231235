// Define a debounce function
export function debounce<F extends (...args: any[]) => any>(
    func: F,
    delay: number
): (...args: Parameters<F>) => void {
    let timeoutId: NodeJS.Timeout | null;

    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
        const context = this;

        // Clear the previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set a new timeout
        timeoutId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}