export enum RoleValue {
	Administrator = 0,
	Technician = 1,
	Manager = 2,
	User = 3,
}

export const RoleValueDisplayNames: { [key in keyof typeof RoleValue]?: string} = {
	"Administrator": "Admin",
	0 : "Admin",
	"Technician": "Technician",
	1 : "Technician",
	"Manager": "Manager",
	2 : "Manager",
	"User": "User",
	3 : "User",
}

export const getRoleValueDisplayName = (key: RoleValue | (keyof typeof RoleValue & string)) => {
	return RoleValueDisplayNames[key];
}

