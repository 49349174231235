import { LuxcomClientEndpoint } from "@app/shared";
import { useModel } from "../../../hooks";
import { Input, InputRow } from "../inputs";
import { ClientEndpointEditorProps } from "./ClientEndpointEditor";

type EditLuxcomClientEndpointProps = {
} & ClientEndpointEditorProps;

export const EditLuxcomClientEndpoint: React.FC<EditLuxcomClientEndpointProps> = (props) => {
    const [endpoint, helper] = useModel(LuxcomClientEndpoint, props.endpoint as LuxcomClientEndpoint);
    const onChange = (newEndpoint: Partial<LuxcomClientEndpoint>) => {
        props.onChange(newEndpoint as LuxcomClientEndpoint);
    };
    return (
        <>
            <InputRow><Input label="Endpoint Name" {...helper.bindingsFor("endpointName", onChange)} /></InputRow>
            <InputRow><Input label="Converter IP" {...helper.bindingsFor("ipAddress", onChange)} /></InputRow>
            <InputRow><Input label="Command Port" {...helper.bindingsFor("commandPort", onChange)} /></InputRow>
            <InputRow><Input label="Sys Log Port" {...helper.bindingsFor("syslogPort", onChange)} /></InputRow>
            <InputRow><Input label="Forward UDP Port" {...helper.bindingsFor("forwardUdpPort", onChange)} /></InputRow>
        </>
    );
}