export enum SensorConfigType {
	Unknown = 0,
	Radar = 1,
	Tracker = 2,
}

export const SensorConfigTypeDisplayNames: { [key in keyof typeof SensorConfigType]?: string} = {
	"Unknown": "Unknown",
	0 : "Unknown",
	"Radar": "Radar",
	1 : "Radar",
	"Tracker": "Tracker",
	2 : "Tracker",
}

export const getSensorConfigTypeDisplayName = (key: SensorConfigType | (keyof typeof SensorConfigType & string)) => {
	return SensorConfigTypeDisplayNames[key];
}

