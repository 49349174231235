import { Button, useAuth, UserLogin } from "@app/shared";
import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Pages } from "..";
import { useModel } from "../../../hooks";
import { NoAuthAccountLayout } from "../../layouts";
import { EmailInput, InputRow, PasswordInput } from "../../shared";

export const Login: FC = (props) => {
    const [credentials, { bindingsFor, validateWithAction, modelContainerClassNames, ErrorSummary }] = useModel(UserLogin);
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const submitButton = useRef<HTMLButtonElement>(null);
    const [error, setError] = useState<string>();
    const { logIn, logOut, isAuthenticated, isLoading } = useAuth();

    useEffect(() => {
        if (isAuthenticated && !isLoading) { logOut(); }
    }, [isLoading]);//

    async function handleLogInOnClick() {
        const { email, password } = credentials;
        await validateWithAction(async () => {
            await logIn(credentials as UserLogin);
            if (query.has("fwd")) {
                navigate(query.get("fwd")!);
            } else {
                navigate("/");
            }
        });
    }

    return (
        <NoAuthAccountLayout>
            <div className="mx-auto w-full max-w-sm my-10">
                <div className={`${modelContainerClassNames} flex justify-center justify-items-center flex-col`}>
                    <div className="mx-auto mb-3">
                        <h1>Sign In</h1>
                    </div>
                    <div className="mx-auto mb-3">
                        <ErrorSummary />
                    </div>
                    <InputRow>
                        <EmailInput {...bindingsFor('email')} />
                    </InputRow>
                    <InputRow>
                        <PasswordInput {...bindingsFor('password')} onEnter={() => submitButton.current?.click()} />
                    </InputRow>
                    <div className="mx-auto mt-2">
                        <Button ref={submitButton} type="submit" onClick={handleLogInOnClick}>Log In</Button>
                    </div>
                </div>
                <div className="mx-auto text-center mt-10">

                    <NavLink to={Pages.ForgotPassword.getUrl()}>Forgot your password?</NavLink><br />
                    <NavLink to={Pages.CreateAccount.getUrl()}>Create a new account</NavLink><br />
                    <NavLink to={Pages.Terms.getUrl()}>Terms and Conditions</NavLink>
                </div>
            </div>
        </NoAuthAccountLayout>
    );
}