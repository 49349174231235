import { Button } from "@app/shared";

type ErrorBoundaryFallbackProps = {
    error: Error;
    resetErrorBoundary: (...args: Array<unknown>) => void;
};

export const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({ error, resetErrorBoundary }) => {
    return (
        <div className="text-center">
            <p>
                Ooops... Something went wrong.
            </p>
            <pre className="my-5">{error.message}</pre>

            <Button onClick={resetErrorBoundary} className="mx-auto my-5">Try Again</Button>
        </div>
    );
}