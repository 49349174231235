import { Button, DetectionZone, Device, Devices, Facilities, IDetectionZone } from "@app/shared";
import { ReactEventHandler, useEffect, useState, useCallback } from "react";
import { useFacilityCenter, toastSuccess } from "../../helpers";
import { useFetch } from "../../hooks";
import { SkeletonGrid } from "./loading";
import { CircleMapShape, DetectionZoneMapShape, MapShape, OmniGoogleMap, ReferencePointMapShape } from "./maps";

type DeviceBoundaryProps = {
    device: Device;
    onChange?: () => any;
};

export const DeviceBoundaries: React.FC<DeviceBoundaryProps> = ({ device, onChange }) => {
    const [facility, , facilityHelper] = useFetch(() => device ? Facilities.getById(device.facilityId) : Promise.resolve(undefined), [device]);
    const [isEditingBoundaries, setIsEditingBoundaries] = useState(false);
    const [boundaryPoly, setBoundaryPoly] = useState<DetectionZone>();
    const [hasChanges, setHasChanges] = useState<boolean>();

    const [centerCoords, centerIsLoading] = useFacilityCenter(facility);

    const initBoundaryPoly = useCallback((device: Device) => {

        var zone = new DetectionZone();
        zone.points = [...device.boundaryPoints ?? []];
        zone.id = 'boundary';
        return zone;
    }, []);

    useEffect(() => {
        if (facility && device) {
            setBoundaryPoly(initBoundaryPoly(device));
        }
    }, [facility, device, initBoundaryPoly]);

    const handleSaveOnClick: ReactEventHandler<HTMLButtonElement> = async (e) => {
        if (!device) return;

        var mappedPoints = [...boundaryPoly?.points ?? []];
        await Devices.updateBoundaryPoints(device?.id, { ...device, boundaryPoints: mappedPoints } as Device);

        if (onChange) {
            await onChange();
        }
        handleCancel();
        toastSuccess("Saved", "Boundary has been saved!");
    };

    const handleCancel = () => {
        if (device) {
            setHasChanges(false);
            setIsEditingBoundaries(false);
            setBoundaryPoly(initBoundaryPoly(device));
        }
    }
    const handleReset = () => {
        if (device) {
            if (device.boundaryPoints?.length) {
                setHasChanges(true);
            }
            setBoundaryPoly(cv => new DetectionZone({ points: [], id: 'boundary' } as unknown as IDetectionZone));
        }
    }

    const handleUpdate = (dz: DetectionZone) => {
        setBoundaryPoly(cv => new DetectionZone({ points: [...dz.points!], id: 'boundary' } as unknown as IDetectionZone));
        if (dz.isComplete()) {
            setHasChanges(true);
            if ((device.boundaryPoints?.length ?? 0) < 1) {
                setIsEditingBoundaries(false);
            }

        }
    }

    const handleEdit = () => {
        setIsEditingBoundaries(true)
    }

    if (facilityHelper.isLoading || centerIsLoading) {
        return (
            <>
                <SkeletonGrid rows={1} cols={2} />
                <SkeletonGrid rows={1} cols={2} colClassNames="h-60 w-full" />
            </>
        );
    }

    const firstPoint = boundaryPoly?.points?.[0];

    return (
        facility ?
            <div>
                <div className="text-center justify-self-center">
                    <h1>Radar Detection Boundary</h1>
                    <div className="py-1">
                        {centerCoords && !centerIsLoading &&
                            <OmniGoogleMap center={centerCoords} fitToPolygons={true} draggable={!isEditingBoundaries} >
                                {device && <MapShape facility={facility} shape={device} />}
                                {facility && <ReferencePointMapShape facility={facility} shape={facility.originReferencePointLatLng} />}
                                {boundaryPoly && <DetectionZoneMapShape facility={facility} shape={boundaryPoly} isEditable={isEditingBoundaries} onUpdate={handleUpdate} />}
                                {boundaryPoly && !boundaryPoly.isComplete() && (!!firstPoint) && <CircleMapShape position={firstPoint} radius={5} color={'red'} />}
                            </OmniGoogleMap>}

                    </div>
                    {((hasChanges === undefined || !hasChanges) && !isEditingBoundaries) &&
                        <Button onClick={handleEdit}>Edit Boundaries</Button>
                    }

                    {(isEditingBoundaries || hasChanges) &&
                        <div className="mx-auto flex flex-row-reverse">
                            <Button onClick={handleSaveOnClick} disabled={!hasChanges}>Save Boundaries</Button>
                            {((boundaryPoly?.points?.length ?? 0) < 1) ? <button disabled={true} className="px-4 py-1 h-10 rounded-md block  disabled:opacity-50 bg-brand-primary active:bg-brand-primary-darkened text-bright-text text-text-bright" >Remove Boundaries</button> : <Button onClick={handleReset}>Remove Boundaries</Button>}
                            <Button onClick={handleCancel}>Cancel</Button>
                        </div>
                    }
                </div>
            </div>
            :
            <div>
                <p>Please add this device to a location if you want to configure the boundaries</p>
            </div>
    );
}