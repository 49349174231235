import { Guid, IGuid } from "./System.Guid.gen";
import { Vector2, IVector2 } from "./System.Numerics.Vector2.gen";

export interface ISpot {
	id: string | undefined;

	name: string | undefined;

	points: IVector2[] | undefined;

	origin: IVector2 | undefined;

	$type?: string | undefined;
}
export class Spot {
	constructor(data?: ISpot) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.points = data.points ? data.points.map((p: any) => new Vector2(p)) : [];
		if (data.origin) { this.origin = new Vector2(data.origin); }
	}

	id: string | undefined;

	name: string | undefined;

	points: Vector2[] | undefined = [];

	origin: Vector2 | undefined;

	static $type: string = "Shared.Geometry.Spot, ";
	$type?: string | undefined = Spot.$type;
}

