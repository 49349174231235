import { createContext, ReactNode, useEffect, useState } from "react";
import { ApplicationLoading } from ".";
import { OmniUser, Sessions, UserLogin, UserRoleClaim, Users } from "..";
import { useSession } from "../hooks";

export const authContext = createContext<AuthContext>({} as any);

export interface AuthContext {
  user: OmniUser | undefined;
  userClaims: UserRoleClaim[] | undefined;
  numberFormatter: Intl.NumberFormat;
  isAuthenticated: boolean;
  isLoading: boolean;
  currentOrganizationId: string | undefined;
  setCurrentOrganizationId: (newOrgId: string) => void;
  reload: () => Promise<void>;
  logIn: (login: UserLogin) => Promise<void>;
  logOut: () => Promise<void>;
}

interface AuthContextProps {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProps) => {
  const [user, setUser] = useSession<OmniUser>('currentUser');
  const [hasSessionLoaded, setHasSessionLoaded] = useState(false);
  const [organizationId, setOrganizationId] = useState<string>();
  const [userClaims, setUserClaims] = useState<UserRoleClaim[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [numberFormatter] = useState(new Intl.NumberFormat('en-US'));//T\ODO: move this to backend USER and use everywhere

  const logIn = async (login: UserLogin) => {
    setIsLoading(true);
    await Sessions.login(login);
    return checkAuthState().then(() => setIsLoading(false));
  };

  const logOut = async () => {
    setIsLoading(true);
    await Sessions.logout();
    setUser(undefined);
    setIsLoading(false);
  };

  const setCurrentOrganizationId = async (newOrgId: string) => {
    //Update the server with the newID
    await Users.setLastViewedOrganizationId(newOrgId);
    setOrganizationId(newOrgId);

    //Refresh the user
    await checkAuthState();
    window.location.href = '/';//don't stay on page viewing another orgs data.
  };

  const checkAuthState = async () => {
    var currentUser: OmniUser | undefined = undefined;
    var userRoleClaims: undefined | UserRoleClaim[] = undefined;
    try {
      currentUser = await Sessions.currentIdentity();
      userRoleClaims = await Users.getCurrentUserRoleClaims();
      setOrganizationId((prev) => prev ?? currentUser?.lastViewedOrganizationId);
    } catch (e) {
      //This means we couldn't get a session for this user
    }
    setUser(currentUser);
    setUserClaims(userRoleClaims);
    setHasSessionLoaded(true);
  };

  useEffect(() => {
    const eventName = "auth:checkAuthState";
    document.addEventListener(eventName, checkAuthState);
    return () => {
      document.removeEventListener(eventName, checkAuthState);
    }
  });

  const isAuthenticated = !!user;
  const context: AuthContext = { user, userClaims, isAuthenticated, reload: checkAuthState, isLoading, currentOrganizationId: organizationId, setCurrentOrganizationId, logIn, logOut, numberFormatter };

  useEffect(() => { checkAuthState(); }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const childrenToRender = hasSessionLoaded ? children : <ApplicationLoading />;

  return (
    <authContext.Provider value={context}>
      {childrenToRender}
    </authContext.Provider>
  );
}

