import { FunctionComponent } from "react";
import { InputLabel } from "..";
import { InputProps } from "./inputTypes";
import { ValidatedInputGroup } from "./ValidatedInputGroup";


export const EmailInput: FunctionComponent<InputProps> = (props) => {
  const { fieldName, validationError, validateModel, ...inputProps } = props;

  return (
    <ValidatedInputGroup fieldName={fieldName} validate={validateModel} validationError={validationError}>
      {({ label, handleBlur }) => <>
        {props.label && <InputLabel>{props.label}</InputLabel>}
        <input
          className="w-full omnisight"
          type="email"
          autoComplete="email"
          placeholder={label}
          onBlur={handleBlur}
          {...inputProps}
        />
      </>
      }
    </ValidatedInputGroup>
  );
}