import { ApiResult, DailyAverageByYear, DesignDayVolumes, Reports, TrafficReportConfiguration, getTargetTypeDisplayName, useAuth } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { BarStackMapped, OptionallyStackedBarChart } from "./OptionallyStackedBarChart";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { useContext, useMemo } from "react";
import { useFetch } from "../../hooks";
import { ReportContext, buildStackedData, getZonedDateAsUTC, stackedTooltipBuilder, tooltipBuilder } from "./reportHelpers";
import { OptionallySplitLineChart, SeriesDetails } from "./OptionallySplitLineChart";
import { AverageDailyVolumeByMonth } from "./AverageDailyVolumeByMonth";
import { SelectedReportConfiguration } from "./ReportComponents";
import { TickRendererProps } from "@visx/axis";
import { ScaleOrdinal } from "d3-scale";
import useRenderingTrace from "../../hooks/useRenderingTrace";


function getAnnualClickHandler(cfg: SelectedReportConfiguration) {
    return (datum: any, stackKey?: string | number | symbol | undefined): void => {
        const rParams = { ...cfg };
        rParams.selectedReportId = AverageDailyVolumeByMonth.key;

        rParams.inclusiveStart = getZonedDateAsUTC(datum.year ?? datum.data.stack, 1, 1, cfg.chartTimezone);
        rParams.exclusiveEnd = getZonedDateAsUTC((datum.year ?? datum.data.stack) + 1, 1, 1, cfg.chartTimezone);
        cfg.context.setReportConfig(rParams);
    }
}

const StackedAnnualDailyVolumeReport: React.FC<TrafficReportProps & { data: DailyAverageByYear[] }> = (props: TrafficReportProps & { data: DailyAverageByYear[] }) => {
    const { reportConfig } = useContext(ReportContext);
    const { numberFormatter } = useAuth();

    const ttWithData = useMemo(() => Array.from(new Set(props.data.map((d) => d.targetType))), [props.data]);
    const sortedData = useMemo(() => {
        if (!props.data || !props.data?.length) return [];

        let cData = props.data.filter(x => x.averageDailyVolume > 0);
        if (reportConfig.groupByTargetType)
            cData = buildStackedData(cData, "averageDailyVolume", "targetType");
        return cData?.sort((x, y) => x.year - y.year)

    }, [props.data, reportConfig.groupByTargetType]);

    const series: SeriesDetails<BarStackMapped<DailyAverageByYear>>[] = ttWithData.map(tt => {
        return {
            accessors: {
                xAccessor: dc => dc?.year,
                yAccessor: dc => dc?.$$BarStackMap[tt + '']
            },
            dataKey: tt + ''
        }
    });
    const chartProps = {
        toolTip: stackedTooltipBuilder<DailyAverageByYear>(numberFormatter, (d, tt) => getTargetTypeDisplayName(tt as any)!, undefined, 'Annual Average Daily Volume', d => `Based on data from ${d.numDaysIncluded} days`),
        ...props,
        barStackKey: "targetType" as keyof DailyAverageByYear,
        stackOrGroupLabel: getTargetTypeDisplayName as any,
        onClick: getAnnualClickHandler(reportConfig),
        xKey: "year" as keyof DailyAverageByYear,
        yKey: "averageDailyVolume" as keyof DailyAverageByYear,
        lineSplitKey: "targetType" as keyof DailyAverageByYear,
        lineSplitLabel: getTargetTypeDisplayName as any,
        data: sortedData as any,
        series: series as any
    };
    return (<>
        {reportConfig.chartType === 'bar' && <OptionallyStackedBarChart<DailyAverageByYear> {...chartProps} />}
        {reportConfig.chartType === 'line' && <OptionallySplitLineChart<DailyAverageByYear> {...chartProps} />}
    </>);
}

const NotStackedAnnualDailyVolumeReport: React.FC<TrafficReportProps & { data: DailyAverageByYear[] }> = (props: TrafficReportProps & { data: DailyAverageByYear[] }) => {
    const { reportConfig } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const fitData = useMemo(() => props.data as BarStackMapped<DailyAverageByYear>[], [props.data]);

    const yearsWithData = useMemo(() => Array.from(new Set(fitData.map((d) => d.year))), [fitData]);
    const series: SeriesDetails<BarStackMapped<DailyAverageByYear>>[] = yearsWithData.map(year => {
        return {
            accessors: {
                xAccessor: dc => dc?.year,
                yAccessor: dc => dc?.averageDailyVolume
            },
            dataKey: year + ''
        }
    });

    return reportConfig.chartType === 'bar' ? (<OptionallyStackedBarChart<DailyAverageByYear>
        toolTip={tooltipBuilder<DailyAverageByYear>(x => x.year.toString(), v => numberFormatter.format(v.averageDailyVolume).toString(), 'Annual Average Daily Volume', d => `Based on data from ${d.numDaysIncluded} days`)}
        {...props}
        data={fitData}
        onClick={getAnnualClickHandler(reportConfig)}
        xKey="year"
        yKey="averageDailyVolume" />) :
        (<OptionallySplitLineChart<DailyAverageByYear>
            toolTip={tooltipBuilder<DailyAverageByYear>(x => x.year.toString(), v => numberFormatter.format(v.averageDailyVolume).toString() + '-' + v.numDaysIncluded, 'Annual Average Daily Volume', d => `Based on data from ${d.numDaysIncluded} days`)}
            {...props}
            data={fitData as any}
            series={series as any}
            onClick={getAnnualClickHandler(reportConfig)}
            xKey="year"
            yKey="averageDailyVolume" />);
}

export interface AverageDailyVolumeByYearLoaderProps extends TrafficReportProps {
    dataToChart?: (props: TrafficReportConfiguration) => ApiResult<DesignDayVolumes>;
    xTickLabeler?: (props: TickRendererProps) => React.ReactNode | undefined;
    toolTip?: (datum: BarStackMapped<DailyAverageByYear>, colorScale: ScaleOrdinal<string, any, any>, stackKey?: string | number | symbol | undefined) => JSX.Element;
}

export const AverageDailyVolumeByYearLoader: React.FC<AverageDailyVolumeByYearLoaderProps> = (props: AverageDailyVolumeByYearLoaderProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    useRenderingTrace('AVG D V By year loader', [props, reportConfig, searchParams, props.dataToChart]);
    const [data, error, { isLoading }] = useFetch(() => props.dataToChart ? props.dataToChart(searchParams) : Reports.getAverageDailyVolumes(searchParams), [searchParams, props.dataToChart]);

    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.annualAverages?.length} isLoading={isLoading} size={props.size} >
        {reportConfig.groupByTargetType ? <StackedAnnualDailyVolumeReport {...props} data={data?.annualAverages!} /> : <NotStackedAnnualDailyVolumeReport  {...props} data={data?.annualAverages!} />
        }</ReportLoadingErrorWrapper>);
}

export const AverageDailyVolumeByYear: IConfiguredTrafficReport = {
    name: "by Year",
    key: "advy",
    description: "This report shows average daily traffic for the specified period",
    component: AverageDailyVolumeByYearLoader,
    defaultChartType: 'bar',
    defaultRange: DateOptions.AllData,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};