export enum TargetClassificationStrategy {
	Omnisight = 0,
	FHWA = 1,
}

export const TargetClassificationStrategyDisplayNames: { [key in keyof typeof TargetClassificationStrategy]?: string} = {
	"Omnisight": "Omnisight",
	0 : "Omnisight",
	"FHWA": "FHWA",
	1 : "FHWA",
}

export const getTargetClassificationStrategyDisplayName = (key: TargetClassificationStrategy | (keyof typeof TargetClassificationStrategy & string)) => {
	return TargetClassificationStrategyDisplayNames[key];
}

