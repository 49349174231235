import { Devices } from "@app/shared";
import { useFetch } from "../../hooks";
import { Select, SelectProps } from "./inputs";

type UnassociatedDeviceDropdownProps = Omit<SelectProps, "options">;

export const UnassociatedDeviceDropdown: React.FC<UnassociatedDeviceDropdownProps> = (props) => {
    const [devices, ,] = useFetch(() => Devices.getUnassociatedDeviceOptions());
    //TODO: Maybe refresh every few seconds or something?
    return (
        <Select options={devices} emptyOptionText="Select Device" emptyOptionDisabled={false} {...props} />
    );
}