import { TargetHistory, ITargetHistory } from "./TargetHistory.gen";
import { Single, ISingle } from "./System.Single.gen";
import { Double, IDouble } from "./System.Double.gen";
import { DeviceTargetStatus } from "./Shared.DeviceTargetStatus.gen";
import { TargetType } from "./Shared.TargetType.gen";
import { TargetStatus } from "./Shared.TargetStatus.gen";

export interface ITarget {
	deviceStatus: DeviceTargetStatus;

	currentPositionX: number;

	currentPositionY: number;

	currentVelocityX: number;

	currentVelocityY: number;

	history: ITargetHistory[] | undefined;

	statusStartedAsString: string | undefined;

	statusAsString: string | undefined;

	timeElapsedSinceStatus: string | undefined;

	id: string | undefined;

	externalId: string | undefined;

	deviceId: string | undefined;

	type: TargetType;

	lastUpdate: string | undefined;

	statusStarted: string | undefined;

	status: TargetStatus;

	maxPointCount: number;

	hasPhoto: boolean;

	maximumVelocity: number | undefined;

	maximumWidth: number | undefined;

	maximumHeight: number | undefined;

	averageHeight: number | undefined;

	minimumHeight: number | undefined;

	averageVelocity: number | undefined;

	minimumVelocity: number | undefined;

	startPosition: {x:number,y:number} | undefined;

	endPosition: {x:number,y:number} | undefined;

	netFacilityCountChange: number;

	$type?: string | undefined;
}
export class Target {
	constructor(data?: ITarget) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.history = data.history ? data.history.map((h: any) => new TargetHistory(h)) : [];
		if (data.lastUpdate) { this.lastUpdate = new Date(data.lastUpdate); }
		if (data.statusStarted) { this.statusStarted = new Date(data.statusStarted); }
	}

	deviceStatus: DeviceTargetStatus = 0;

	currentPositionX: number = 0;

	currentPositionY: number = 0;

	currentVelocityX: number = 0;

	currentVelocityY: number = 0;

	history: TargetHistory[] | undefined = [];

	statusStartedAsString: string | undefined;

	statusAsString: string | undefined;

	timeElapsedSinceStatus: string | undefined;

	id: string | undefined;

	externalId: string | undefined;

	deviceId: string | undefined;

	type: TargetType = 0;

	lastUpdate: Date | undefined = new Date();

	statusStarted: Date | undefined = new Date();

	status: TargetStatus = 0;

	maxPointCount: number = 0;

	hasPhoto: boolean = false;

	maximumVelocity: number | undefined;

	maximumWidth: number | undefined;

	maximumHeight: number | undefined;

	averageHeight: number | undefined;

	minimumHeight: number | undefined;

	averageVelocity: number | undefined;

	minimumVelocity: number | undefined;

	startPosition: {x:number,y:number} | undefined = undefined;

	endPosition: {x:number,y:number} | undefined = undefined;

	netFacilityCountChange: number = 0;

	static $type: string = "CoreApi.Models.Target, ";
	$type?: string | undefined = Target.$type;
}

