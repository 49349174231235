import { SensorStreamDataCommand, SensorStreamDataCommandParams, TaskCommand } from "@app/shared";
import { EnumSelect, Input, InputRow, NumericInput } from "../..";
import { useModel } from "../../../hooks";
import { DeviceCommandEditorProps } from "./DeviceCommandEditor";

type EditSensorStreamDataCommandProps = {
} & DeviceCommandEditorProps;

export const EditSensorStreamDataCommand: React.FC<EditSensorStreamDataCommandProps> = (props) => {
    const [, paramsHelper] = useModel(SensorStreamDataCommandParams, (props.command as SensorStreamDataCommand).commandParamsJson);
    const onChange = (newParams: Partial<SensorStreamDataCommandParams>) => {
        props.onChange({ ...props.command, commandParamsJson: newParams } as SensorStreamDataCommand);
    };
    return (
        <>
            <InputRow><EnumSelect label="Command" enumType={TaskCommand} {...paramsHelper.bindingsFor("command", onChange)} /></InputRow>
            <InputRow><Input label="TCP Stream IP Address" {...paramsHelper.bindingsFor("tcpStreamIp", onChange)} /></InputRow>
            <InputRow><NumericInput label="TCP Stream Port" allowFloat={false} {...paramsHelper.bindingsFor("tcpStreamPort", onChange)} /></InputRow>
        </>
    );
}