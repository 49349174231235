import _, { isArray } from "lodash";
import { FC, HTMLProps } from "react";

export type SkeletonGridProps = {
    rows: number;
    cols: number;
    visible?: boolean;
    rowClassName?: string;
    colClassNames?: string[] | string;
} & HTMLProps<HTMLDivElement>;

export const SkeletonGrid: FC<SkeletonGridProps> = ({ rows, cols, rowClassName, visible = true, colClassNames, ...divProps }) => {

    const skeletonCols = _.range(cols).map((v, i) => {
        const colClassName = (!isArray(colClassNames) ? colClassNames : (colClassNames.length > i ? colClassNames[i] : undefined));
        return (
            <div key={"col-" + i} className={"bg-muted-body-text rounded-lg p-2 m-2 " + (colClassName ?? "flex-grow h-8 w-10")} >
            </div>
        );
    });

    const skeletonRows = _.range(rows).map((v, i) => (
        <div key={"row-" + i} className={rowClassName + " w-full flex animate-pulse "} style={{ animationDelay: (2 / (rows + 1) * i) + "s" }}>
            {skeletonCols}
        </div>
    ));

    return (
        <>
            {visible &&
                <div className="w-full flex-col animate-fade-in opacity-0" {...divProps}>
                    {skeletonRows}
                </div>
            }
        </>
    );
}