/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IChangeUserPassword {
	currentPassword: string;

	newPassword2: string;

	newPassword: string;

	$type?: string | undefined;
}
export class ChangeUserPassword {
	constructor(data?: IChangeUserPassword) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	@IsNotEmpty()
	currentPassword: string = "";

	@IsNotEmpty()
	newPassword2: string = "";

	@IsNotEmpty()
	newPassword: string = "";

	static $type: string = "CoreApi.Models.View.ChangeUserPassword, ";
	$type?: string | undefined = ChangeUserPassword.$type;
}

