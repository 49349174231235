import { useState } from "react";
import { SessionSetter, sessionValue } from "..";

export function useSession<T>(key: string): [T | undefined, SessionSetter<T>] {
  const [currentSessionValue, setSessionValue] = sessionValue<T>(key);
  const [stateValue, setStateValue] = useState<T | undefined>(currentSessionValue);

  const setValue: SessionSetter<T> = (value) => {
    setSessionValue(value);
    setStateValue(value);
  }

  return [stateValue, setValue];
}