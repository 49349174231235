import { DateOptions, IConfiguredTrafficReport } from "./IConfiguredTrafficReport";
import { AggregationLevel } from "@app/shared";
import { GetFacilityUsageDataLoader } from "./FacilityUsageDataLoader";
import { formatInTimeZone } from "date-fns-tz";

export const FacilityUsageByYear = {
    name: "by Year",
    description: "This report shows the total Inbound/Outbound Targets in the Location by year for the specified range ",
    component: GetFacilityUsageDataLoader(AggregationLevel.Year, (zone, period) => formatInTimeZone(period, zone, 'yyyy')),
    defaultChartType: 'bar',
    defaultRange: DateOptions.AllData,
    key: 'fuy',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true,
        disabled: false,
        disallowChartTypeToggling: true
    }
} as IConfiguredTrafficReport;