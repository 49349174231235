import { useEffect, useState, useRef } from "react";
import { useDebounce } from "react-use";

export type MaxHeightContainerProps = {
    debounceTime?: number;
    minWidth?: number;
    minHeight: number;
    margin?: { top: number, right: number, bottom: number, left: number }
    className?: string;
    children: (
        args: {
            ref: HTMLDivElement | null;
        } & ParentSizeState,
    ) => React.ReactNode;
};

type ParentSizeState = {
    width: number;
    height: number;
};

export const MaxHeightContainer: React.FC<MaxHeightContainerProps> = ({ minHeight, minWidth, debounceTime = 20, className, children, margin = { top: 32, right: 0, bottom: 32, left: 0 } }) => {
    const calcHeight = () => window.innerHeight - (dynamicHeight.current?.offsetTop ?? 0) - margin.top - margin.bottom;
    const calcWidth = () => {
        const cWidth = Math.min(dynamicHeight.current?.clientWidth ?? 9999, window.innerWidth);
        const usedHorizontalSpace = (dynamicHeight.current?.offsetLeft ?? 0) + margin.left + margin.right + 24;//literal is width of y scrollbar that may be present
        return Math.max(0, cWidth - usedHorizontalSpace);
    }

    const dynamicHeight = useRef<HTMLDivElement>(null);
    const [height, updateHeight] = useState(calcHeight());
    const [width, updateWidth] = useState(calcWidth());
    const [debouncedDimensions, updateDebouncedDimensions] = useState({ width, height });

    function onResize() {
        if (dynamicHeight.current) {
            const newHeight = Math.max(minHeight, calcHeight());
            const wCalc = calcWidth();
            const newWidth = Math.max(calcWidth(), minWidth ?? 0);
            if (height !== newHeight) { updateHeight(newHeight); }
            if (wCalc > 0 && width !== newWidth) { updateWidth(newWidth); }
        }
    };
    useDebounce(() => { updateDebouncedDimensions({ width, height }) }, debounceTime, [height, width]);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        onResize();
        return () => window.removeEventListener("resize", onResize);
    });

    return (
        <div ref={dynamicHeight} className={className ?? "p-3"}>
            {children({
                ...debouncedDimensions,
                ref: dynamicHeight.current
            })}
        </div >
    );
}