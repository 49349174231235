export interface ISensorUpdateCommandParams {
	v: string | undefined;

	url: string | undefined;

	hash: string | undefined;

	force: boolean;

	$type?: string | undefined;
}
export class SensorUpdateCommandParams {
	constructor(data?: ISensorUpdateCommandParams) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	v: string | undefined;

	url: string | undefined;

	hash: string | undefined;

	force: boolean = false;

	static $type: string = "CoreApi.Models.DeviceCommands.SensorUpdateCommandParams, ";
	$type?: string | undefined = SensorUpdateCommandParams.$type;
}

