import cn from "classnames";
import React, { ReactNode, useEffect, useState } from 'react';
import { SkeletonGrid, Table } from ".";
import { sortArray } from "../../helpers";
import { Icons } from "../shared";

type IBaseModel = {
    id: string | undefined;
    $type?: string | undefined;
}
export type SortableTableHeaderType<T> = {
    header: string | ReactNode;
    dataKey?: string & keyof (T);
    dataClassName?: string;
    className?: string;
    width: number;
    renderFunc?: (data?: T) => ReactNode;

};
export type SortableTableProps<T> = {
    columns: SortableTableHeaderType<T>[];
    rowClassNames?: string;
    data: T[] | undefined;
    className?: string;
    isLoading?: boolean;
    onRowClick?: (rowData: T) => any | void;
    initialSortKey?: string & keyof (T);
    initialSortDir?: "asc" | "desc";
    emptyValue?: React.ReactNode | string;
}

export type SortKeyType<T> = (string & keyof (T));
export type SortParams<T> = {
    sortKey?: SortKeyType<T>;
    isAscending: boolean;
}

export const SortableTable = <T extends IBaseModel>(props: SortableTableProps<T>) => {
    const [sortParams, setSortParams] = useState<SortParams<T>>({
        sortKey: props.initialSortKey ?? (props.columns.length > 0 ? props.columns[0].dataKey : undefined),
        isAscending: (props.initialSortDir !== undefined ? props.initialSortDir === "asc" : true)
    });
    const [sortedData, setSortedData] = useState<T[]>();

    useEffect(() => {
        //If there is nothing to sort by, reset to our props data
        const sortKey = sortParams.sortKey ?? (props.columns.length > 0 ? props.columns[0].dataKey : undefined);
        if (sortKey === undefined) {
            console.warn("Sort key is undefined for a sortable table...");
        }
        if (sortKey === undefined) {
            setSortedData([...(props.data ?? [])]);
            return;
        }

        setSortedData([...(sortArray(props.data, sortKey, sortParams.isAscending ? "asc" : "desc") ?? [])]);
    }, [sortParams, props.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>, key?: SortKeyType<T>) => {
        if (!key) return;

        e.preventDefault();
        e.stopPropagation();

        setSortParams((prev) => {
            return { sortKey: key, isAscending: (prev.sortKey !== key ? false : !prev.isAscending) };
        });
    }

    const headerDivs = props.columns.map((c, i) => (
        <Table.HeaderCol
            key={`hc-${i}`}
            className={cn(c.className, "text-left pb-3 pr-3 uppercase font-semibold", { "cursor-pointer": c.dataKey })}
            style={{ width: c.width, flexGrow: c.width }}
            onClick={(e) => handleHeaderClick(e, c.dataKey)}
            onMouseDown={(e) => e.preventDefault()}
        >
            {c.header}
            {c.dataKey &&
                (c.dataKey === sortParams.sortKey ?
                    (sortParams.isAscending ?
                        <Icons.SortUp className="inline" />
                        : <Icons.SortDown className="inline" />)
                    : <Icons.UnSorted className="inline" />
                )
            }

        </Table.HeaderCol>
    ));

    const dataDivs = sortedData?.map((data, r) => (
        <Table.Row
            key={data.id}
            className={cn(props.rowClassNames, "flex items-center p-1 px-2", { "hover:bg-background-page-highlight-gray cursor-pointer": props.onRowClick })}
            onClick={() => props.onRowClick && props.onRowClick(data)}>
            {props.columns.map((c, i) => (
                <Table.Col key={`${data.id}-${i}`} className={c.dataClassName} style={{ width: c.width, flexGrow: c.width }}>
                    <>
                        {c.renderFunc ? c.renderFunc(data) : (c.dataKey && data[c.dataKey])}
                    </>
                </Table.Col>
            ))}
        </Table.Row>
    ));

    return (
        <Table.Container>
            <Table.Header>
                <Table.HeaderRow className="flex">
                    {headerDivs}
                </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
                {(props.isLoading) &&
                    <Table.Row>
                        <Table.Col>
                            <SkeletonGrid cols={props.columns.length} rows={3} />
                        </Table.Col>
                    </Table.Row>
                }
                {(sortedData && sortedData.length === 0 && props.emptyValue && !props.isLoading) &&
                    <Table.Row>
                        <Table.Col className="text-center">
                            {props.emptyValue}
                        </Table.Col>
                    </Table.Row>
                }
                {!props.isLoading && sortedData && dataDivs}
            </Table.Body>
        </Table.Container>);
}