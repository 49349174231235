import { Ruler, IRuler } from "./Shared.Geometry.Ruler.gen";
import { Spot, ISpot } from "./Shared.Geometry.Spot.gen";
import { Double, IDouble } from "./System.Double.gen";

export interface IFacilityGeometryConfiguration {
	backgroundImageSource: string | undefined;

	scaleReferenceLine: IRuler | undefined;

	originReferencePoint: ISpot | undefined;

	backgroundImageWidth: number | undefined;

	backgroundImageHeight: number | undefined;

	$type?: string | undefined;
}
export class FacilityGeometryConfiguration {
	constructor(data?: IFacilityGeometryConfiguration) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.scaleReferenceLine) { this.scaleReferenceLine = new Ruler(data.scaleReferenceLine); }
		if (data.originReferencePoint) { this.originReferencePoint = new Spot(data.originReferencePoint); }
	}

	backgroundImageSource: string | undefined;

	scaleReferenceLine: Ruler | undefined;

	originReferencePoint: Spot | undefined;

	backgroundImageWidth: number | undefined;

	backgroundImageHeight: number | undefined;

	static $type: string = "CoreApi.Models.View.FacilityGeometryConfiguration, ";
	$type?: string | undefined = FacilityGeometryConfiguration.$type;
}

