import { FunctionComponent } from "react";
import { InputLabel } from "./InputLabel";
import { InputProps } from "./inputTypes";
import { ValidatedInputGroup } from "./ValidatedInputGroup";


type PasswordInputProps = InputProps & {
  isNew?: boolean;
  onEnter?: () => void;
}

export const PasswordInput: FunctionComponent<PasswordInputProps> = (props) => {
  const { fieldName, validationError, validateModel, isNew, onEnter, ...inputProps } = props;

  const autoComplete = isNew ? 'new-password' : 'password';

  return (
    <ValidatedInputGroup fieldName={fieldName} validate={validateModel} validationError={validationError}>
      {({ label, handleBlur }) =>
        <>
          {props.label && <InputLabel>{props.label}</InputLabel>}
          <input
            className="omnisight w-full"
            type="password"
            autoComplete={autoComplete}
            placeholder={label}
            onBlur={handleBlur}
            onKeyUp={async (e) => {
              if (onEnter && e.key === "Enter") {
                await onEnter();
              }
            }}
            {...inputProps}
          />
        </>
      }
    </ValidatedInputGroup>
  );
}