import { FC, Suspense, lazy } from "react";
import { LoadingIndicator } from "../../shared";
import { useAuth } from "@app/shared";

export const LATEST_TERMS_PATH = "/terms_03272024.pdf";

const TermsLazy = lazy(() => import('./TermsLazyLoaded'));
export const Terms: FC<{ showActions: boolean }> = (props) => {

    const { isAuthenticated } = useAuth();

    return (<Suspense fallback={<LoadingIndicator />}>
        <TermsLazy showActions={isAuthenticated} latestTermsPath={LATEST_TERMS_PATH}/>
    </Suspense>);
}