import { DailyAverageByMonth, Reports, useAuth } from "@app/shared";
import { useFetch } from "../../hooks";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { OptionallySplitLineChart, SeriesDetails } from "./OptionallySplitLineChart";
import { BarStackMapped, OptionallyStackedBarChart } from "./OptionallyStackedBarChart";
import { useContext, useMemo } from "react";
import { ReportContext, buildStackedData, groupByKeys, monthLabeler, stackedTooltipBuilder, tooltipBuilder } from "./reportHelpers";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { format } from "date-fns";



const MonthlyVolumeDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const [data, error, { isLoading }] = useFetch(() => Reports.getAverageDailyVolumes(searchParams), [searchParams]);
    const years = useMemo(() => data?.monthlyAverages?.length ? Array.from(new Set(data.monthlyAverages.map((d) => d.year))) : undefined, [data]);
    const sortedData = useMemo(() => {
        if (!data?.monthlyAverages?.length) return undefined;
        const ed = [...data.monthlyAverages];
        years!.forEach(year => {
            for (let i = 0; i < 12; i++) {
                const found = ed?.find(d => d.month === (i + 1) && d.year === year);
                if (!found) {
                    ed.push({ month: i + 1, averageDailyVolume: 0, year: year, targetType: undefined, $type: undefined, numDaysIncluded: -1 })
                } else {
                    found.targetType = undefined;
                    found.$type = undefined;
                }
            }
        });
        return ed?.sort((a, b) => a.month - b.month);
    }, [data, years]);
    const fitData = useMemo(() => {
        if (!sortedData?.length) return undefined;
        const filtered = sortedData.map(({ numDaysIncluded, ...rest }) => rest);
        //let cData = groupByKeys(sortedData!, ['month', 'targetType'], 'averageDailyVolume');//[...data];
        const cData = groupByKeys(filtered, ['year', 'month'], 'averageDailyVolume');//[...data];
        return buildStackedData<Omit<DailyAverageByMonth, 'numDaysIncluded'>>(cData, "averageDailyVolume", "year");
    }, [sortedData]);

    const series = useMemo(() => {
        if (!sortedData?.length) return undefined;

        const cData = Array.from(new Set(data?.monthlyAverages?.map((d) => d.year)));
        return cData?.map(year => {
            return {
                accessors: {
                    xAccessor: dc => dc?.month,
                    yAccessor: dc => {  return dc?.$$BarStackMap[year + ''] }
                },
                dataKey: year + ''
            }
        }) as SeriesDetails<BarStackMapped<DailyAverageByMonth>>[];
    }, [data, sortedData]);
    const lineTTips = stackedTooltipBuilder(numberFormatter, (x: DailyAverageByMonth, key: string) => key, undefined, x => 'Average Daily Volume - ' + format(new Date(x.year, x.month - 1, 1), 'MMMM'));
    const barTTips = tooltipBuilder((x: DailyAverageByMonth) => x.year + '', d => numberFormatter.format(Math.round(d.averageDailyVolume)), x => 'Average Daily Volume - ' + format(new Date(x.year, x.month - 1, 1), 'MMMM'));

    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.monthlyAverages?.length} isLoading={isLoading} size={props.size} >{reportConfig.chartType === 'line' ?
        <OptionallySplitLineChart {...props} data={fitData as any} series={series as any} lineSplitKey='year' lineSplitLabel={y => y?.toString()} xKey="month" yKey="averageDailyVolume" toolTip={lineTTips} xTickCount={12} xTickLabeler={monthLabeler} />
        : <OptionallyStackedBarChart {...props} data={sortedData as BarStackMapped<DailyAverageByMonth>[]} xKey="month" yKey="averageDailyVolume" xTickLabeler={monthLabeler} barGroupKey="year" toolTip={barTTips} stackOrGroupLabel={x => x.toString()} />}
    </ReportLoadingErrorWrapper>);
}


export const MonthlyComparisonReport = {
    name: "Monthly Volume Comparison Report",
    description: "This report shows the average daily volume for every month grouped by year for the specified period",
    component: MonthlyVolumeDataLoader,
    key: 'mvc',
    defaultChartType: 'bar',
    defaultRange: DateOptions.AllData,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: false//already grouping here can't group both ways yet
    }
} as IConfiguredTrafficReport;