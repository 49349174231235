import { DetectionZone, Facility } from "@app/shared";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ICoordsHelper, MapContext, MapObjArray, circle, createGooglePolygon, useFacilityCoordsHelper } from "../../../../helpers";

export type RectangleMapShapeProps = { facility: Facility, color?: string, shape?: DetectionZone, isSelected?: boolean, isEditable?: boolean, isSelectable?: boolean, onUpdate?: (shape: DetectionZone) => void, onClick?: (e: google.maps.MapMouseEvent, shape: DetectionZone) => void };
export const RectangleMapShape: React.FC<RectangleMapShapeProps> = ({ facility, shape, isEditable, isSelectable, onUpdate, onClick, color }) => {
    const [parentMap, setBounds] = useContext(MapContext);
    const mapObjects = useRef([] as MapObjArray);
    const listeners = useRef([] as Array<google.maps.MapsEventListener>);
    const vertexCircles = useRef([] as Array<google.maps.Marker>);
    const [zonePoly, setZonePoly] = useState<google.maps.Polygon>();
    const coordsHelper = useFacilityCoordsHelper(facility);
    const lastMouseEvent = useRef('');

    useEffect(() => {//create/update google poly for shape        
        if (parentMap && shape?.points?.length) {
            const [poly] = initPoly(shape, parentMap, coordsHelper, color);
            setZonePoly(poly);
            setBounds(curr => {
                poly.getPath().getArray().forEach(pt => curr.extend(pt));
                return curr;
            });

            return () => {
                mapObjects.current?.forEach(mo => { mo?.setVisible(false); mo.setMap(null); });
                poly.setMap(null); poly.setVisible(false);
                setZonePoly(undefined);
                mapObjects.current = [];
            }
        }
    }, [parentMap, mapObjects, shape, color, coordsHelper, setBounds]);


    const dragRectangle = useCallback((e: google.maps.MapMouseEvent) => {
        if (!shape?.points?.length || !e.latLng) { return; };
        const newPath = [coordsHelper.toLatLng(shape.points[0])];

        newPath.push(new google.maps.LatLng({ lng: newPath[0].lng(), lat: e.latLng.lat() }));
        newPath.push(new google.maps.LatLng({ lng: e.latLng.lng(), lat: e.latLng.lat() }));
        newPath.push(new google.maps.LatLng({ lng: e.latLng.lng(), lat: newPath[0].lat() }));
        zonePoly?.setPath(newPath);
    }, [shape, coordsHelper, zonePoly]);

    const handleEditClick = (e: google.maps.MapMouseEvent) => {
        if ((e.domEvent.type === 'click' && lastMouseEvent.current === 'mousedown') ||
            (e.domEvent.type === 'mouseup' && lastMouseEvent.current === 'click')) {
            lastMouseEvent.current = e.domEvent.type;
            return;//don't double up while watching both events
        }
        lastMouseEvent.current = e.domEvent.type;
        if (!e.latLng || !shape) { return; }
        const dz = shape;
        const v2 = coordsHelper.fromLatLng(e.latLng);

        if (dz.points?.length) {
            dragRectangle(e);
            dz.points = zonePoly?.getPath().getArray().map(coordsHelper.fromLatLng);
            dz.points?.push(dz.points[0]);
            onUpdate?.(Object.assign(new DetectionZone(), { ...dz }));
        } else {
            dz.points = [v2];
            onUpdate?.(Object.assign(new DetectionZone(), { ...dz }));
            return;
        }
    }
    const memoHandleEditClick = useCallback(handleEditClick, [shape, zonePoly, onUpdate, coordsHelper, dragRectangle]);


    const handleMove = (e: google.maps.MapMouseEvent) => {
        if (!zonePoly || !isEditable || !e.latLng || shape?.isComplete()) return;
        dragRectangle(e);
    };
    const memoHandleMove = useCallback(handleMove, [zonePoly, isEditable, shape, dragRectangle]);

    useEffect(() => { // make selectable
        if (!isSelectable) { return; }
        let selectListener: google.maps.MapsEventListener;
        const zp = zonePoly;
        if (shape?.isComplete() && onClick && zp) {
            zp.set('clickable', true);
            selectListener = zp.addListener('click', (e: google.maps.MapMouseEvent) => onClick?.(e, Object.assign(new DetectionZone(), { ...shape })));
        }

        return () => {
            zp?.set('clickable', false);
            selectListener?.remove()
        };

    }, [onClick, shape, isSelectable, zonePoly]);

    const createVertexCircles = useCallback((poly: google.maps.Polygon | google.maps.Polyline) => {
        const path = poly.getPath().getArray();
        vertexCircles.current = path.map((pos, idx) => {
            const isClickable = shape?.isComplete() || idx !== 0;
            const posMark = new google.maps.Marker({
                icon: circle("#5900ff"),
                position: pos,
                map: parentMap,
                clickable: isClickable,
                draggable: isClickable,
                cursor: 'all-scroll'
            });
            //unintuitively dragging will change position of multiple points in path so don't use that path to calc new DZ position
            listeners.current.push(posMark.addListener('dragend', (d: google.maps.MapMouseEvent) => {
                if (d.latLng && zonePoly) {
                    const points = [...shape?.points ?? []];
                    points[idx] = coordsHelper.fromLatLng(d.latLng);
                    const path = zonePoly.getPath();
                    path.setAt(idx, d.latLng);
                    if (idx === 0) {
                        path.setAt(path.getLength() - 1, d.latLng);
                        vertexCircles.current[path.getLength() - 1]?.setPosition(d.latLng);
                        points[points.length - 1] = points[0];
                    } else if (idx === (path.getLength() - 1)) {
                        path.setAt(0, d.latLng);
                        vertexCircles.current[0]?.setPosition(d.latLng);
                        points[0] = points[points.length - 1];
                    }
                    onUpdate?.(Object.assign(new DetectionZone(), { ...shape, points: points }));
                }
            }));
            listeners.current.push(posMark.addListener('drag', (d: google.maps.MapMouseEvent) => {

                if (d.latLng && zonePoly) {
                    const path = zonePoly.getPath();
                    path.setAt(idx, d.latLng);
                    if (idx === 0 || idx === (path.getLength() - 1)) {
                        idx === 0 ? path.setAt(path.getLength() - 1, d.latLng) : path.setAt(0, d.latLng);
                    }
                    zonePoly?.setPath(path);
                }
            }));

            return posMark;
        });
        vertexCircles.current.forEach(vc => mapObjects.current.push(vc));
    }, [vertexCircles, listeners, shape, parentMap, onUpdate, coordsHelper, zonePoly]);

    useEffect(() => { //editable    

        if (isEditable && parentMap) {
            if (!shape?.isComplete()) {
                listeners.current.push(parentMap.addListener('click', memoHandleEditClick));
                listeners.current.push(parentMap.addListener('mouseout', () => zonePoly?.setPath([...(shape?.points?.map(coordsHelper.toLatLng)) ?? []])));//move covers mousein 
                listeners.current.push(parentMap.addListener('mousedown', memoHandleEditClick));
                listeners.current.push(parentMap.addListener('mouseup', memoHandleEditClick));
                listeners.current.push(parentMap.addListener('mousemove', memoHandleMove));
            }
        }

        return () => {
            listeners.current.forEach(l => l?.remove());
            listeners.current = [];
            vertexCircles.current.forEach(l => { l.setVisible(false); l.setMap(null) });
            vertexCircles.current = [];
        }

    }, [isEditable, shape, parentMap, memoHandleMove, memoHandleEditClick, onUpdate, coordsHelper, createVertexCircles, zonePoly])
    return null;
}


function initPoly(shape: DetectionZone, parentMap: google.maps.Map, coordsHelper: ICoordsHelper, color?: string) {
    return createGooglePolygon([shape.points ?? []], coordsHelper, parentMap, {
        strokeColor: color,
        strokeOpacity: 0.8,
        fillColor: color,
        zIndex: 100
    });
}