import { Vector2 } from "@app/shared";

export enum Orientation { Colinear = 0, Clockwise = 1, CounterClockwise = 2 }

export const degreesToRadians = (degrees: number) => (degrees * Math.PI / 180);
export const radiansToDegrees = (radians: number) => (radians * 180 / Math.PI);

export const isQOnSegmentPR = (p: Vector2, q: Vector2, r: Vector2) => {
    if (q.x <= Math.max(p.x, r.x) && q.x >= Math.min(p.x, r.x) &&
        q.y <= Math.max(p.y, r.y) && q.y >= Math.min(p.y, r.y))
        return true;

    return false;
}

// To find orientation of ordered triplet (p, q, r).
export const getOrientation = (p: Vector2, q: Vector2, r: Vector2) => {
    // See https://www.geeksforgeeks.org/orientation-3-ordered-points/
    // for details of below formula.
    var val = (q.y - p.y) * (r.x - q.x) -
        (q.x - p.x) * (r.y - q.y);

    if (val === 0) return Orientation.Colinear;

    return (val > 0) ? Orientation.Clockwise : Orientation.CounterClockwise;
}

export const getDistanceBetween = (point1: Vector2, point2: Vector2) => {
    var dx = point2.x - point1.x;
    var dy = point2.y - point1.y;

    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
}

export const doLinesIntersect = (line1Start: Vector2, line1End: Vector2, line2Start: Vector2, line2End: Vector2) => {
    // Find the four orientations needed for general and
    // special cases
    var o1 = getOrientation(line1Start, line1End, line2Start);
    var o2 = getOrientation(line1Start, line1End, line2End);
    var o3 = getOrientation(line2Start, line2End, line1Start);
    var o4 = getOrientation(line2Start, line2End, line1End);

    // General case
    if (o1 !== o2 && o3 !== o4)
        return true;

    // Special Cases
    // p1, q1 and p2 are colinear and p2 lies on segment p1q1
    if (o1 === 0 && isQOnSegmentPR(line1Start, line2Start, line1End)) return true;

    // p1, q1 and q2 are colinear and q2 lies on segment p1q1
    if (o2 === 0 && isQOnSegmentPR(line1Start, line2End, line1End)) return true;

    // p2, q2 and p1 are colinear and p1 lies on segment p2q2
    if (o3 === 0 && isQOnSegmentPR(line2Start, line1Start, line2End)) return true;

    // p2, q2 and q1 are colinear and q1 lies on segment p2q2
    if (o4 === 0 && isQOnSegmentPR(line2Start, line1End, line2End)) return true;

    return false; // Doesn't fall in any of the above cases
}

export const getHeadingForPoint = (x: number, y: number) => {
    return getHeadingBetweenPoints([0, 0], [x, y]);
}

export const getHeadingBetweenPoints = (p1: [number?, number?], p2: [number?, number?]) => {
    if (p1[0] === undefined || p1[1] === undefined) { return undefined; }
    if (p2[0] === undefined || p2[1] === undefined) { return undefined; }

    const x = p2[0] - p1[0];
    const y = p2[1] - p1[1];

    if (x === 0 && y === 0) { return undefined; }

    // these coordinates are reversed to make the heading calculation easier. :)
    const radians = Math.atan2(x, y);
    const degrees = radiansToDegrees(radians);

    return (degrees + 360) % 360;
}

/* If we need this, add 'yarn add gl-matrix'
export const rotatePoint = (heading: number, p: [number, number], origin?: [number, number]) => {
    var retVal: vec2 = [0, 0];
    vec2.rotate(retVal, p, origin ?? [0, 0], heading);
    return retVal;
}*/

export const rotateVector = (v: Vector2, angleDegrees: number) => {
    var angRad = angleDegrees * (Math.PI / 180);
    var ret = new Vector2();
    ret.x = v.x * Math.cos(angRad) - v.y * Math.sin(angRad);
    ret.y = v.x * Math.sin(angRad) + v.y * Math.cos(angRad);
    return ret;
};

export const isSameDirection = (heading1?: number, heading2?: number) => {
    if (heading1 === undefined || heading2 === undefined) return false;

    var angle = Math.abs(heading1 - heading2);
    var smallestAngle = Math.min(angle, 360 - angle);

    return smallestAngle < 90;
}
export type OrientationDirection = ("forWeb" | "forServer" | "forDevice" | "fromDevice");
export const reOrientXyPoint = (point: { x: number, y: number } | undefined, origin: Vector2, scale: number, direction: OrientationDirection) => {
    if (!point) return point;
    var retVal = { ...point };

    if (direction === "forWeb") {
        retVal.x = origin.x + retVal.x / scale;
        retVal.y = origin.y + retVal.y / scale;
    }
    else if (direction == "forServer") {
        retVal.x = (retVal.x - origin.x) * scale;
        retVal.y = (retVal.y - origin.y) * scale;
    }
    else if (direction == "forDevice") {
        retVal.x = (retVal.x - origin.x) * scale;
        retVal.y = (retVal.y - origin.y) * scale * -1.0;
    }
    else if (direction === "fromDevice") {
        retVal.x = origin.x + retVal.x / scale;
        retVal.y = origin.y + retVal.y / scale * -1.0;
    }

    return retVal;
}

