export interface IAcceptedTerms {
	id: string | undefined;

	fileName: string | undefined;

	acceptedAt: string | undefined;

	$type?: string | undefined;
}
export class AcceptedTerms {
	constructor(data?: IAcceptedTerms) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.acceptedAt) { this.acceptedAt = new Date(data.acceptedAt); }
	}

	id: string | undefined;

	fileName: string | undefined;

	acceptedAt: Date | undefined = new Date();

	static $type: string = "CoreApi.Models.AcceptedTerms, ";
	$type?: string | undefined = AcceptedTerms.$type;
}

