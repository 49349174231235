import { AxisScaleOutput, TickRendererProps } from "@visx/axis";
import { Text } from "@visx/text";
import { Axis, Grid, LineSeries, Tooltip, XYChart, EventHandlerParams, lightTheme } from "@visx/xychart";
import { curveCardinal } from '@visx/curve';
import React, { useMemo } from "react";
import { ScaleOrdinal } from "d3-scale";
import { ChartSizeProps } from "../shared/charts";
import { BarStackMapped } from "./OptionallyStackedBarChart";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { ScaleConfig, scaleOrdinal } from "@visx/scale";
import { extLightThemeColors } from "../../styles/chartThemeColors";

export type OptionallySplitLineChartProps<TData extends {}> = {
    id?: string;
    title?: string | ((data: any) => string);
    subTitle?: string | ((data: any) => string);
    data: BarStackMapped<TData>[];
    series?: SeriesDetails<TData>[];
    lineSplitKey?: keyof TData;
    lineSplitLabel?: (stackKeyVal: number | symbol | string) => string;
    toolTip?: (datum: BarStackMapped<TData>, colorScale: ScaleOrdinal<string, any, any>, stackKey?: string | number | symbol) => JSX.Element;
    onClick?: (datum: BarStackMapped<TData>, stackKey?: string | number | symbol) => void;
    xTickCount?: number;
    xTickLabeler?: (props: TickRendererProps) => React.ReactNode | undefined;
    isLoading?: boolean;
    xKey: keyof TData;
    yKey: keyof TData;
    yUnit?: string;
    domain?: [number, number];
    size: ChartSizeProps;
}

export type SeriesAccessors<T> = {
    xAccessor: (data?: T) => any;
    yAccessor: (data?: T) => number | undefined;
}

export type SeriesDetails<T> = {
    dataKey: string;
    accessors: SeriesAccessors<T>;
    opacity?: number;
}

type YScaleConfig = ScaleConfig<AxisScaleOutput, any, any>;
function defaultTickLabeler(props: TickRendererProps) {
    const { formattedValue, ...tickProps } = props;
    return (<>
        {formattedValue?.split("|").map((str: string, ind: number) => <Text key={str} {...tickProps} dy={ind > 0 ? 15 : 0}>{str}</Text>)}
    </>);
}
export function OptionallySplitLineChart<T extends object>(props: OptionallySplitLineChartProps<T>) {
    const { data = [] as BarStackMapped<T>[], lineSplitKey, isLoading } = props;
    let { width = 50, height = 600, margin } = props.size;
    if (!!!margin || margin?.top === undefined) {
        margin = { top: 16, right: 25, bottom: 30, left: 50 };
        margin.top += props.title ? 24 : 0;
        margin.top += props.subTitle ? 24 : 0;
    }
    if (lineSplitKey && !props.lineSplitLabel)
        throw new Error('lineSplitLabel function must be provided when providing barStackKey');

    const xScale = { type: 'band', paddingInner: .9 } as ScaleConfig<AxisScaleOutput, any, any>;
    var yScale = { type: 'linear', nice: true } as YScaleConfig;
    if (props.domain) {
        yScale.domain = props.domain;
    }

    const uniqueStackValues = useMemo(() => {
        if (lineSplitKey && !isLoading && data?.length && data[0].$$BarStackMap) {
            return Array.from(new Set(data.flatMap((d) => Object.keys(d.$$BarStackMap))));
        }
        return ['any'];
    }, [data, lineSplitKey, isLoading]);
    const colorScale = useMemo(() => scaleOrdinal<string>({
        domain: [...uniqueStackValues!],
        range: [...extLightThemeColors]
    }), [uniqueStackValues]);
    function getColorFunc(stackVal: string) {
        return () => {
            return colorScale(stackVal) as string | null;
        };
    }
    const handleClick = (event: EventHandlerParams<BarStackMapped<T>>) => {
        if (props.onClick && event.datum) props.onClick(event.datum, event.key);
    }
    const theme = { ...lightTheme, colors: extLightThemeColors }
    return (<>
        {/* <h2 className="text-center pb-0 mb-0">{title}</h2> */}
        <XYChart height={height} width={width} xScale={xScale} yScale={yScale} margin={margin} captureEvents onPointerUp={handleClick} theme={theme}>
            {props.title && <Text x={width / 2} y={16} textAnchor="middle" fontSize={20} fontWeight="bold" >{typeof props.title === 'function' ? props.title(props.data) : props.title}</Text>}
            {props.subTitle && <Text x={width / 2} y={props.title ? 40 : 16} textAnchor="middle" fontSize={16} fontWeight="bold" >{typeof props.subTitle === 'function' ? props.subTitle(props.data) : props.subTitle}</Text>}
            <Axis orientation="bottom" numTicks={props.xTickCount ?? 10} tickComponent={props.xTickLabeler ?? defaultTickLabeler} />
            <Axis orientation="left" numTicks={5} />
            <Grid columns={false} numTicks={10} />

            {(props.series && data) && props.series.map(c =>
                <LineSeries key={c.dataKey} dataKey={c.dataKey} data={data} {...(c.accessors as any)} opacity={c.opacity} curve={curveCardinal} colorAccessor={getColorFunc(c.dataKey)} />
            )}
            {!props.series && data &&
                <LineSeries dataKey="any" xAccessor={x => x[props.xKey]} yAccessor={y => y[props.yKey]} data={data} curve={curveCardinal} color={extLightThemeColors[0]} />
            }
            {props.toolTip && <Tooltip<BarStackMapped<T>>
                snapTooltipToDatumX
                snapTooltipToDatumY
                //showVerticalCrosshair
                showSeriesGlyphs
                renderTooltip={({ tooltipData, colorScale }) => (
                    tooltipData?.nearestDatum && props.toolTip!(tooltipData.nearestDatum.datum, colorScale!, tooltipData.nearestDatum.key)
                )}
            />}

        </XYChart>
        {lineSplitKey && <StackLegend {...props} scale={colorScale!} />}
    </>
    );
}


const StackLegend = <TData extends {}>(props: OptionallySplitLineChartProps<TData> & { scale: ScaleOrdinal<string, any, never> }) => {
    const legendGlyphSize = 15;
    return (<div
        style={{
            top: (props.size.margin?.top ?? 20) / 2 - 10,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '14px',
        }}
    >
        <LegendOrdinal scale={props.scale} direction="row" labelMargin="0 15px 0 0">
            {labels => (
                <div className="flex">
                    {labels.map((label, i) => (
                        <LegendItem key={`legend-${i}`} className="cursor-pointer" >
                            <svg width={legendGlyphSize} height={legendGlyphSize}>
                                <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                            </svg>
                            <LegendLabel align="left" margin="0 20px 0 8px">
                                {props.lineSplitLabel!(label.datum) ?? '*[BAD DATA]'}
                            </LegendLabel>
                        </LegendItem>
                    ))}
                </div>
            )}
        </LegendOrdinal>
    </div>);
}