import { ZoneOccupancy, IZoneOccupancy } from "./ZoneOccupancy.gen";

export interface IFacilityOccupancy {
	facilityId: string | undefined;

	dateCreated: string | undefined;

	zoneOccupancy: IZoneOccupancy[] | undefined;

	$type?: string | undefined;
}
export class FacilityOccupancy {
	constructor(data?: IFacilityOccupancy) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.dateCreated) { this.dateCreated = new Date(data.dateCreated); }
		this.zoneOccupancy = data.zoneOccupancy ? data.zoneOccupancy.map((z: any) => new ZoneOccupancy(z)) : [];
	}

	facilityId: string | undefined;

	dateCreated: Date | undefined = new Date();

	zoneOccupancy: ZoneOccupancy[] | undefined = [];

	static $type: string = "CoreApi.Models.FacilityOccupancy, ";
	$type?: string | undefined = FacilityOccupancy.$type;
}

