import { FacilityUtilization, IFacilityUtilization } from "./Reports.FacilityUtilization.gen";

export interface IFacilityUtilizationReport {
	facilityId: string | undefined;

	facilityName: string | undefined;

	utilizations: IFacilityUtilization[] | undefined;

	$type?: string | undefined;
}
export class FacilityUtilizationReport {
	constructor(data?: IFacilityUtilizationReport) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.utilizations = data.utilizations ? data.utilizations.map((u: any) => new FacilityUtilization(u)) : [];
	}

	facilityId: string | undefined;

	facilityName: string | undefined;

	utilizations: FacilityUtilization[] | undefined = [];

	static $type: string = "CoreApi.Models.Reports.FacilityUtilizationReport, ";
	$type?: string | undefined = FacilityUtilizationReport.$type;
}

