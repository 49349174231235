import { Button, ButtonVariants, Sessions } from "@app/shared";
import { FC, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Pages } from "..";
import { NoAuthAccountLayout } from "../../layouts";
import { ErrorAlert, Input, InputRow } from "../../shared";

export const ForgotPassword: FC = (props) => {
    let location = useLocation();
    const [email, setEmail] = useState(location?.state?.email ?? "");
    const [error, setError] = useState<string>();
    const [confirmation, setConfirmation] = useState<string>();
    const navigate = useNavigate();
    const submitButton = useRef<HTMLButtonElement>(null);


    const handleSendEmailOnClick = async () => {
        try {
            await Sessions.forgotPassword(email)
            setConfirmation("Thank you.  An email has been sent to the provided email address.");
        } catch (e) {
            setError("Error: " + e);
        }
    };

    const handleCancelOnClick = () => {
        navigate(Pages.Login.getUrl());
    };

    return (
        <NoAuthAccountLayout>
            <h2>Forgot Your Password?</h2>
            {confirmation ?
                <>
                    <p>{confirmation}</p>
                    <div className="flex pt-5 gap-5 place-items-center justify-center">
                        <Button onClick={handleCancelOnClick}>Back to Login</Button>
                    </div>
                </>
                :
                <>
                    <p>Please enter your email address below and we will send you an email with a link to reset your password</p>
                    <ErrorAlert errors={error} />
                    <InputRow>
                        <Input label="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} onEnter={() => {
                            submitButton.current?.click();
                        }} />
                    </InputRow>
                    <div className="flex pt-5 gap-5 place-items-center justify-center">
                        <Button ref={submitButton} onClick={handleSendEmailOnClick}>Send Email</Button>
                        <Button variant={ButtonVariants.Secondary} onClick={handleCancelOnClick}>Cancel</Button>
                    </div>
                </>
            }
        </NoAuthAccountLayout>
    );
}