import { useContext, useEffect, useState } from "react";
import { ContainerContext } from "../components/shared/ContainerProvider";

export const useContainer = (visible: boolean) => {
    var containers = useContext(ContainerContext);
    var [containerId, setContainerId] = useState<string>();

    useEffect(() => {
        var newId = containers?.pushContainer(undefined, visible);
        if (!newId) {
            console.warn("unable to create new container!");
            return;
        }

        setContainerId(newId);
        return function cleanup() {
            if (newId)
                containers?.popContainer(newId);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const currentId = containerId;
        if (currentId)
            containers?.setVisible(currentId, visible);
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    return containerId;
}