import classNames from 'classnames';
import { FunctionComponent, SelectHTMLAttributes, useEffect, useRef, useState, ReactNode } from "react";
import { InputLabel } from "..";
import { InputProps } from "./inputTypes";
import { ValidatedInputGroup } from "./ValidatedInputGroup";


export type MultiSelectProps = {
    options: SimpleOption[],
    emptyOptionText?: string,
    emptyOptionDisabled?: boolean,
    value: Array<string | number>,
    label?:ReactNode,
    selectAllOption?: SimpleOption,
    onChange: (values: Array<string | number>) => void
} & Omit<Omit<InputProps<SelectHTMLAttributes<HTMLSelectElement>>, 'value'>,'label'>;

export interface SimpleOption {
    value: string | number;
    label: ReactNode;
}

export const MultiSelect: FunctionComponent<MultiSelectProps> = (props) => {
    const { fieldName, validationError, validateModel, options } = props;
    const [selected, setSelected] = useState<Array<string | number>>(props.value);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ref = useRef(null);

    useEffect(() => {
        setSelected(cv => {
            if (cv !== props.value) {
                return props.value;
            } return cv;
        });
    }, [props.value]);


    const toggleOption = (option: string | number) => {
        const isSelected = selected.includes(option);
        let nextSelection = isSelected ? selected.filter((value) => value !== option) : [...selected, option];

        if (props.selectAllOption) {
            if ((option === props.selectAllOption?.value && nextSelection.includes(option))) {
                nextSelection = [props.selectAllOption?.value];
                setIsOpen(false);//if you just selected all then close the dialog
            } else if ((options.length - 1) === nextSelection.length) {
                nextSelection = [props.selectAllOption?.value];
            } else {
                nextSelection = nextSelection.filter(x => x !== props.selectAllOption?.value);
            }
        }

        setSelected(nextSelection);
        props.onChange(nextSelection);
    };
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !(ref.current as any)?.contains(event.target)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        window.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on cleanup
            window.removeEventListener("click", handleClickOutside);
        };
    }, [ref]);




    return (
        <ValidatedInputGroup fieldName={fieldName} validate={validateModel} validationError={validationError}>
            {({ label, handleBlur }) => <div className={props.className}>
                {props.label && <InputLabel>{props.label}</InputLabel>}
                <div className="relative" ref={ref}>
                    <button
                        type="button"
                        className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onClick={toggleMenu}
                    >
                        <span className="flex items-center">
                            <span className="ml-3 block truncate">
                                {selected.length === 0 ? label : selected.map(v => options.find(x => x.value === v)?.label).join(', ')}
                            </span>
                        </span>
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                                className="h-5 w-5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </button>
                    {isOpen && <div className="absolute mt-1 w-full bg-dialog-background shadow-lg rounded-md z-10">
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className={classNames(
                                    'cursor-pointer select-none relative py-2 pl-3 pr-9',
                                    selected.includes(option.value)
                                        ? 'bg-indigo-500 text-white'
                                        : 'text-gray-900'
                                )}
                                onClick={() => toggleOption(option.value)}
                            >
                                <div className="flex items-center space-x-3">
                                    <div className="font-normal">
                                        {option.label}
                                    </div>
                                    {selected.includes(option.value) && (
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                            <svg
                                                className="h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M14.348 5.652a1 1 0 00-1.413 0L10 8.586l-2.935-2.934a1 1 0 00-1.413 1.414l3.5 3.5a1 1 0 001.414 0l6.5-6.5a1 1 0 000-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}

                    </div>}
                </div>
            </div>
            }
        </ValidatedInputGroup>
    );
};