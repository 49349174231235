import { Facility, ZoneOccupancy } from "@app/shared";
import { getTargetTypeDisplayName } from "@app/shared/src/models/Shared.TargetType.gen";
import React, { useMemo } from "react";
import { CheckboxInput, SortableTable } from "../../shared";
import { formatInTimeZone } from "date-fns-tz";

type RecentOccupancyTableProps = {
    facility: Facility;
    data: ZoneOccupancy[];
    isLoading?: boolean;
};

export const RecentOccupancyTable: React.FC<RecentOccupancyTableProps> = ({ facility, data, isLoading }) => {
    const getDeviceNameForZone = (zone?: ZoneOccupancy) => facility.devices?.find(d => d.id === zone?.deviceId)?.name ?? "";

    const zone = facility.localTimezoneId ?? Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
    const timeZone = useMemo(() => {
        return formatInTimeZone(new Date(), zone, "z");
    }, [zone]);

    return (
        <SortableTable
            data={data}
            initialSortKey={"dateDetected"}
            initialSortDir="desc"
            isLoading={isLoading}
            columns={[
                { dataKey: "detectionZoneId", header: "Detection Zone", width: 100, renderFunc: (zi) => `${facility.detectionZones?.find(z => z.id === zi?.detectionZoneId)?.name}` },
                { dataKey: "isOccupied", header: "Occupied", width: 100, renderFunc: (zi) => <CheckboxInput checked={zi?.isOccupied} readOnly={true} /> },
                { dataKey: "targetType", header: "Target Type", width: 150, renderFunc: (target) => getTargetTypeDisplayName(target!.targetType) },
                { header: "Detection Device", width: 175, renderFunc: getDeviceNameForZone },
                { dataKey: "dateDetected", header: `Last Change (${timeZone})`, width: 135, renderFunc: (target) => target?.dateDetected ? formatInTimeZone(target?.dateDetected, zone, "MMM d, HH:mm") : "" },
            ]}
        />
    );
}