import { Vector3, IVector3 } from "./System.Numerics.Vector3.gen";
import { DeviceTargetStatus } from "./Shared.DeviceTargetStatus.gen";
import { TargetType } from "./Shared.TargetType.gen";

export interface ITargetHistory {
	position: IVector3 | undefined;

	velocity: IVector3 | undefined;

	acceleration: IVector3 | undefined;

	id: string | undefined;

	frameNumber: number;

	targetId: string | undefined;

	date: string | undefined;

	status: DeviceTargetStatus;

	type: TargetType;

	width: number;

	height: number;

	pointCount: number;

	netFacilityCountChange: number;

	$type?: string | undefined;
}
export class TargetHistory {
	constructor(data?: ITargetHistory) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.position) { this.position = new Vector3(data.position); }
		if (data.velocity) { this.velocity = new Vector3(data.velocity); }
		if (data.acceleration) { this.acceleration = new Vector3(data.acceleration); }
		if (data.date) { this.date = new Date(data.date); }
	}

	position: Vector3 | undefined;

	velocity: Vector3 | undefined;

	acceleration: Vector3 | undefined;

	id: string | undefined;

	frameNumber: number = 0;

	targetId: string | undefined;

	date: Date | undefined = new Date();

	status: DeviceTargetStatus = 0;

	type: TargetType = 0;

	width: number = 0;

	height: number = 0;

	pointCount: number = 0;

	netFacilityCountChange: number = 0;

	static $type: string = "CoreApi.Models.TargetHistory, ";
	$type?: string | undefined = TargetHistory.$type;
}

