import { CustomClaimValue, FHWAClass, TargetClassificationStrategy, TargetType, useAuth } from "@app/shared";
import { addDays, addMonths, getDate, getMonth, getYear } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useContext, useMemo, useState } from "react";
import useRenderingTrace from "../../hooks/useRenderingTrace";
import { CheckboxInput, DateInput, IconLink, Icons, PageContainer, Select, SelectOptionGroup } from "../shared";
import { HoverTooltip } from "../shared/HoverTooltip";
import { MultiSelect, SimpleOption } from "../shared/inputs/MultiSelect";
import { DateOptions, IConfiguredTrafficReport } from "./IConfiguredTrafficReport";
import { GetReport, GroupedReportConfigurations } from "./ReportComponents";
import { AllFHWAClasses, AllTargetTypes, ReportContext } from "./reportHelpers";
import { TargetTypeMultiSelect } from "./TargetTypeMultiSelect";
import { TotalVolumeByDay } from "./TotalVolumeByDay";

const reportGroups: SelectOptionGroup[] = GroupedReportConfigurations.map(g => {
    return {
        label: g.label,
        options: g.values.map(rc => { return { label: rc.name, value: rc.key, disabled: rc.config?.disabled } })
    }
});

export function getReportPeriod(selectedDateId: DateOptions, facilityTimezone: string) {//tested working 8/16/2024

    const current = new Date();
    let updatedPeriod: { inclusiveStart: Date | undefined, exclusiveEnd: Date | undefined } = {
        inclusiveStart: undefined,
        exclusiveEnd: undefined
    };
    switch (selectedDateId) {
        case DateOptions.AllData:
            updatedPeriod = {
                inclusiveStart: undefined,
                exclusiveEnd: undefined
            };
            break;
        case DateOptions.LastYear:
            updatedPeriod = {
                inclusiveStart: new Date(current.getFullYear() - 1, 0, 1),
                exclusiveEnd: new Date(current.getFullYear(), 0, 1)
            };
            break;
        case DateOptions.LastMonth:
            current.setDate(0);//last day of last month
            current.setDate(1);//first day of last month

            updatedPeriod = {
                inclusiveStart: new Date(current.getFullYear(), current.getMonth(), current.getDate()),
                exclusiveEnd: addMonths(new Date(current.getFullYear(), current.getMonth(), current.getDate()), 1)
            };
            break;
        case DateOptions.LastWeek:
            current.setDate(current.getDate() - (current.getDay() + 7)); // First day is the day of the month - the day of the week
            updatedPeriod = {
                inclusiveStart: new Date(current.getFullYear(), current.getMonth(), current.getDate()),
                exclusiveEnd: addDays(new Date(current.getFullYear(), current.getMonth(), current.getDate()), 7)
            };
            break;
        case DateOptions.Last90Days:
            const daysAgo90 = new Date(addDays(new Date(), -89).setHours(0, 0, 0, 0));
            const startOfDay = current.setHours(0, 0, 0, 0);
            updatedPeriod = {
                inclusiveStart: daysAgo90,
                exclusiveEnd: addDays(startOfDay, 1)
            };
            break;
        case DateOptions.ThisYear:
            updatedPeriod = {
                inclusiveStart: new Date(current.getFullYear(), 0, 1),
                exclusiveEnd: new Date(current.getFullYear() + 1, 0, 1)
            };
            break;
        case DateOptions.ThisMonth:
            updatedPeriod = {
                inclusiveStart: new Date(current.getFullYear(), current.getMonth(), 1),
                exclusiveEnd: new Date(current.getFullYear(), current.getMonth() + 1, 1)
            };
            break;
        default:
            break;
    }
    if (updatedPeriod.inclusiveStart) {
        updatedPeriod.inclusiveStart = zonedTimeToUtc(updatedPeriod.inclusiveStart, facilityTimezone)!;
    }
    if (updatedPeriod.exclusiveEnd) {
        updatedPeriod.exclusiveEnd = zonedTimeToUtc(updatedPeriod.exclusiveEnd, facilityTimezone)!;
    }
    return updatedPeriod;
}

export const ReportParametersForm: React.FC = () => {
    const { reportConfig, updateReportConfig } = useContext(ReportContext);
    const user = useAuth();
    const isSuperAdmin = user.userClaims?.find(uc => uc.claims.find(c => c === CustomClaimValue.SuperAdmin));
    const { facility, device } = reportConfig.context;
    const deviceOptions = useMemo(() => !!!facility ? [{ label: device?.name, value: device?.id }] : [{ label: 'All Devices', value: 'all' }, ...facility?.devices?.map(d => ({ label: d.name, value: d.id! })) ?? []], [device, facility]);
    const deviceSelections = reportConfig.deviceIds?.length === (deviceOptions.length - 1) ? ['all'] : reportConfig.deviceIds;
    const [showFilters, setShowFilters] = useState(false);


    function handlePeriodChange(e: any) {
        const selectedDateId = e.target!.value as DateOptions;
        updateReportConfig({ ...reportConfig!, ...getReportPeriod(selectedDateId, reportConfig!.chartTimezone), selectedDateId: selectedDateId ?? DateOptions.Custom });
    }

    function getAvailableDevices(): Array<string> {
        if (facility) {
            return facility.devices?.map(d => d.id ?? '') ?? [];
        }
        return [device!.id!];
    }

    const selectedGroup = useMemo(() => GroupedReportConfigurations.find(rc => rc.values.find(x => x.key === reportConfig.selectedReportId)), [reportConfig.selectedReportId]);
    const selectedReport = useMemo(() => {
        const group = GroupedReportConfigurations.find(rc => rc.values.find(x => x.key === reportConfig.selectedReportId));
        return group?.values.find(x => x.key === reportConfig.selectedReportId)
    }, [reportConfig.selectedReportId]);


    function handleCustomStart(e: Date | null) {
        if (!e) return;
        let start = new Date(e);
        start.setHours(0, 0, 0, 0);//now beginning of the day in browser time
        start = zonedTimeToUtc(start.toISOString(), reportConfig!.chartTimezone);
        updateReportConfig({ ...reportConfig!, inclusiveStart: start, selectedDateId: calcRangeSelection(start, reportConfig!.exclusiveEnd) });
    }
    function handleCustomEnd(e: Date | null) {
        if (!e) return;
        let end = addDays(new Date(e), 1);
        end.setHours(0, 0, 0, 0);
        end = zonedTimeToUtc(end.toISOString(), reportConfig!.chartTimezone)!;
        updateReportConfig({ ...reportConfig!, exclusiveEnd: end, selectedDateId: calcRangeSelection(reportConfig!.inclusiveStart, end) });
    }

    const numFilters = useMemo(() => {
        let count = 0;
        if (reportConfig!.deviceIds !== undefined && reportConfig!.deviceIds?.length !== (deviceOptions.length - (deviceOptions[0].value === 'all' ? 1 : 0))) count++;
        if (reportConfig!.targetTypes.length !== Object.keys(TargetType).filter(x => isNaN(parseInt(x))).length) count++;
        if (reportConfig!.groupByTargetType) count++;
        if (selectedReport && reportConfig!.selectedDateId && (reportConfig!.selectedDateId !== selectedReport?.defaultRange)) count++;

        return count;
    }, [reportConfig, selectedReport, deviceOptions]);

    const resetFilters = () => changeReport(selectedReport!);
    const handleReportChange = (e: React.ChangeEvent<HTMLSelectElement>) => changeReport(GetReport(e.target.value));

    function changeReport(report: IConfiguredTrafficReport) {
        updateReportConfig({ ...reportConfig!, selectedReportId: report.key, chartType: report.defaultChartType, ...getReportPeriod(report.defaultRange, reportConfig!.chartTimezone), selectedDateId: report.defaultRange, groupByTargetType: false, targetTypes: AllTargetTypes, deviceIds: getAvailableDevices(), tTGrouping: TargetClassificationStrategy.Omnisight });
    }
    function calcRangeSelection(start?: Date, end?: Date) {
        let result = DateOptions.Custom;
        if (start) {
            Object.values(DateOptions).forEach(opt => {
                var range = getReportPeriod(opt, reportConfig!.chartTimezone);
                if (range.inclusiveStart === start && range.exclusiveEnd === end) {
                    result = opt;
                }
            });
        }
        return result;
    }
    const dateSelectOptions = Object.values(DateOptions).map(opt => ({ label: opt, value: opt }));

    if (selectedReport?.config && !selectedReport?.config?.allowTargetTypeGrouping && reportConfig.groupByTargetType) {
        updateReportConfig({ ...reportConfig, groupByTargetType: false });
    }


    useRenderingTrace('ReportParametersForm', [facility, device, reportConfig]);

    const toInclusiveDateOnly = (x: Date) => {
        if (!x) { return x; }
        const dateOnly = convertToSystemDateOnly(x, reportConfig.chartTimezone);
        const p1 = zonedTimeToUtc(addDays(dateOnly, -1), reportConfig.chartTimezone);
        return p1;
    }

    const convertToSystemDateOnly = (date: Date, timeZone: string) => {
        const zonedDate = utcToZonedTime(date, timeZone);
        const year = getYear(zonedDate);
        const month = getMonth(zonedDate);
        const day = getDate(zonedDate);
        return new Date(year, month, day);
    }
    const showFHWA = isSuperAdmin && reportConfig.selectedReportId == TotalVolumeByDay.key;

    return (
        <PageContainer Icon={Icons.StatsDots} title="Report Settings">
            <div className="flex flex-wrap justify-start">
                <div className="basis-full ">
                    <div className="flex flex-wrap">
                        <HoverTooltip placement="right" hoverable={
                            <Select className="w-full focus:outline-0 bg-transparent mb-4" label="Report" optionGroups={reportGroups} showGroupName={selectedGroup?.includeLabelInTitle} value={reportConfig.selectedReportId} onChange={handleReportChange} Icon={Icons.Info} />
                        }>
                            <span>{selectedReport?.description}</span>
                        </HoverTooltip>
                        <span className="ml-5 mt-3 select-none flex items-center">
                            <IconLink iconClassName=" " className="mx-0 text-info-icon" Icon={Icons.Filter} onClick={e => setShowFilters(!showFilters)}>{showFilters ? 'Hide' : 'Show'} Filters</IconLink>
                            {numFilters !== 0 && <div className="w-5 h-5 ml-1 rounded-full bg-brand-primary flex items-center justify-center text-bright-text text-xs font-bold">{numFilters}</div>}
                            {numFilters !== 0 && showFilters && <IconLink className="ml-9 text-info-icon" iconClassName="stroke-brand-primary reset-icon" Icon={Icons.Reset} onClick={resetFilters} >Reset</IconLink>}
                        </span>
                    </div>
                </div>
                {showFilters && <>
                    {!!!device && <div className="hmm mr-5">
                        <MultiSelect className="focus:outline-0 bg-transparent mb-4" label="Devices" options={deviceOptions as SimpleOption[]} value={deviceSelections!} onChange={e => updateReportConfig({ ...reportConfig, deviceIds: ((e as string | number[])[0] === 'all' ? getAvailableDevices() : e as string[]) })} selectAllOption={deviceOptions[0] as SimpleOption} />
                    </div>}

                    <div className="hmm mr-5"><Select className="focus:outline-0 bg-transparent mb-4" label="Data Range" options={dateSelectOptions} value={reportConfig.selectedDateId} onChange={handlePeriodChange} /></div>
                    {selectedReport?.config?.allowsCustomRange && <div className="hmm mr-5"><DateInput onChange={handleCustomStart} displayTimezone={reportConfig.chartTimezone} label="Start Date" showMonthYearPicker={false} showTimeSelect={false} ignoreStartDate={true} value={reportConfig.inclusiveStart?.toISOString()} showTimeInput={false} dateFormat="MMMM dd yyyy" /></div>}
                    {selectedReport?.config?.allowsCustomRange && <div className="hmm mr-5"><DateInput onChange={handleCustomEnd} displayTimezone={reportConfig.chartTimezone} label="End Date" showMonthYearPicker={false} showTimeSelect={false} ignoreStartDate={true} value={reportConfig.exclusiveEnd ? toInclusiveDateOnly(reportConfig.exclusiveEnd).toISOString() : undefined} minDate={reportConfig.inclusiveStart} showTimeInput={false} dateFormat="MMMM dd yyyy" /></div>}
                </>}

                {showFilters && <TargetTypeMultiSelect showFHWA={showFHWA} className="min-w-[300px] mr-5" label="Target Types" ttGroups={reportConfig.tTGrouping} onChange={(ttType, vals) => updateReportConfig({ ...reportConfig,tTGrouping:ttType, targetTypes: ttType === TargetClassificationStrategy.Omnisight ? vals as TargetType[] : AllTargetTypes, fHWAClasses: ttType === TargetClassificationStrategy.FHWA ? vals as FHWAClass[] : AllFHWAClasses })} values={{ ttType: reportConfig.tTGrouping, vals: reportConfig.tTGrouping === TargetClassificationStrategy.Omnisight ? reportConfig.targetTypes : reportConfig.fHWAClasses }} />}
                {showFilters && <div className="flex items-baseline mt-9 hmm mr-5">
                    {selectedReport?.config?.allowTargetTypeGrouping && <CheckboxInput label="Distinguish TargetTypes" className="mx-2 mr-5" onChange={e => updateReportConfig({ ...reportConfig, groupByTargetType: e.target.checked })} checked={reportConfig.groupByTargetType} />}
                </div>}
            </div>
        </PageContainer>
    );
}