/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IOrganization {
	id: string | undefined;

	isDeleted: boolean;

	name: string;

	address: string | undefined;

	city: string | undefined;

	state: string | undefined;

	zipcode: string | undefined;

	phoneNumber: string | undefined;

	emailAddress: string | undefined;

	address2: string | undefined;

	$type?: string | undefined;
}
export class Organization {
	constructor(data?: IOrganization) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	isDeleted: boolean = false;

	@IsNotEmpty()
	name: string = "";

	address: string | undefined;

	city: string | undefined;

	state: string | undefined;

	zipcode: string | undefined;

	phoneNumber: string | undefined;

	emailAddress: string | undefined;

	address2: string | undefined;

	static $type: string = "CoreApi.Models.Organization, ";
	$type?: string | undefined = Organization.$type;
}

