import cn from "classnames";
import { HoverTooltip } from "./HoverTooltip";
type StatusDotProps = {
    status?: 'ok' | 'warn' | 'error';
    sizeClasses?: string;
    hoverMessage?: string | React.ReactNode;
};

export const StatusDot: React.FC<StatusDotProps> = ({ status, sizeClasses, hoverMessage }) => {
    const color = status === 'ok' ? "#30D15F" : (status === 'error' ? "#D00E0E" : (status === 'warn' ? "#FFD700" : "#FFFFFF"));
    var dot = <div className={cn("rounded my-auto mx-auto", sizeClasses ?? "h-2 w-2", { "border": status === undefined })} style={{ backgroundColor: color }} />

    if (!hoverMessage)
        return dot;

    return (
        <HoverTooltip hoverable={dot}>
            {hoverMessage}
        </HoverTooltip>
    );
}