import { PropsWithChildren, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { IconType } from "react-icons";
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback";

type PageContainerProps = {
    title: string | ReactNode;
    Icon?: IconType;
    titleRight?: string | ReactNode;
    className?: string;
};

export const PageContainer: React.FC<PropsWithChildren<PageContainerProps>> = ({ children, Icon, ...props }) => {

    return (
        <div className={`w-full my-4 p-0 shadow-sm min-w-fit border border-l border-r border-b border-separators-page ${props.className}`}>
            <div className="flex items-center px-3 py-2 m-0 bg-background-container-title" style={{ minHeight: 60 }}>
                <div className="flex-grow text-semi-bright-text uppercase flex items-center font-semibold gap-x-3">
                    {Icon &&
                        <Icon className="w-7 h-7" />
                    }
                    <div>
                        {props.title}
                    </div>
                </div>
                {props.titleRight &&
                    <div>
                        {props.titleRight}
                    </div>
                }
            </div>
            <div className="p-3  ">
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    {children}
                </ErrorBoundary>
            </div>
        </div>
    );
}