export enum AggregationLevel {
	FiveMinutes = 5,
	Day = 24,
	Month = 30,
	Hour = 60,
	Year = 365,
}

export const AggregationLevelDisplayNames: { [key in keyof typeof AggregationLevel]?: string} = {
	"FiveMinutes": "Five Minutes",
	5 : "Five Minutes",
	"Day": "Day",
	24 : "Day",
	"Month": "Month",
	30 : "Month",
	"Hour": "Hour",
	60 : "Hour",
	"Year": "Year",
	365 : "Year",
}

export const getAggregationLevelDisplayName = (key: AggregationLevel | (keyof typeof AggregationLevel & string)) => {
	return AggregationLevelDisplayNames[key];
}

