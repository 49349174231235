import { enumKeys, FHWAClass, getFHWAClassDisplayName, getTargetTypeDisplayName, TargetClassificationStrategy, TargetType } from "@app/shared";
import { FunctionComponent, useMemo, useState } from "react";
import { MultiSelect, MultiSelectProps, SimpleOption } from "../shared/inputs/MultiSelect";
import { AllFHWAClasses, AllTargetTypes } from "./reportHelpers";

export type TargetTypeMultiSelectProps = {
    showFHWA: boolean | undefined,
    ttGroups: TargetClassificationStrategy,
    values: { ttType: TargetClassificationStrategy, vals: TargetType[] | FHWAClass[] },
    onChange: (TTType: TargetClassificationStrategy, values: TargetType[] | FHWAClass[]) => void,
    options?: { Omni: SimpleOption[], FHWA: SimpleOption[] }
} & Omit<Omit<Omit<MultiSelectProps, 'value'>, 'onChange'>, 'options'>;

const allOpt = { label: 'All', value: -1 };

export const TargetTypeMultiSelect: FunctionComponent<TargetTypeMultiSelectProps> = (props) => {

    //const [ttType, setTTType] = useState(TargetClassificationStrategy.Omnisight);
    const targetTypeOptions = useMemo(() => {
        if (props.ttGroups === TargetClassificationStrategy.Omnisight) {
            const opts = [allOpt, ...enumKeys(TargetType).map(k => ({
                label: getTargetTypeDisplayName(k)!,
                value: TargetType[k] as number
            }))];
            opts.sort((a, b) => a.label.localeCompare(b.label));
            return opts;
        } else {
            const opts = [allOpt, ...enumKeys(FHWAClass).map(k => ({
                label: getFHWAClassDisplayName(k)!,
                value: FHWAClass[k] as number
            }))];
            opts.sort((a, b) => a.value-b.value);
            return opts;
        }

    }, [props.options, props.ttGroups]);

    const ttValue = useMemo(() => {
        if (props.values?.vals?.length === (targetTypeOptions.length - 1)) {
            return [-1];
        }
        if (props.ttGroups === TargetClassificationStrategy.Omnisight) {
            return targetTypeOptions.filter(x => (props.values.vals as TargetType[]).includes(x.value as TargetType)).map(x => x.value);
        } else {
            return targetTypeOptions.filter(x => (props.values.vals as FHWAClass[]).includes(x.value as FHWAClass)).map(x => x.value);
        }
    }, [props.values, targetTypeOptions]);

    const toValue = (vals: number[]) => {
        if (!vals?.length || vals[0] === -1) {
            props.onChange(props.ttGroups, targetTypeOptions.filter(x => x.value !== -1).map(x => x.value as number))
        } else {
            props.onChange(props.ttGroups, vals)
        }
    }
    const ttChoice = (<><span className="me-3">{props.label}</span><span><input type="radio" id="html" name="fav_language" value="HTML" className="me-1" checked={props.ttGroups === TargetClassificationStrategy.Omnisight} onChange={x => props.onChange(TargetClassificationStrategy.Omnisight,AllTargetTypes)} /><label htmlFor="html" className="me-2">Omnisight</label>
        <input type="radio" id="css" name="fav_language" value="CSS" className="me-1" checked={props.ttGroups === TargetClassificationStrategy.FHWA} onChange={x => props.onChange(TargetClassificationStrategy.FHWA, AllFHWAClasses)} />
        <label htmlFor="css">FHWA</label></span></>);
    return (
        <MultiSelect {...props} label={props.showFHWA ? ttChoice : props.label} value={ttValue} options={targetTypeOptions} onChange={toValue as any} selectAllOption={allOpt} />
    );
};
