import { useAuth } from "@app/shared";
import { FC, PropsWithChildren, ReactNode, useEffect, useMemo } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Pages } from "../pages";
import { SidebarNav } from "./SidebarNav";
import { LATEST_TERMS_PATH } from "../pages/account/Terms";
import { urlToFilename } from "../../helpers";

export type DashboardLayoutProps =
    {
        centerChildren: ReactNode;
        leftChildren?: ReactNode;
        rightChildren?: ReactNode;
        bodyClassName?: string;
    } & PropsWithChildren;
export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const hasAcceptedTerms = useMemo(() => !!(auth.user?.terms?.find(x => x.fileName === urlToFilename(LATEST_TERMS_PATH))), [auth.user]);

    useEffect(() => {
        if (auth.isLoading) { return; }
        if (!auth.isAuthenticated) {
            navigate(Pages.Login.getUrl());
        } else if (!hasAcceptedTerms) {
            navigate(Pages.Terms.getUrl());
        }
    }, [auth.isLoading, auth.isAuthenticated, hasAcceptedTerms, navigate])

    return (
        <div className="min-h-full h-full flex text-body-text">
            <Toaster />
            <SidebarNav></SidebarNav>
            <div className="bg-background-page rounded-t-lg flex-grow overflow-auto">
                <div className="bg-background-top-nav rounded-t-lg flex h-14 items-center border-b border-separators-page">
                    <div className="text-muted-body-text ml-5">
                        {props.leftChildren}
                    </div>
                    <div className="flex-grow">
                        <div className="text-xl text-center ">
                            {props.centerChildren}
                        </div>
                    </div>
                    <div className="text-muted-body-text mr-5">
                        {props.rightChildren}
                    </div>

                </div>
                <div className={(props.bodyClassName ?? "m-5") + " min-w-fit"}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}