import cn from 'classnames';
import { IconBaseProps } from "react-icons";
import { PiMouseSimple, PiMouseRightClickFill  } from "react-icons/pi";
import { AiFillApi, AiOutlineSend, AiOutlineSplitCells } from "react-icons/ai";
import { BiDetail, BiStats, BiTargetLock } from "react-icons/bi";
import { BsClockHistory, BsSliders, BsThreeDots } from "react-icons/bs";
import { FaArrowAltCircleUp, FaCalendar, FaCaretDown, FaCaretRight, FaCaretUp, FaCheckCircle, FaClipboard, FaLayerGroup, FaClock, FaCogs, FaDownload, FaDrawPolygon, FaEdit, FaExclamationCircle, FaEye, FaEyeSlash, FaInfoCircle, FaLock, FaMapMarker, FaNetworkWired, FaParking, FaPencilAlt, FaPlusCircle, FaRegObjectGroup, FaRoute, FaRuler, FaSatelliteDish, FaSearch, FaSort, FaSortDown, FaSortUp, FaSpinner, FaStopwatch, FaTablet, FaTimes, FaTimesCircle, FaTrash, FaTruck, FaUsers, FaVideo } from "react-icons/fa";
import { FaUpRightFromSquare, FaTimeline, FaArrowsUpDown, FaArrowsLeftRight } from "react-icons/fa6";
import { GrPowerReset, GrRefresh } from "react-icons/gr";
import { ImExit, ImPlus } from "react-icons/im";
import { IoEnter, IoExit, IoSettingsSharp } from "react-icons/io5";
import { MdAccountCircle, MdExpandMore, MdPhotoCamera, MdReport } from "react-icons/md";
import { RiFileDamageLine } from "react-icons/ri";
import { TbCloudLockOpen, TbMessage2Code } from "react-icons/tb";
import { TiArrowBack, TiMessageTyping } from "react-icons/ti";

export const Icons = {
    Api: AiFillApi,
    ArrowBack: TiArrowBack,
    ArrowsUpDown: FaArrowsUpDown,
    ArrowsLeftRight: FaArrowsLeftRight,
    MouseScroll: PiMouseSimple,
    Expand:MdExpandMore,
    RightMouseButton:PiMouseRightClickFill ,
    Clipboard: FaClipboard,
    Download: FaDownload,
    Edit: FaEdit,
    PlusCircle: FaPlusCircle,
    Refresh: GrRefresh,
    Trash: FaTrash,
    Timeline: FaTimeline,
    Plus: ImPlus,
    Close: FaTimesCircle,
    Search: FaSearch,
    OverflowMenu: BsThreeDots,
    Pencil: FaPencilAlt,
    Eye: FaEye,
    EyeSlash: FaEyeSlash,
    Show: FaEye,
    Hide: FaEyeSlash,
    OpenModal: FaUpRightFromSquare,
    SortUp: FaSortUp,
    SortDown: FaSortDown,
    UnSorted: FaSort,
    Filter: BsSliders,
    Profile: MdAccountCircle,
    Spinner: FaSpinner,
    CaretDown: FaCaretDown,
    CaretUp: FaCaretUp,
    CaretRight: FaCaretRight,
    Calendar: FaCalendar,
    Logout: ImExit,
    Send: AiOutlineSend,
    OpenPort: TbCloudLockOpen,
    NetworkWired: FaNetworkWired,
    MessageArrows: TbMessage2Code,
    Truck: FaTruck,
    Satellite: FaSatelliteDish,
    Clock: FaClock,
    Parking: FaParking,
    StopWatch: FaStopwatch,
    Entry: IoEnter,
    Exit: IoExit,
    Details: BiDetail,
    Settings: IoSettingsSharp,
    Target: BiTargetLock,
    Route: FaRoute,
    Photo: MdPhotoCamera,
    History: BsClockHistory,
    StatsDots: BiStats,
    Message: TiMessageTyping,
    Users: FaUsers,
    Warning: FaExclamationCircle,
    Info: FaInfoCircle,
    Error: FaTimesCircle,
    Success: FaCheckCircle,
    Times: FaTimes,
    Page404: RiFileDamageLine,
    Reset: GrPowerReset,
    Upgrade: FaArrowAltCircleUp,
    Report: MdReport,
    ObjectGroup: FaRegObjectGroup,
    MapMarker: FaMapMarker,
    LayerGroup: FaLayerGroup,
    Ruler: FaRuler,
    DrawPolygon: FaDrawPolygon,
    Video: FaVideo,
    Lock: FaLock,
    SplitCells: AiOutlineSplitCells,
    NavTruck: FaTruck,
    NavRadar: (props: IconBaseProps) => FaTablet({ ...props, className: cn(props.className, "rotate-180") }),
    NavSettings: FaCogs
};

