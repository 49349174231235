import { Facility, ScreenLine, Target, Vector2 } from "@app/shared";
import { useEffect, useState } from "react";
import { ScreenLineMapShape } from "./ScreenLineMapShape";


export type TargetMapShapeProps = { facility: Facility, shape?: Target, isSelected?: boolean, isEditable?: boolean, onUpdate?: (shape: ScreenLine) => void };
export const TargetMapShape: React.FC<TargetMapShapeProps> = ({ facility, shape, isEditable, onUpdate, isSelected }) => {

    const [line, setLine] = useState<ScreenLine>();
    useEffect(() => {
        if (shape?.endPosition && shape.startPosition) {
            const sl = new ScreenLine();
            sl.id = shape?.id;
            sl.points = [new Vector2({ x: shape?.startPosition?.x, y: shape?.startPosition.y }), new Vector2({ x: shape?.endPosition?.x, y: shape?.endPosition.y })];
            setLine(sl);
        }
    }, [shape, shape?.endPosition])
    return (<ScreenLineMapShape shape={line} facility={facility} />);
}