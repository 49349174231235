import { Button, Organization, Organizations, useAuth } from "@app/shared";
import { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { Pages } from "..";
import { useModel } from "../../../hooks";
import { DashboardLayout } from "../../layouts";
import { Input } from "../../shared";

type AddOrganizationProps = {
};

export const AddOrganization: React.FC<AddOrganizationProps> = (props) => {
    const [org, orgHelpers] = useModel(Organization);
    const { currentOrganizationId, setCurrentOrganizationId } = useAuth();
    const navigate = useNavigate();

    const handleSaveOnClick: MouseEventHandler = async () => {
        orgHelpers.validateWithAction(async () => {
            const newOrgId = await Organizations.save(org as Organization);
            if (newOrgId) {
                setCurrentOrganizationId(newOrgId);
            }
            navigate(Pages.ViewFacilityGroups.getUrl());
        }
        );
    }

    return (
        <DashboardLayout centerChildren={"Create Organization"}>
            {!(currentOrganizationId) &&
                <p className="font-semibold">It appears that you are not part of any organization.  Please add an organization below, or contact a manager of your organization.</p>
            }
            <div className="grid grid-cols-2 p-3 gap-5">
                <div className="col-span-full">
                    <Input label="Organization Name" {...orgHelpers.bindingsFor("name")} />
                </div>

                <Input label="Address Line 1" {...orgHelpers.bindingsFor("address")} />
                <Input label="Address Line 2" {...orgHelpers.bindingsFor("address2")} />
                <Input label="City" {...orgHelpers.bindingsFor("city")} />
                <div className="col-start-1 flex col-span-full place-content-end">
                    <Button onClick={handleSaveOnClick}>Save</Button>
                </div>
            </div>

        </DashboardLayout>
    );
}