export interface IOrientationInfo {
	id: string | undefined;

	error: number;

	accelX: number;

	accelY: number;

	accelZ: number;

	pitch: number;

	roll: number;

	$type?: string | undefined;
}
export class OrientationInfo {
	constructor(data?: IOrientationInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	error: number = 0;

	accelX: number = 0;

	accelY: number = 0;

	accelZ: number = 0;

	pitch: number = 0;

	roll: number = 0;

	static $type: string = "CoreApi.Models.Hardware.OrientationInfo, ";
	$type?: string | undefined = OrientationInfo.$type;
}

