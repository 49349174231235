
import {
    DeviceConfigCommand, DiagnosticCommand, FactoryResetCommand,
    FirmwareUpdateCommand, IDeviceConfigCommand, IDiagnosticCommand,
    IFactoryResetCommand, IFirmwareUpdateCommand, ILogRequestCommand,
    IRebootCommand, IRecordDataCommand, ISensorStreamDataCommand,
    ISensorUpdateCommand, IStreamPortCommand, IStreamVideoCommand,
    LogRequestCommand, RebootCommand,
    RecordDataCommand, SensorStreamDataCommand, SensorUpdateCommand,
    StreamPortCommand, StreamVideoCommand
} from "@app/shared";
import { ChangeEventHandler } from "react";
import { InputRow, Select } from "../inputs";
import { DeviceCommandEditor, DeviceCommandTypes } from "./DeviceCommandEditor";

type EditDeviceCommandProps = {
    deviceId: string;
    command: DeviceCommandTypes | undefined;
    onChange: (command: DeviceCommandTypes | undefined) => any;
};

export const EditDeviceCommand: React.FC<EditDeviceCommandProps> = ({ deviceId, command, onChange }) => {

    const selectOptions = [
        { label: "--Select Command--", value: "" },
        { label: "Device Config", value: DeviceConfigCommand.$type },
        { label: "Diagnostic", value: DiagnosticCommand.$type },
        { label: "Factory Reset", value: FactoryResetCommand.$type },
        { label: "Firmware Update", value: FirmwareUpdateCommand.$type },
        { label: "Log Request", value: LogRequestCommand.$type },
        { label: "Reboot", value: RebootCommand.$type },
        { label: "Record Data", value: RecordDataCommand.$type },
        { label: "Sensor Stream Data", value: SensorStreamDataCommand.$type },
        { label: "Sensor Update", value: SensorUpdateCommand.$type },
        { label: "Stream Port", value: StreamPortCommand.$type },
        { label: "Stream Video", value: StreamVideoCommand.$type },
    ];

    const handleTypeChanged: ChangeEventHandler<HTMLSelectElement> = (e) => {
        //New up the device command they selected
        switch (e.target.value) {
            case DeviceConfigCommand.$type:
                onChange(new DeviceConfigCommand({ deviceId: deviceId } as IDeviceConfigCommand));
                break;
            case DiagnosticCommand.$type:
                onChange(new DiagnosticCommand({ deviceId: deviceId } as IDiagnosticCommand));
                break;
            case FactoryResetCommand.$type:
                onChange(new FactoryResetCommand({ deviceId: deviceId } as IFactoryResetCommand));
                break;
            case FirmwareUpdateCommand.$type:
                onChange(new FirmwareUpdateCommand({ deviceId: deviceId } as IFirmwareUpdateCommand));
                break;
            case LogRequestCommand.$type:
                onChange(new LogRequestCommand({ deviceId: deviceId } as ILogRequestCommand));
                break;
            case RebootCommand.$type:
                onChange(new RebootCommand({ deviceId: deviceId } as IRebootCommand));
                break;
            case RecordDataCommand.$type:
                onChange(new RecordDataCommand({ deviceId: deviceId } as IRecordDataCommand));
                break;
            case SensorStreamDataCommand.$type:
                onChange(new SensorStreamDataCommand({ deviceId: deviceId } as ISensorStreamDataCommand));
                break;
            case SensorUpdateCommand.$type:
                onChange(new SensorUpdateCommand({ deviceId: deviceId } as ISensorUpdateCommand));
                break;
            case StreamPortCommand.$type:
                onChange(new StreamPortCommand({ deviceId: deviceId } as IStreamPortCommand));
                break;
            case StreamVideoCommand.$type:
                onChange(new StreamVideoCommand({ deviceId: deviceId } as IStreamVideoCommand));
                break;
            default:
                onChange(undefined);
        }
    };

    return (
        <>
            <InputRow>
                <Select label="Command Type" options={selectOptions} onChange={handleTypeChanged} value={command?.$type} />
            </InputRow>
            <DeviceCommandEditor command={command} onChange={onChange} />
        </>
    );
}