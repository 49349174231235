import {
  buildMessage,
  equals,
  ValidateBy,
  ValidationOptions,
} from "class-validator";

export function Compare<T>(property: (keyof T), validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy({
    name: 'compare',
    constraints: [property],
    validator: {
      validate: (value, args) => {
        const object = args?.object as any;
        const compareToValue = object[property];

        return equals(value, compareToValue);
      },
      defaultMessage: buildMessage(
        eachPrefix => eachPrefix + '$property should equal $constraint1',
        validationOptions
      ),
    }
  });
}