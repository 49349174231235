export enum FHWAClass {
	Class1 = 1,
	Class2 = 2,
	Class3 = 3,
	Class4 = 4,
	Class5 = 5,
	Class6 = 6,
	Class7 = 7,
	Class8 = 8,
	Class9 = 9,
	Class10 = 10,
	Class11 = 11,
	Class12 = 12,
	Class13 = 13,
	Class15Unknown = 15,
}

export const FHWAClassDisplayNames: { [key in keyof typeof FHWAClass]?: string} = {
	"Class1": "Class 1",
	1 : "Class 1",
	"Class2": "Class 2",
	2 : "Class 2",
	"Class3": "Class 3",
	3 : "Class 3",
	"Class4": "Class 4",
	4 : "Class 4",
	"Class5": "Class 5",
	5 : "Class 5",
	"Class6": "Class 6",
	6 : "Class 6",
	"Class7": "Class 7",
	7 : "Class 7",
	"Class8": "Class 8",
	8 : "Class 8",
	"Class9": "Class 9",
	9 : "Class 9",
	"Class10": "Class 10",
	10 : "Class 10",
	"Class11": "Class 11",
	11 : "Class 11",
	"Class12": "Class 12",
	12 : "Class 12",
	"Class13": "Class 13",
	13 : "Class 13",
	"Class15Unknown": "Unknown",
	15 : "Unknown",
}

export const getFHWAClassDisplayName = (key: FHWAClass | (keyof typeof FHWAClass & string)) => {
	return FHWAClassDisplayNames[key];
}

