import { RadarConfiguration, SensorConfigType, SensorConfiguration } from "@app/shared";
import { useModel } from "../../hooks";
import { HeaderWithSeparator } from "./HeaderWithSeparator";
import { CheckboxInput, Input, InputRow, TextArea } from "./inputs";

type EditSensorConfigProps = {
    config: SensorConfiguration | RadarConfiguration;
    onChange: (newVal: Partial<SensorConfiguration> | Partial<RadarConfiguration>) => void;
};

export const EditSensorConfig: React.FC<EditSensorConfigProps> = ({ config, onChange }) => {
    const [radarConfig, radarHelpers] = useModel(RadarConfiguration, config);

    return (
        <>
            {config.configType === SensorConfigType.Radar &&
                <>
                    <HeaderWithSeparator>Radar Configuration</HeaderWithSeparator>
                    <InputRow><CheckboxInput label="Enable Transmitter" checked={radarConfig.transmitterEnabled} onChange={radarHelpers.handlerFor("transmitterEnabled", onChange)} /></InputRow>
                    <InputRow><Input label="Seconds to Transmit per Minute" {...radarHelpers.bindingsFor("secondsToTransmitPerMinute", onChange)} /></InputRow>
                    <InputRow><Input label="Minimum Seconds to Transmit" {...radarHelpers.bindingsFor("minimumSecondsToTransmit", onChange)} /></InputRow>
                    <InputRow><Input label="Calibration" {...radarHelpers.bindingsFor("calibrationConfig", onChange)} /></InputRow>
                    <InputRow><TextArea rows={10} label="CHIRP Config" {...radarHelpers.bindingsFor("chirpConfig", onChange)} /></InputRow>
                </>
            }
        </>
    );
}