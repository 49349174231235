import { Guid, IGuid } from "./System.Guid.gen";

export interface IEventTriggerFacility {
	eventTriggerId: string | undefined;

	facilityId: string | undefined;

	$type?: string | undefined;
}
export class EventTriggerFacility {
	constructor(data?: IEventTriggerFacility) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	eventTriggerId: string | undefined;

	facilityId: string | undefined;

	static $type: string = "CoreApi.Models.EventTriggers.EventTriggerFacility, ";
	$type?: string | undefined = EventTriggerFacility.$type;
}

