import { useState } from "react";
import { Input, InputProps } from ".";
import { Icons } from "../..";
import { useDelayedAction } from "../../../hooks";

type SearchInputProps = {
    onSearch: (newText: string) => any | void,
    initialSearch?: string | null,
    delay?: number
} & Omit<InputProps, "value">;

export const SearchInput: React.FC<SearchInputProps> = (props) => {
    const { onChange, placeholder = "Search", delay = 500 } = props;
    const [searchText, setSearchText] = useState<string>(props.initialSearch ?? "");

    const performSearch = useDelayedAction(() => { },
        () => {
            //Do not perform a search until they have entered something into the box!
            if (searchText === undefined)
                return;
            props.onSearch(searchText);
        },
        [searchText],
        delay);

    const myOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (onChange)
            onChange(e);
    }

    const handleSearchKeyPress: React.KeyboardEventHandler<HTMLInputElement> = async (keyEvent) => {
        if (keyEvent.key === "Escape") {
            setSearchText("");
            keyEvent.stopPropagation();
        } else if (keyEvent.key === "Enter") {
            await performSearch();
            keyEvent.stopPropagation();
        }
    }

    return (
        <label className="relative float-right">
            {(searchText && searchText.length > 0) &&
                <span onClick={() => { setSearchText(""); }}>
                    <Icons.Close className="absolute right-3 inline-block top-3 cursor-pointer z-10" />
                </span>
            }
            {(!!!searchText || searchText.length < 1) &&
                <span onClick={async () => await performSearch()}>
                    <Icons.Search className={"absolute right-3 inline-block top-3 z-10 " + ((searchText && searchText.length) ? "cursor-pointer" : "")} />
                </span>
            }
            <Input className="w-96 mr-0 " style={{ paddingLeft: "2em", paddingRight: "2em" }}
                onKeyDown={handleSearchKeyPress}
                placeholder={placeholder}
                value={searchText ?? ""}
                onChange={myOnChange} />
        </label>

    );
}