import { CpuUsageInfo } from "@app/shared";
import { useEffect, useState } from "react";
import { HardwareStatusChart, HardwareStatusChartProps, SeriesDetails } from "./HardwareStatusChart";

type CpuUtilizationChartProps = Omit<HardwareStatusChartProps<CpuUsageInfo>, "series">;

const dataAccessors = (index?: number) => {
    return {
        date: (data?: CpuUsageInfo) => data?.statusTimestamp,
        xAccessor: (data?: CpuUsageInfo) => data?.statusTimestamp,
        yAccessor: (data?: CpuUsageInfo) => {
            if (!data?.coreUsages || data?.coreUsages.length <= 0)
                return undefined;

            if (index !== undefined) {
                return data.coreUsages[index];
            }
            const sum = (data?.coreUsages.reduce((partialSum, val) => partialSum + val, 0) ?? undefined);
            if (sum === undefined) return 0;
            return (sum / data!.numberOfCores);
        }
    };
}

const charts: SeriesDetails<CpuUsageInfo>[] = [
    {
        dataKey: "CPU 1",
        accessors: dataAccessors(0),
        opacity: .2
    },
    {
        dataKey: "CPU 2",
        accessors: dataAccessors(1),
        opacity: .2
    },
    {
        dataKey: "CPU 3",
        accessors: dataAccessors(2),
        opacity: .2
    },
    {
        dataKey: "CPU 4",
        accessors: dataAccessors(3),
        opacity: .2
    },
    {
        dataKey: "AVG",
        accessors: dataAccessors(),
    }
];

export function CpuUtilizationChart(props: CpuUtilizationChartProps) {
    const [numberCores, setNumberCores] = useState(1);
    useEffect(() => {
        if (!props.data)
            return;
        for (var i = 0; i < props.data.length; i++) {
            if (props.data[i].numberOfCores && props.data[i].coreUsages) {
                setNumberCores(props.data[i].numberOfCores);
                return;
            }
        }
    }, [props.data])
    return <HardwareStatusChart series={[...charts.slice(0, numberCores), ...charts.slice(-1)]} yUnit="%" {...props} />;
}