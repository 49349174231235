import cn from 'classnames';
import { FC, HTMLProps } from "react";

export const InputRow: FC<HTMLProps<HTMLDivElement> & { addlClasses?: string }> = (props) => {
    const { className, addlClasses, ...otherProps } = props;
    return (
        <div className={cn("mx-auto w-9/10 md:w-8/10 lg:w-7/10 m-1", addlClasses)} {...otherProps}>
            {otherProps.children}
        </div>
    );
}
