import { useState } from "react";
import { Icons } from "./Icons";
import { SearchInput } from "./inputs";

type HiddenSearchBoxProps = {
    onSearchChanged: (search: string) => any;
    iconClassNames?: string;
}
export const HiddenSearchBox: React.FC<HiddenSearchBoxProps> = ({ iconClassNames = "w-5 h-5", ...props }) => {
    const [hidden, setHidden] = useState(true);

    return (
        <>
            {hidden &&
                <Icons.Search className={iconClassNames} onClick={() => setHidden(curr => !curr)} />
            }
            {!hidden &&
                <SearchInput onSearch={props.onSearchChanged} />
            }
        </>
    );
}