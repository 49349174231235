import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";

export type LinkProps = {
    onClick: () => void | any
} & Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "onClick">;

export const Link: FunctionComponent<LinkProps> = (props) => {
    const { children, className, ...rest } = props;

    return (
        <div {...rest} onClick={props.onClick} className={"cursor-pointer text-link-page hover:text-link-page-hover inline-flex h-full " + className}>
            {children}
        </div>
    );
}