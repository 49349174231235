import { Device, Vector2 } from "../models";

declare module "../models/Device.gen" {
    interface Device {
        clampPointIfClosing(point: Vector2, threshold: number): Vector2;
        isComplete(): boolean;
    }
}
Device.prototype.isComplete = function () {
    return this.desiredTiltDegrees !== 0 && this.height !== 0;
}

Device.prototype.clampPointIfClosing = function (point: Vector2, threshold: number) {
    return point;
}