import { Facilities, Facility } from "@app/shared";
import { FC, MouseEventHandler } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Pages } from ".";
import { toastError, toastSuccess } from "../../helpers";
import { useFetch } from "../../hooks";
import { useEditModelDialog } from "../../hooks/useEditModelDialog";
import { DashboardLayout } from "../layouts";
import { EditFacilityDetailsInputs, FacilityGroupDropdown, IconLink, Icons, SortableTable } from "../shared";
import { DropDownMenu } from "../shared/DropDownMenu";

export const ViewFacilityGroup: FC = (props) => {
    const { id } = useParams();
    const [facilities, , fetchHelpers] = useFetch(() => Facilities.getByGroupId(id), [id]);
    const facilityDialog = useEditModelDialog(Facility);
    const navigate = useNavigate();

    const handleAddOnClick: MouseEventHandler = () => {
        facilityDialog.show(async (newFacility) => {
            var newId = await Facilities.save(newFacility as Facility);
            if (newId) {
                navigate(Pages.FacilityDetails.getUrl({ id: newId }));
            }

        }, "Add New Location", { facilityGroupId: id } as Partial<Facility>);
    };

    const handleDeleteOnClick = async (fg: Facility) => {
        try {
            await Facilities.deleteFacility(fg.id);
            toastSuccess("Deleted", "Location has been deleted");
            fetchHelpers.refreshData();
        } catch (e) {
            toastError("Unable to delete", e + "");
        }

    };

    return (
        <DashboardLayout
            centerChildren="Locations"
            leftChildren={
                <FacilityGroupDropdown
                    currentGroupId={id!}
                    onSelected={newId => {
                        navigate(Pages.ViewFacilityGroup.getUrl({ id: newId }))
                    }}
                />
            }>
            <>
                <SortableTable
                    isLoading={fetchHelpers.isLoading}
                    emptyValue={
                        <div className="py-5">
                            <p className="py-3">
                                No locations were found.
                            </p>
                            <IconLink Icon={Icons.PlusCircle} iconSize={30} onClick={handleAddOnClick}>Add New Location</IconLink>
                        </div>
                    }
                    columns={[
                        {
                            header: "Name",
                            dataKey: "name",
                            width: 250
                        },
                        {
                            header: "Sensors",
                            dataKey: "deviceCount",
                            width: 150
                        },
                        {
                            header: <IconLink iconSize={20} Icon={Icons.PlusCircle} onClick={handleAddOnClick} />,
                            width: 10,
                            renderFunc: f => (<DropDownMenu>

                                <IconLink Icon={Icons.Trash} onClick={() => handleDeleteOnClick(f!)}>Delete</IconLink>
                            </DropDownMenu>)
                        }

                    ]}
                    onRowClick={f => {
                        navigate(Pages.FacilityDetails.getUrl({ id: f.id! }))
                    }}
                    data={facilities}
                />
                {facilityDialog.renderDialog((model, helpers) => (
                    <EditFacilityDetailsInputs model={model} helpers={helpers} />
                ))}
            </>
        </DashboardLayout>
    );
}