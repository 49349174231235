import { DetectionZone } from "@app/shared";
import { Input, InputRow } from "../../inputs";

type DetectionZoneConfigProps = {
    currentZone: DetectionZone;
    onChange: (zone: DetectionZone) => void;
    isEditable?: boolean;
    isCollapsed: boolean;
};

export const DetectionZoneConfig: React.FC<DetectionZoneConfigProps> = ({ currentZone, onChange, isCollapsed, isEditable = false }) => {
    const handleOnChange = (key: keyof DetectionZone, value: DetectionZone[keyof DetectionZone]) => {
        onChange(new DetectionZone({ ...currentZone, [key]: value }));
    }
    if (isCollapsed) { return null; }
    return (
        <>
            <InputRow label="Detection Zone Type">
                <Input onChange={(e) => handleOnChange("name", e.target.value)} label="Name" value={currentZone.name} disabled={!isEditable} />
            </InputRow>

        </>
    );
}
