import { formatInTimeZone, toDate } from "date-fns-tz";
import { FocusEvent, FunctionComponent, KeyboardEvent, useEffect, useRef, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconType } from "react-icons";
import { Icons, InputLabel } from "..";
import { InputProps } from "./inputTypes";
import { ValidatedInputGroup } from "./ValidatedInputGroup";


export type DateInputProps = Omit<InputProps<ReactDatePickerProps>, "onChange" | "dateFormat"> &
{
    ignoreStartDate?: boolean;
    Icon?: IconType;
    dateFormat?: string;
    displayTimezone?: string;
    onChange: (date: Date | null) => void;
}

const getTimeIfIsOnlyTime = (val?: string) => {
    if (!val)
        return undefined;

    const timeOnlyRegex = new RegExp('^[\\s]*([\\d]{1,2}:[\\d]{2})[\\s]*(PM|AM|P|A|pm|am|p|a){0,1}[\\s]*$');
    const time = (val ?? "").match(timeOnlyRegex);
    if (time)
        return time[0];
    return undefined;
}

export const DateInput: FunctionComponent<DateInputProps> = (props) => {
    const {
        ignoreStartDate,
        fieldName,
        validationError,
        validateModel,
        value,
        showTimeInput = true,
        timeInputLabel = "Time:",
        dateFormat = "MM/dd/yyyy h:mm aa",
        className = "w-full",
        shouldCloseOnSelect = false,
        startDate: startDateIn,
        preventOpenOnFocus = true,
        displayTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
        onChange,
        Icon = props.Icon ?? Icons.Calendar,
        ...inputProps
    } = props;

    const datePicker = useRef<DatePicker>(null);
    const [open, setOpen] = useState(false);
    const [textValue, setTextValue] = useState<string>();
    const [dateValue, setDateValue] = useState<Date | null>(null);
    const zone = props.displayTimezone ?? Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
    // const startDate = startDateIn && displayTimezone ? dateInTimezone(startDateIn, displayTimezone) : startDateIn;

    useEffect(() => {
        if (!value) {
            setTextValue(undefined);
            setDateValue(null);
            return;
        }

        const newDate = toDate(value, { timeZone: zone });
        setTextValue(newDate ? formatInTimeZone(newDate, zone, dateFormat) : undefined);
        setDateValue(newDate ? newDate : null);
    }, [value, dateFormat, displayTimezone, zone]);

    const onChangeRawHandler = (e: FocusEvent<HTMLInputElement>) => {
        setTextValue(e.target.value);
    }

    const onChangeHandler = (value: Date | [Date, Date] | null) => {
        if (value === null || value instanceof Date) {
            setDateValue(value);
        }
    };
    const handleOnBlur = () => {
        setOpen(false);
        const time = getTimeIfIsOnlyTime(textValue);
        if (time || props.showTimeSelectOnly) {
            const startDateOrNow = startDateIn ?? toDate(new Date(), { timeZone: zone });
            var date = toDate(startDateOrNow!.toDateString() + " " + (time ?? ""), { timeZone: zone });
            if (!time) {
                setTextValue(formatInTimeZone(date, zone, dateFormat));
            }
            setDateValue(date);
            callParentOnChange(date)
            return;
        }
        callParentOnChange(dateValue);
    }
    const callParentOnChange = (newDateValue: Date | null, force?: boolean) => {
        if (!force) {
            if (((textValue || value) && textValue === value) ||
                (value && textValue === formatInTimeZone(new Date(value), displayTimezone, dateFormat))) {
                return;
            }
        }
        //send date to parent        
        onChange(newDateValue);

    }
    const handleCalendarClick = () => {
        setOpen(true);

        if (!dateValue) {
            const startDateOrNow = startDateIn ?? toDate(new Date(), { timeZone: zone });
            setDateValue(startDateOrNow);
            callParentOnChange(startDateOrNow);
        }
    }
    const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        //Enter will bring it to the start date
        if (e.key === "Enter" && !ignoreStartDate) {
            e.preventDefault();
            const startDateOrNow = startDateIn ?? toDate(new Date(), { timeZone: zone });
            setDateValue(startDateOrNow);
            callParentOnChange(startDateOrNow, true);
            // This would be nice to jump to the next box, but the blur handler is sending bad data because we blur before the above values are set on the state
            // findNextTabStop(e.currentTarget).focus();
        }
    }

    const handleClickOutside = () => {
        if (props.showTimeSelectOnly)
            handleOnBlur();
        else {
            setOpen(false);
        }
    }

    return (
        <ValidatedInputGroup fieldName={fieldName} validate={validateModel} validationError={validationError}>
            {({ label, handleBlur }) =>
                <>
                    {props.label && <InputLabel>{props.label}</InputLabel>}
                    <div className="relative">
                        <DatePicker
                            popperProps={{ positionFixed: true }}
                            portalId='date-picker-wrapper'
                            ref={datePicker}
                            wrapperClassName={className}
                            selected={dateValue}
                            className={"pr-10 omnisight " + className}
                            popperClassName="z-100"
                            placeholderText={label}
                            onBlur={(e) => { handleBlur(); handleOnBlur(); }}
                            shouldCloseOnSelect={shouldCloseOnSelect}
                            onChange={onChangeHandler}
                            onKeyDown={handleOnKeyDown}
                            onChangeRaw={onChangeRawHandler}
                            selectsRange={false}
                            onClickOutside={handleClickOutside}
                            showTimeInput={showTimeInput}
                            timeInputLabel={timeInputLabel}
                            dateFormat={dateFormat}
                            onSelect={(e) => { onChangeHandler(e); onChange(toDate(e, { timeZone: zone })); setOpen(false) }}
                            preventOpenOnFocus={preventOpenOnFocus}
                            open={open}
                            value={textValue}
                            {...inputProps}
                        />
                        <span className="absolute right-3 inline-block bottom-4 cursor-pointer">
                            <Icon onClick={handleCalendarClick} />
                        </span>
                    </div>
                </>
            }
        </ValidatedInputGroup>
    );
}