/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post, del } from "./api_client";
import { EventTrigger, IEventTrigger } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getByFacilityId(orgId?: string, facilityId?: string): ApiResult<EventTrigger[]> {
		const queryParams = [orgId !== undefined ? `orgId=${orgId}` : undefined,
			facilityId !== undefined ? `facilityId=${facilityId}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IEventTrigger[]>(`/api/eventtrigger/for-facility${query}`).then(result => result === undefined ? undefined : result.map(o => new EventTrigger(o)));
}

export async function update(endPoint: EventTrigger): ApiResult<string> {
	return post<string>(`/api/eventtrigger`, endPoint);
}

export async function deleteTrigger(id?: string): ApiResult<undefined> {
	return del(`/api/eventtrigger/${ id }`);
}
