import { AggregationLevel, CustomClaimValue, Facility, Reports, TrafficReportConfiguration } from "@app/shared";
import { addDays } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useFetch } from "../../../hooks";
import { Icons, LoadingIndicator, PageContainer, Select } from "../../shared";
import { FacilityEventsChart } from "../../shared/charts";
import { HoverTooltip } from "../../shared/HoverTooltip";
import { AuthorizeView } from "../../shared/AuthorizeView";
import { AllTargetTypes, groupBy, toMPH } from "../../reports/reportHelpers";

type FacilityTargetsDashboardProps = {
    facility: Facility;
};
export const FacilityTargetsDashboard: React.FC<FacilityTargetsDashboardProps> = ({ facility }) => {
    const [chartDeviceId, setChartDeviceId] = useState<string | undefined>(!!(facility.devices?.length) ? facility.devices![0].id! : undefined);
    const [recentTargets, errors, recentTargetHelpers] = useFetch(() => chartDeviceId ? Reports.getTotalVolume(AggregationLevel.Day, { deviceIds: [chartDeviceId], inclusiveStart: addDays(new Date(), -14), exclusiveEnd: new Date(), targetTypes: AllTargetTypes } as TrafficReportConfiguration) : Promise.resolve(undefined), [chartDeviceId]);

    const targetEvents = useMemo(() => {
        if (!(recentTargets && !errors && !recentTargetHelpers.isLoading && recentTargets.length))
            return;
        const rounded = recentTargets?.filter(d => d.count > 0).map(d => ({ date: d.periodStart, count: d.count, percentage: 0, tt: d.targetType, mph: Math.round(toMPH(d.avgVelocity!)) }));
        const byDate = groupBy(rounded!, 'date');
        const result = [];
        for (let key in byDate) {
            const dayStats = byDate[key].map(x => ({ count: x.count, tt: x.tt, speed: x.mph }));
            const ttCountDic = {} as { [key: string]: number };
            const ttSpeedDic = {} as { [key: string]: number };
            const byTT = groupBy(dayStats, 'tt');
            for (let tt in byTT) {
                ttCountDic[tt] = byTT[tt].reduce((acc, cv) => acc + cv.count, 0) / byTT[tt].length;
                ttSpeedDic[tt] = byTT[tt].reduce((acc, cv) => acc + cv.speed, 0) / byTT[tt].length;
            }
            result.push({
                date: new Date(key),
                counts: ttCountDic,
                deviceId: chartDeviceId,
                speeds: ttSpeedDic
            });
        };
        return result;
    },
        [recentTargets, recentTargetHelpers.isLoading, errors, chartDeviceId]);
    //When our facility changes, load the recent targets for the first device
    useEffect(() => {
        if (!chartDeviceId && (facility.devices?.length ?? 0) > 0) {
            setChartDeviceId(facility.devices![0].id);
        }
    }, [facility, chartDeviceId]);

    return (

        <PageContainer title="Recent Targets" titleRight={
            <Select options={facility.devices?.map(d => ({ label: d.name, value: d.id }))} value={chartDeviceId} onChange={e => setChartDeviceId(e.target.value)} />
        }>
            {recentTargetHelpers.isLoading && <LoadingIndicator isLoading={recentTargetHelpers.isLoading} />
            }
            {(recentTargets && !errors && !recentTargetHelpers.isLoading && recentTargets.length > 0) &&
                <FacilityEventsChart data={targetEvents} facility={facility} device={facility.devices?.find(x => x.id === chartDeviceId)!} />
            }
            {(recentTargets && !errors && !recentTargetHelpers.isLoading && recentTargets.length === 0) &&
                <div className="flex justify-center ">
                    <p className="text-1xl brand-primary-lightened">No recent targets</p>
                </div>
            }

            {errors && !recentTargetHelpers.isLoading &&
                <HoverTooltip placement="auto" hoverable={
                    <p className="text-error-icon flex gap-3 place-items-center">
                        <Icons.Warning /> There was an error loading recent targets for this device.
                    </p>
                }>
                    <AuthorizeView claim={CustomClaimValue.SuperAdmin}><span>{errors}</span></AuthorizeView>
                </HoverTooltip>
            }
        </PageContainer>

    );
}