import { SensorUpdateCommand, SensorUpdateCommandParams } from "@app/shared";
import { useModel } from "../../../hooks";
import { CheckboxInput, Input, InputRow } from "../inputs";
import { DeviceCommandEditorProps } from "./DeviceCommandEditor";

type EditSensorUpdateCommandProps = {
} & DeviceCommandEditorProps;

export const EditSensorUpdateCommand: React.FC<EditSensorUpdateCommandProps> = (props) => {
    const [params, paramsHelper] = useModel(SensorUpdateCommandParams, (props.command as SensorUpdateCommand).commandParamsJson);
    const onChange = (newParams: Partial<SensorUpdateCommandParams>) => {
        props.onChange({ ...props.command, commandParamsJson: newParams } as SensorUpdateCommand);
    };
    return (
        <>
            <InputRow><Input label="Firmware Version" {...paramsHelper.bindingsFor("v", onChange)} /></InputRow>
            <InputRow><Input label="Firmware Url" {...paramsHelper.bindingsFor("url", onChange)} /></InputRow>
            <InputRow><Input label="Firmware Hash" {...paramsHelper.bindingsFor("hash", onChange)} /></InputRow>
            <InputRow><CheckboxInput label="Force Update" checked={params.force} onChange={(e) => paramsHelper.updateModel("force", e.target.checked)} /></InputRow>
        </>
    );
}