import { ApiResult, Facilities, Facility } from "@app/shared";
import { toastWarning } from "../helpers";
import { useFetch } from "./useFetch";
import { useState, useEffect } from 'react';
import { useSessionStorage } from "react-use";

export function useFacilityTZ(facility: string | Facility, warnOnUTC: boolean = false): [facilityTimezone: string | undefined, error: string, isLoading: boolean] {

    const [fetchedFacility, isLoading] = useFetch(() => { return typeof (facility) === 'object' ? ApiResult.resolve(facility) : Facilities.getById(facility) }, [facility]);
    const [hasBeenWarned, setHasBeenWarned] = useSessionStorage('reports_utc_warning_shown', false);
    const [facilityTZ, setFacilityTZ] = useState(undefined as string | undefined)

    useEffect(() => {
        if (isLoading || !!!fetchedFacility) return;
        if (fetchedFacility.localTimezoneId === 'UTC' && !hasBeenWarned) {
            setHasBeenWarned(true);
            toastWarning('Default Timezone', `Reports are shown in location Timezone which is currently set to ${fetchedFacility.localTimezoneId}`);
        }
        setFacilityTZ(fetchedFacility.localTimezoneId);
    }, [isLoading, fetchedFacility, setHasBeenWarned, hasBeenWarned]);
    return [facilityTZ, 'error', !!isLoading?.length];
}