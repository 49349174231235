import { AggregationLevel, Reports, TotalVolumeByPeriod, TrafficReportConfiguration } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { TotalVolumeChart } from "./TotalVolumeDataLoader";
import { useContext } from "react";
import { ReportContext } from "./reportHelpers";
import { formatInTimeZone } from "date-fns-tz";
import { useFetch } from "../../hooks";

export const BusiestDayReportLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => Reports.busiestDay(searchParams), [searchParams]);    
    return (<TotalVolumeChart
        {...props}
        aggregationLevel={AggregationLevel.Hour}
        data={data}
        startDate={searchParams.inclusiveStart}
        endDate={searchParams.exclusiveEnd}
        groupByTargetType={reportConfig.groupByTargetType}
        chartTimezone={reportConfig.chartTimezone}
        chartType={reportConfig.chartType as 'bar' | 'line'}
        title={d => 'Total Volume ' + formatInTimeZone(data?.[0].periodStart!, reportConfig.chartTimezone, 'MMMM d yyyy')}
    />);
}

export const BusiestDayReport = {
    name: "Busiest Day Report",
    description: "This report shows the volume of the selected traffic types on the busiest day in the selected range",
    component: BusiestDayReportLoader,
    defaultChartType: 'bar',
    defaultRange: DateOptions.ThisYear,
    key: 'bdr',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true
    }
} as IConfiguredTrafficReport;