import { Guid, IGuid } from "./System.Guid.gen";
import { Vector2, IVector2 } from "./System.Numerics.Vector2.gen";

export interface ILine {
	id: string | undefined;

	name: string | undefined;

	points: IVector2[] | undefined;

	$type?: string | undefined;
}
export class Line {
	constructor(data?: ILine) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.points = data.points ? data.points.map((p: any) => new Vector2(p)) : [];
	}

	id: string | undefined;

	name: string | undefined;

	points: Vector2[] | undefined = [];

	static $type: string = "Shared.Geometry.Line, ";
	$type?: string | undefined = Line.$type;
}

