/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post, del } from "./api_client";
import { Organization, IOrganization, OptionValue, IOptionValue } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getAll(): ApiResult<Organization[]> {
	return get<IOrganization[]>(`/api/organization`).then(result => result === undefined ? undefined : result.map(o => new Organization(o)));
}

export async function getById(id?: string): ApiResult<Organization> {
	return get<IOrganization>(`/api/organization/${ id }`).then(result => result === undefined ? undefined :  new Organization(result));
}

export async function getAllOptions(): ApiResult<OptionValue[]> {
	return get<IOptionValue[]>(`/api/organization/options`).then(result => result === undefined ? undefined : result.map(o => new OptionValue(o)));
}

export async function save(org: Organization): ApiResult<string> {
	return post<string>(`/api/organization`, org);
}

export async function removeUser(orgId?: string, userId?: string): ApiResult<undefined> {
	return del(`/api/organization/${ orgId }/users/${ userId }`);
}
