import { CustomClaimValue } from "@app/shared";
import { PropsWithChildren } from "react";
import { useHasClaim } from "../../hooks";

type AuthorizeViewProps = {
    claim: CustomClaimValue,
    facilityId?: string,
    organizationId?: string
};

export const AuthorizeView: React.FC<PropsWithChildren<AuthorizeViewProps>> = ({ claim, facilityId, organizationId, ...props }) => {
    const hasPermission = useHasClaim(claim, organizationId, facilityId);

    return (
        <>
            {hasPermission &&
                props.children
            }
        </>
    );
}