import { TimeZones } from "@app/shared";
import { FC, SelectHTMLAttributes } from "react";
import { useFetch } from "../../../hooks";
import { InputProps } from "./inputTypes";
import { Select } from "./Select";

type SelectTimezoneProps = {
    emptyOptionText?: string,
    emptyOptionDisabled?: boolean
} & InputProps<SelectHTMLAttributes<HTMLSelectElement>>;

export const SelectTimezone: FC<Omit<SelectTimezoneProps, "options" | "isLoadingOptions" | "children">> = (props) => {
    const { emptyOptionText = "Select Timezone", emptyOptionDisabled = true, ...otherProps } = props;
    const [timezones, , timezoneHelpers] = useFetch(TimeZones.getTimezoneOptions);

    return (
        <Select {...otherProps} options={timezones} isLoadingOptions={timezoneHelpers.isLoading} emptyOptionText={emptyOptionText} emptyOptionDisabled={emptyOptionDisabled} />
    );
}
