import { Facility } from "@app/shared";
import { LayoutDesigner } from "../../shared/LayoutDesigner/LayoutDesigner";

type FacilityLayoutInfoProps = {
    facility: Facility;
    onChange: () => void;
};
export const FacilityLayoutInfo: React.FC<FacilityLayoutInfoProps> = ({ facility, onChange }) => {
    return (
        <>
            <LayoutDesigner allowEdit={true} facility={facility} onChange={onChange} hideWarning={facility.originReferencePointLatLng?.origin?.x !== 0 || (!facility.backgroundImageSource?.length)} />
        </>
    );
}