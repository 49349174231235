import { DateOptions, IConfiguredTrafficReport } from "./IConfiguredTrafficReport";
import { AggregationLevel } from "@app/shared";
import { GetFacilityUsageDataLoader } from "./FacilityUsageDataLoader";
import { formatInTimeZone } from "date-fns-tz";

function getMonthName(timezone:string, periodStart: Date, locale = 'en-US') {
    return formatInTimeZone(periodStart, timezone, 'MMMM');
}

export const FacilityUsageByMonth = {
    name: "by Month",
    description: "This report shows the total Inbound/Outbound Targets in the Location by month for the specified range ",
    component: GetFacilityUsageDataLoader(AggregationLevel.Month, getMonthName),
    defaultChartType: 'bar',
    defaultRange: DateOptions.ThisYear,
    key: 'fum',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true,
        disabled: false,
        disallowChartTypeToggling: true
    }
} as IConfiguredTrafficReport;