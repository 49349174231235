/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post, del } from "./api_client";
import { FirmwareType, FirmwareVersionType } from "../models";
import { Firmware, IFirmware, FirmwareDeviceCount, IFirmwareDeviceCount } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getAvailable(firmwareType: FirmwareType): ApiResult<Firmware[]> {
		const queryParams = [firmwareType !== undefined ? `firmwareType=${firmwareType}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IFirmware[]>(`/api/firmware/available${query}`).then(result => result === undefined ? undefined : result.map(o => new Firmware(o)));
}

export async function getAll(firmwareType: FirmwareType): ApiResult<Firmware[]> {
		const queryParams = [firmwareType !== undefined ? `firmwareType=${firmwareType}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IFirmware[]>(`/api/firmware/${query}`).then(result => result === undefined ? undefined : result.map(o => new Firmware(o)));
}

export async function getFirmwareDeviceCount(): ApiResult<FirmwareDeviceCount[]> {
	return get<IFirmwareDeviceCount[]>(`/api/firmware/firmware-device-count`).then(result => result === undefined ? undefined : result.map(o => new FirmwareDeviceCount(o)));
}

export async function deleteFirmware(id?: string): ApiResult<undefined> {
	return del(`/api/firmware/${ id }`);
}

export async function save(firmware: Firmware): ApiResult<string> {
	return post<string>(`/api/firmware/save`, firmware);
}

export async function getLatest(firmwareVersionType: number): ApiResult<Firmware[]> {
	return get<IFirmware[]>(`/api/firmware/latest-version/${ firmwareVersionType }`).then(result => result === undefined ? undefined : result.map(o => new Firmware(o)));
}

export async function saveDeviceFirmware(): ApiResult<Firmware> {
	return post<IFirmware>(`/api/firmware/device-firmware`).then(result => result === undefined ? undefined :  new Firmware(result));
}

export async function saveSensorFirmware(): ApiResult<Firmware> {
	return post<IFirmware>(`/api/firmware/sensor-firmware`).then(result => result === undefined ? undefined :  new Firmware(result));
}
