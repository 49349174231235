import { Facility } from "@app/shared";
import { EventTriggerTable } from "../../shared/EventTriggerTable";
import { ClientEndpointTable } from "./ClientEndpointTable";

type FacilityIntegrationsProps = {
    facility: Facility;
    onChange: () => void;
};

export const FacilityIntegrations: React.FC<FacilityIntegrationsProps> = ({ facility, onChange }) => {
    return (
        <>
            <ClientEndpointTable organizationId={facility.organizationId ?? ""} facilityId={facility.id} />
            <EventTriggerTable organizationId={facility.organizationId ?? ""} facilityId={facility.id} />
        </>
    );
}